import React from "react";
import * as Ui from "@CommonControls";
import * as DiContainer from "@DiContainer";
import SelectBoxExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/SelectBoxExampleStore";
import { FinancingClassSelectBox, AdmissionReasonSelectBox, DischargeReasonSelectBox, LateralitySelectBox } from "@HisPlatform/BoundedContexts/Care/Components";
import SourceCode from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/SourceCode";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ColorSelector, { IColorSelectorItem } from "@CommonControls/ColorSelector/ColorSelector";

const colors = [
    "012A4A",
    "013A63",
    "01497C",
    "014F86",
    "2A6F97",
    "2C7DA0",
    "468FAF",
    "61A5C2",
    "89C2D9",
    "A9D6E5",
    "112A4A",
    "113A63",
    "11497C",
    "114F86",
    "3A6F97",
    "3C7DA0",
    "568FAF",
    "71A5C2",
    "99C2D9",
    "B9D6E5",
];

@State.observer
export default class SelectBoxExampleBox extends React.Component {

    @DiContainer.lazyResolve("SelectBoxExampleStore") private store: SelectBoxExampleStore;

    private renderLoadOptionsAsyncDemo() {
        return (
            <>
                <ValidationBoundary validationResults={this.store.validationResults} entityTypeName="test">

                    <Ui.Flex>
                        <Ui.Flex.Item xs={1}>
                            <Ui.CheckBox automationId="" label="Required" value={this.store.isRequired} onChange={this.store.setRequired} /><br />
                            <Ui.CheckBox automationId="" label="Small" value={this.store.isCompact} onChange={this.store.setCompact} />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex style={{ backgroundColor: "#384252" }}>
                        <Ui.Flex.Item xs={3} style={{ color: "#f4f7fc" }} >
                            <br />
                            Dark mode:
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.SelectBox
                                label="Value1"
                                items={this.store.preloadedOptions}
                                getOptionValue={this.store.getOptionValue}
                                value={this.store.value}
                                onChange={this.store.setValue}
                                searchable
                                required={this.store.isRequired}
                                size={this.store.size}
                                placeholder="Hint"
                                propertyIdentifier="testValue"
                                automationId="selectBox1"
                                visualMode="dark"
                                theme="dark"
                            />
                            <div style={{ color: "f4f7fc" }}>Dark</div>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3} xsOffset={1}>
                            <Ui.SelectBox
                                label="Value1"
                                items={this.store.preloadedOptions}
                                getOptionValue={this.store.getOptionValue}
                                value={this.store.value}
                                onChange={this.store.setValue}
                                searchable
                                required={this.store.isRequired}
                                size={this.store.size}
                                placeholder="Hint"
                                propertyIdentifier="testValue"
                                automationId="selectBox1"
                                visualMode="dark"
                                theme="dark"
                                disabled
                            />
                            Dark disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={3}>
                            <br />
                            Secondary:
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.SelectBox
                                label="Value1"
                                items={this.store.preloadedOptions}
                                getOptionValue={this.store.getOptionValue}
                                value={this.store.value}
                                onChange={this.store.setValue}
                                searchable
                                required={this.store.isRequired}
                                size={this.store.size}
                                placeholder="Hint"
                                propertyIdentifier="testValue"
                                automationId="selectBox1" />
                            NORMAL
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3} xsOffset={1}>
                            <Ui.SelectBox automationId=""
                                label="Value2"
                                items={this.store.preloadedOptions}
                                getOptionValue={this.store.getOptionValue}
                                value={this.store.value}
                                onChange={this.store.setValue}
                                searchable
                                required={this.store.isRequired}
                                disabled
                                size={this.store.size}
                                placeholder="Hint"
                                propertyIdentifier="testValue" />
                            DISABLED
                            <br />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={3}>
                            <br />
                            Normal with label:
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.SelectBox automationId=""
                                label="Label"
                                items={this.store.preloadedOptions}
                                getOptionValue={this.store.getOptionValue}
                                value={this.store.value}
                                onChange={this.store.setValue}
                                required={this.store.isRequired}
                                searchable
                                size={this.store.size}
                                placeholder="Hint"
                                propertyIdentifier="testValue" />
                            NORMAL
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3} xsOffset={1}>
                            <Ui.SelectBox automationId=""
                                label="Label"
                                items={this.store.preloadedOptions}
                                getOptionValue={this.store.getOptionValue}
                                value={this.store.value}
                                onChange={this.store.setValue}
                                searchable
                                required={this.store.isRequired}
                                disabled
                                size={this.store.size}
                                placeholder="Hint"
                                propertyIdentifier="testValue" />
                            DISABLED
                        </Ui.Flex.Item>
                    </Ui.Flex>

                    <Ui.Flex>
                        <Ui.Flex.Item xs={3}>
                            <br />
                            Readonly normal with label:
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.SelectBox automationId=""
                                label="Label"
                                items={this.store.preloadedOptions}
                                getOptionValue={this.store.getOptionValue}
                                value={this.store.value}
                                onChange={this.store.setValue}
                                required={this.store.isRequired}
                                searchable
                                size={this.store.size}
                                placeholder="Hint"
                                propertyIdentifier="testValue"
                                isReadOnly />
                            NORMAL readonly
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3} xsOffset={1}>
                            <Ui.SelectBox automationId=""
                                label="Label"
                                items={this.store.preloadedOptions}
                                getOptionValue={this.store.getOptionValue}
                                value={this.store.value}
                                onChange={this.store.setValue}
                                searchable
                                required={this.store.isRequired}
                                disabled
                                size={this.store.size}
                                placeholder="Hint"
                                propertyIdentifier="testValue"
                                isReadOnly />
                            DISABLED readonly
                        </Ui.Flex.Item>
                    </Ui.Flex>

                </ValidationBoundary>
                <Ui.Flex>
                    <Ui.Flex.Item>
                        <Ui.SelectBox automationId=""
                            label="Grouped Options"
                            items={this.store.groupedOptions}
                            value={this.store.groupedValue}
                            onChange={this.store.setGroupedValue}
                            size={this.store.size} />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item>
                        <Ui.SelectBox automationId=""
                            label="Grouped multi value"
                            items={this.store.groupedOptions}
                            value={this.store.groupedMultiValue}
                            onChange={this.store.setGroupedMultiValue}
                            size={this.store.size}
                            multiSelect />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <h2>Load options async</h2>
                <Ui.Button automationId="" text="Load options async" onClick={this.store.loadOptionsAsync} />

                <Ui.Button automationId="" text="Set to Name1" onClick={this.store.setName1} />
                <Ui.Button automationId="" text="Set to Name2" onClick={this.store.setName2} />
                <Ui.Button automationId="" onClick={this.store.removeValidation}>Remove validation</Ui.Button>
                <Ui.Button automationId="" onClick={this.store.addError}>Add validation error</Ui.Button>
                <Ui.Button automationId="" onClick={this.store.addWarning}>Add validation warning</Ui.Button>

                <Ui.SelectBox automationId=""
                    items={this.store.options}
                    getOptionValue={this.store.getOptionValue}
                    value={this.store.value}
                    onChange={this.store.setValue} />

                <Ui.SingleAccordion title="Source">
                    <SourceCode language="Html">{`
<Ui.SelectBox
    items={this.store.options}
    getOptionValue={this.store.getOptionValue}
    value={this.store.value}
    onChange={this.store.setValue} />
                    `}
                    </SourceCode>
                    <SourceCode language="TypeScript">{`
@State.observable.ref public value: ISampleItem = null;
@State.observable.ref public options: Array<ISelectBoxItem<ISampleItem>> = null;

@State.action.bound
public setValue(newValue: ISampleItem) {
    this.value = newValue;
}

public getOptionValue(value: ISelectBoxItem2<ISampleItem>) {
    if (!value.value) {
        return "null";
    }
    
    return value.value.SampleNumber + "_" + value.value.SampleName;
}
`}
                    </SourceCode>
                </Ui.SingleAccordion>

                <Ui.SingleAccordion title="Store">
                    <pre>
                        {JSON.stringify({ value: this.store.value, options: this.store.options }, null, 4)}
                    </pre>
                </Ui.SingleAccordion>

                <Ui.Flex>
                    <Ui.Flex.Item xs={3}>
                        <ColorSelector
                            automationId="colorSelector"
                            value={this.store.color}
                            onChange={this.store.setColor}
                            items={colors.map(c => ({ cssColor: "#" + c, value: c, text: c } as IColorSelectorItem))}
                            label="ColorSelector"
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>

            </>
        );
    }

    private renderEESelectBoxes() {
        return (
            <>
                <h2>Extensible enum selectboxes</h2>
                <FinancingClassSelectBox
                    onChange={this.store.setFinancingClass}
                    value={this.store.selectedFinancingClassId}
                    searchable clearable
                    automationId="" />

                <AdmissionReasonSelectBox
                    onChange={this.store.setAdmissionReason}
                    value={this.store.selectedAdmissionReasonId}
                    searchable clearable
                    automationId="" />

                <DischargeReasonSelectBox
                    onChange={this.store.setDischargeReason}
                    value={this.store.selectedDischargeReasonId}
                    searchable clearable
                    automationId="" />

                <LateralitySelectBox
                    onChange={this.store.setLaterality}
                    value={this.store.selectedLateralityId}
                    searchable clearable
                    automationId="" />
            </>
        );
    }

    private renderComapctEESelectBoxes() {
        return (
            <>
                <h2>Extensible enum selectboxes in compact mode</h2>
                <FinancingClassSelectBox
                    onChange={this.store.setFinancingClass}
                    value={this.store.selectedFinancingClassId}
                    searchable clearable displayMode="shorthandTooltip"
                    automationId="" />

                <AdmissionReasonSelectBox
                    onChange={this.store.setAdmissionReason}
                    value={this.store.selectedAdmissionReasonId}
                    searchable clearable displayMode="shorthandTooltip"
                    automationId="" />
            </>
        );
    }

    private renderSearchDemo() {
        return (
            <>
                <h2>Search</h2>

                <Ui.SelectBox
                    items={this.store.preloadedOptions}
                    getOptionValue={this.store.getOptionValue}
                    value={this.store.value2}
                    onChange={this.store.setValue2}
                    searchable
                    automationId="" />

                <Ui.SingleAccordion title="Source">
                    <SourceCode language="Html">{`
<Ui.SelectBox
    items={this.store.options}
    getOptionValue={this.store.getOptionValue}
    value={this.store.value}
    onChange={this.store.setValue}
    searchable />
                    `}
                    </SourceCode>
                    <SourceCode language="TypeScript">{`
@State.observable.ref public value: ISampleItem = null;
@State.observable.ref public options: Array<ISelectBoxItem<ISampleItem>> = [...];

@State.action.bound
public setValue(newValue: ISampleItem) {
    this.value = newValue;
}

public getOptionValue(value: ISampleItem) {
    return value.SampleNumber + "_" + value.SampleName};
}
`}
                    </SourceCode>
                </Ui.SingleAccordion>

                <Ui.SingleAccordion title="Store">
                    <pre>
                        {JSON.stringify({ value: this.store.value2, options: this.store.preloadedOptions }, null, 4)}
                    </pre>
                </Ui.SingleAccordion>
            </>
        );
    }

    private renderSearchWithCreatableDemo() {
        return (
            <>
                <h2>Search with creatable entities</h2>

                <Ui.SelectBox
                    items={this.store.preloadedOptions}
                    getOptionValue={this.store.getOptionValue}
                    value={this.store.value3}
                    onChange={this.store.setValue3}
                    onAddNewOption={this.store.addNewOption}
                    searchable
                    isCreatable
                    automationId="" />

                <Ui.SingleAccordion title="Source">
                    <SourceCode language="Html">{`
<Ui.SelectBox
    items={this.store.options}
    getOptionValue={this.store.getOptionValue}
    value={this.store.value}
    onChange={this.store.setValue}
    searchable
    isCreatable />
                    `}
                    </SourceCode>
                    <SourceCode language="TypeScript">{`
@State.observable.ref public value: ISampleItem = null;
@State.observable.ref public options: Array<ISelectBoxItem<ISampleItem>> = [...];

@State.action.bound
public setValue(newValue: ISampleItem) {
    this.value = newValue;
}

public getOptionValue(value: ISampleItem) {
    return value.SampleNumber + "_" + value.SampleName};
}
`}
                    </SourceCode>
                </Ui.SingleAccordion>

                <Ui.SingleAccordion title="Store">
                    <pre>
                        {JSON.stringify({ value: this.store.value3, options: this.store.preloadedOptions }, null, 4)}
                    </pre>
                </Ui.SingleAccordion>
            </>
        );
    }

    private renderMultiSelectDemo() {
        return (
            <>
                <h2>MultiSelect</h2>
                <Ui.SelectBox
                    items={this.store.preloadedOptions}
                    getOptionValue={this.store.getOptionValue}
                    value={this.store.multiValue}
                    onChange={this.store.setMultiValue}
                    multiSelect
                    automationId=""
                />

                <Ui.SingleAccordion title="Source">
                    <SourceCode language="Html">{`
<Ui.SelectBox
    items={this.store.preloadedOptions}
    getOptionValue={this.store.getOptionValue}
    value={this.store.multiValue}
    onChange={this.store.setMultiValue}
    multiSelect />
                    `}
                    </SourceCode>
                    <SourceCode language="TypeScript">{`
@State.observable.ref public multiValue: ISampleItem[] = null;

@State.action.bound
public setMultiValue(newValue: ISampleItem[]) {
    this.multiValue = newValue;
}
`}
                    </SourceCode>
                </Ui.SingleAccordion>

                <Ui.SingleAccordion title="Store">
                    <pre>
                        {JSON.stringify({ value: this.store.multiValue }, null, 4)}
                    </pre>
                </Ui.SingleAccordion>
            </>
        );
    }
    public render() {
        return (
            <Ui.PageBox title="Select box" >
                <Ui.PageBox.Body>
                    {this.renderLoadOptionsAsyncDemo()}
                    <hr />
                    {this.renderSearchDemo()}
                    <hr />
                    {this.renderSearchWithCreatableDemo()}
                    <hr />
                    {this.renderMultiSelectDemo()}
                    <hr />
                    {this.renderEESelectBoxes()}
                    <hr />
                    {this.renderComapctEESelectBoxes()}
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}