import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class GroupBoxExampleStore {
    @State.observable public isOpen: boolean = false;

    @State.action.bound
    public setIsOpen() {
        this.isOpen = !this.isOpen;
    }
}