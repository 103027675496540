import React, { useLayoutEffect } from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import Debug from "@Toolkit/ReactClient/Components/Debug/Debug";
import Button from "@CommonControls/Button";
import DataGrid from "@CommonControls/DataGrid/DataGrid";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import { IPagingState } from "@CommonControls/DataGrid/IDataGridProps";
import SaveButton from "@CommonControls/SaveButton";
import IHasMasterDetailState from "@CommonControls/Layout/IHasMasterDetailState";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

const data: any[] = [
    { id: 0, name: "Name 1", data: "Cut my life into pieces" },
    { id: 1, name: "Name 2", data: "This is my last resort" },
    { id: 2, name: "Name 3", data: "Suffocation" },
    { id: 3, name: "Name 4", data: "No breathing" },
    { id: 4, name: "Name 5", data: "Don't give a ******, bleeding" },
    { id: 5, name: "Name 6", data: "'Cause I'm losing my sight" },
    { id: 6, name: "Name 7", data: "Losing my mind" },
    { id: 7, name: "Name 8", data: "Wish somebody would tell me I'm fine" },
    { id: 8, name: "Name 9", data: "Losing my sight" },
    { id: 9, name: "Name 10", data: "Losing my mind" },
    { id: 10, name: "Name 11", data: "Wish somebody would tell me I'm fine" },
    { id: 11, name: "Name 12", data: "I never realized I was spread too thin" },
    { id: 12, name: "Name 13", data: "Till it was too late" },
    { id: 13, name: "Name 14", data: "And I was empty within" },
    { id: 14, name: "Name 15", data: "Feeding on chaos" },
    { id: 15, name: "Name 16", data: "And living in sin" },
    { id: 16, name: "Name 17", data: "Downward spiral where do I begin?" },
    { id: 17, name: "Name 18", data: "It all started when I lost my mother" },
    { id: 18, name: "Name 19", data: "No love for myself" },
    { id: 19, name: "Name 20", data: "And no love for another" },
];

const paging: IPagingState = {
    currentPage: 1,
    pageSize: 10
};


interface IMasterViewExampleState extends IHasMasterDetailState {
}


const MasterView: React.FC<IMasterViewExampleState> = props => {

    useLayoutEffect(() => {
        console.log("MasterView.mount");
        return () => {
            console.log("MasterView.unmount :( ");
        };
    });

    return (
        <>
            <DataGrid
                dataSource={data}
                rowHeight={40}
                fixedHeight="100%"
                fixedLayout
                paging={paging}
                onSelectedRowChange={props._masterDetailState.onSelectedItemChange}
                isSelectable
                automationId="masterDetailExampleBox_dataGrid"
            >
                <DataGridColumn dataGetter="id" header="#" width={100} isVisible={!props._masterDetailState!.isCompact} />
                <DataGridColumn dataGetter="name" header="Name" width={90} />
                <DataGridColumn dataGetter="data" header="Data" isVisible={!props._masterDetailState!.isDetailOpen && !props._masterDetailState!.isCompact} />
            </DataGrid>
        </>
    );
};


interface IDetailProps extends IHasMasterDetailState {
}


const DetailView: React.FC<IDetailProps> = props => {
    return (
        <>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sit amet placerat diam, hendrerit scelerisque odio. Phasellus vehicula lorem non enim ornare placerat nec accumsan ante. Etiam cursus risus id urna placerat, non malesuada libero finibus. Sed tristique, dolor nec finibus accumsan, massa sem accumsan magna, vel mattis lacus nunc quis orci. Fusce facilisis enim vel pretium vulputate. Nulla sit amet ex eu nisi convallis sodales non eu velit. Vivamus eleifend elit lorem, a luctus enim interdum in. Praesent sagittis, mi eu pharetra blandit, nulla neque mattis enim, vel pulvinar nulla mauris non massa. Quisque luctus odio in sapien pellentesque, eu interdum orci ornare. Donec eu mauris quam. Nunc porttitor et lectus ac iaculis.</p>
            <p>Ut ac venenatis massa. Phasellus mi est, tristique sed feugiat sed, aliquet at justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; In sem orci, luctus sed facilisis eu, bibendum nec ipsum. Ut ullamcorper dolor in mattis ullamcorper. Nulla blandit commodo libero. Ut tincidunt sollicitudin tortor, ac tristique orci fermentum a. Phasellus vestibulum et velit in congue. Vestibulum sem sapien, placerat et euismod eget, imperdiet eu lorem.</p>
            <p>Sed pellentesque aliquam odio, quis rutrum massa blandit ac. Ut mattis ipsum quis enim elementum iaculis. Vestibulum vel varius enim. In laoreet massa ut leo venenatis, sit amet molestie nisi hendrerit. Praesent non ultrices mauris. Etiam aliquet non urna sit amet porttitor. Praesent erat massa, tincidunt et commodo quis, condimentum fringilla sapien.</p>
            <p>Mauris commodo quis diam vel sodales. Vestibulum vulputate risus ligula, pretium pellentesque lorem dictum nec. Mauris vel hendrerit diam. Quisque fermentum ac mi sit amet volutpat. Vestibulum sed nisl eget libero vulputate malesuada. Donec dictum ac ante sed laoreet. Aliquam id arcu nunc. Sed rhoncus sit amet dui et pulvinar. Suspendisse a volutpat massa.</p>
            <p>Donec dapibus eros nec felis fringilla cursus. Maecenas libero arcu, dapibus ac augue sit amet, placerat tincidunt nisi. Maecenas at nunc vitae sapien maximus consectetur et ac ipsum. Nullam vitae enim convallis, maximus nunc eget, ultricies magna. Ut sed fringilla metus. Vivamus lacinia volutpat neque, accumsan volutpat lorem commodo quis. Nam mattis et risus in pellentesque. Praesent eu metus suscipit, pellentesque turpis ut, tincidunt neque. Quisque volutpat ante vitae aliquam fringilla. In hac habitasse platea dictumst. Donec at justo mauris. Curabitur nec rhoncus est. Nullam in risus sed sem euismod congue. Integer sit amet velit ex. Suspendisse pulvinar semper porttitor. Sed in leo iaculis, dapibus odio non, posuere nisi.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sit amet placerat diam, hendrerit scelerisque odio. Phasellus vehicula lorem non enim ornare placerat nec accumsan ante. Etiam cursus risus id urna placerat, non malesuada libero finibus. Sed tristique, dolor nec finibus accumsan, massa sem accumsan magna, vel mattis lacus nunc quis orci. Fusce facilisis enim vel pretium vulputate. Nulla sit amet ex eu nisi convallis sodales non eu velit. Vivamus eleifend elit lorem, a luctus enim interdum in. Praesent sagittis, mi eu pharetra blandit, nulla neque mattis enim, vel pulvinar nulla mauris non massa. Quisque luctus odio in sapien pellentesque, eu interdum orci ornare. Donec eu mauris quam. Nunc porttitor et lectus ac iaculis.</p>
            <p>Ut ac venenatis massa. Phasellus mi est, tristique sed feugiat sed, aliquet at justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; In sem orci, luctus sed facilisis eu, bibendum nec ipsum. Ut ullamcorper dolor in mattis ullamcorper. Nulla blandit commodo libero. Ut tincidunt sollicitudin tortor, ac tristique orci fermentum a. Phasellus vestibulum et velit in congue. Vestibulum sem sapien, placerat et euismod eget, imperdiet eu lorem.</p>
            <p>Sed pellentesque aliquam odio, quis rutrum massa blandit ac. Ut mattis ipsum quis enim elementum iaculis. Vestibulum vel varius enim. In laoreet massa ut leo venenatis, sit amet molestie nisi hendrerit. Praesent non ultrices mauris. Etiam aliquet non urna sit amet porttitor. Praesent erat massa, tincidunt et commodo quis, condimentum fringilla sapien.</p>
            <p>Mauris commodo quis diam vel sodales. Vestibulum vulputate risus ligula, pretium pellentesque lorem dictum nec. Mauris vel hendrerit diam. Quisque fermentum ac mi sit amet volutpat. Vestibulum sed nisl eget libero vulputate malesuada. Donec dictum ac ante sed laoreet. Aliquam id arcu nunc. Sed rhoncus sit amet dui et pulvinar. Suspendisse a volutpat massa.</p>
            <p>Donec dapibus eros nec felis fringilla cursus. Maecenas libero arcu, dapibus ac augue sit amet, placerat tincidunt nisi. Maecenas at nunc vitae sapien maximus consectetur et ac ipsum. Nullam vitae enim convallis, maximus nunc eget, ultricies magna. Ut sed fringilla metus. Vivamus lacinia volutpat neque, accumsan volutpat lorem commodo quis. Nam mattis et risus in pellentesque. Praesent eu metus suscipit, pellentesque turpis ut, tincidunt neque. Quisque volutpat ante vitae aliquam fringilla. In hac habitasse platea dictumst. Donec at justo mauris. Curabitur nec rhoncus est. Nullam in risus sed sem euismod congue. Integer sit amet velit ex. Suspendisse pulvinar semper porttitor. Sed in leo iaculis, dapibus odio non, posuere nisi.</p>
        </>
    );
};

@State.observer
export default class MasterDetailExampleBox extends React.Component {

    @State.observable.ref private selectedItem = 3;
    @State.observable.ref private activeTab = "tab1";
    @State.observable.ref private isLoading = true;

    @State.action.bound
    private setSelectedItem(item: number) {
        this.isLoading = true;
        this.fakeLoad(() => {
            this.selectedItem = item;
        });
    }

    @State.observable.ref public validationResults: IClientValidationResult[] = null;

    @State.action.bound
    public addValidationResults() {
        this.validationResults = [
            {
                entityName: "tab1",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "tab1TestValue1",
                        severity: "error",
                        message: "--Error message1--",
                    },
                    {
                        propertyPath: "tab1TestValue2",
                        severity: "error",
                        message: "--Error message2--",
                    }
                ]
            },
            {
                entityName: "tab2",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "tab2TestValue",
                        severity: "warning",
                        message: "--Warning message--",
                    }
                ]
            },
        ];
    }

    @State.action.bound
    public removeValidationResults() {
        this.validationResults = null;
    }

    @State.action.bound
    private setActiveTab(tab: string) {
        this.activeTab = tab;
    }

    public componentDidMount() {
        this.fakeLoad();
    }

    private fakeLoad(action?: () => void) {
        window.setTimeout(() => {
            State.runInAction(() => {
                this.isLoading = false;
                action?.();
            });
        }, 1000);
    }

    public render() {
        return (
            <ValidationBoundary validationResults={this.validationResults} entityTypeName="test">
                <SingleLayout>
                    <MasterDetailLayout
                        selectedItem={this.selectedItem}
                        onSelectedItemChange={this.setSelectedItem}
                        activeTabName={this.activeTab}
                        onActiveTabNameChange={this.setActiveTab}
                        isLoading={this.isLoading}
                        showCompactModeSwitcher
                    >
                        <MasterDetail.Master
                            title="Items"
                            toolbar={(
                                <>
                                    <Button automationId="" text="New item" iconName="paperclipSolid" visualStyle="primary" />
                                </>
                            )}
                        >
                            <MasterView />
                        </MasterDetail.Master>
                        <MasterDetail.Detail
                            title="Itt van te"
                            hasSidePadding
                            hasFooter
                            footerRightToolbar={(
                                <SaveButton automationId="" />
                            )}
                            toolbar={(
                                <Button automationId=""
                                    onClick={this.validationResults === null ? this.addValidationResults : this.removeValidationResults}
                                >
                                    {this.validationResults === null ? "Invalid" : "Valid"}
                                </Button>
                            )}>
                            <MasterDetail.Tab
                                tabName="tab1"
                                tabTitle="Tab 1"
                                title="Item detail 1"
                                propertyPathRegexPattern="(tab1)"
                            >
                                <DetailView />
                            </MasterDetail.Tab>
                            <MasterDetail.Tab
                                tabName="tab2"
                                tabTitle="Tab 2"
                                title="Item detail 2"
                                propertyPathRegexPattern="(tab2)"
                            >
                                <Debug>This is detail/2</Debug>
                            </MasterDetail.Tab>
                        </MasterDetail.Detail>
                    </MasterDetailLayout>
                </SingleLayout>
            </ValidationBoundary>
        );
    }
}