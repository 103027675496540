import Di from "@Di";
import MultiAccordionStore from "@CommonControls/MultiAccordion/MultiAccordionStore";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import SingleAccordionStore from "@CommonControls/SingleAccordion/SingleAccordionStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class AccordionExampleStore {
    @State.observable public isOpen = false;
    @State.observable public index: number = null;

    @State.action.bound
    public toggleSingleAccordion() {
        this.isOpen = !this.isOpen;
    }

    @State.action.bound
    public setAccordionNone() {
        this.index = null;
    }

    @State.action.bound
    public setAccordionOne() {
        this.index = 0;
    }

    @State.action.bound
    public setAccordionTwo() {
        this.index = 1;
    }

    @State.action.bound
    public setAccordionThree() {
        this.index = 2;
    }

    @State.action.bound
    public setIndex(newIndex: number) {
        if (newIndex === this.index) {
            this.index = null;
        } else {
            this.index = newIndex;

        }
    }
}
