import React from "react";
import * as Ui from "@CommonControls";
import * as DiContainer from "@DiContainer";
import DateRangePickerExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/DateRangePickerExampleStore";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class DateRangePickerExampleBox extends React.Component {

    @DiContainer.lazyResolve("DateRangePickerExampleStore") private store: DateRangePickerExampleStore;

    public render() {
        return (
            <Ui.PageBox title="Date range picker">
                <Ui.PageBox.Body>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <Ui.CheckBox automationId="" label="Required" value={this.store.isRequired} onChange={this.store.setRequired} /><br />
                            <Ui.CheckBox automationId="" label="Small" value={this.store.isCompact} onChange={this.store.setCompact} /*noPadding*/ />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <br />
                    <h3>Partial date</h3>
                    <ValidationBoundary validationResults={this.store.validationResults} entityTypeName="test">
                        <Ui.DateRangePicker automationId=""
                            value={this.store.value}
                            onChange={this.store.setValue}
                            propertyIdentifier="testValue"
                            disabled={this.store.isDisabled}
                            required={this.store.isRequired}
                            size={this.store.size}
                        />

                        <h3>Full with picker</h3>
                        <Ui.DateRangePicker automationId=""
                            value={this.store.value}
                            onChange={this.store.setValue}
                            propertyIdentifier="testValue"
                            disabled={this.store.isDisabled}
                            required={this.store.isRequired}
                            size={this.store.size}
                        />

                        <h3>Validatable</h3>
                        <Ui.DateRangePicker automationId=""
                            value={this.store.validatableValue}
                            onChange={this.store.setValidatableValue}
                            propertyIdentifier="testValue"
                            disabled={this.store.isDisabled}
                            required={this.store.isRequired}
                            size={this.store.size}
                        />

                        <h3>Validatable readonly</h3>
                        <Ui.DateRangePicker automationId=""
                            value={this.store.validatableValue}
                            onChange={this.store.setValidatableValue}
                            propertyIdentifier="testValue"
                            disabled={this.store.isDisabled}
                            required={this.store.isRequired}
                            size={this.store.size}
                            isReadOnly
                        />
                    </ValidationBoundary>

                    <Ui.Button automationId="" onClick={this.store.removeValidation}>Remove validation</Ui.Button>
                    <Ui.Button automationId="" onClick={this.store.addError}>Add validation error</Ui.Button>
                    <Ui.Button automationId="" onClick={this.store.addWarning}>Add validation warning</Ui.Button>
                    <Ui.Button automationId="" onClick={this.store.setDisable}>{this.store.isDisabled ? "Enable" : "Disable"}</Ui.Button>

                </Ui.PageBox.Body>
                <pre>
                    {JSON.stringify(this.store, null, 4)}
                </pre>
            </Ui.PageBox>
        );
    }
}
