import React from "react";
import * as Ui from "@CommonControls";
import SourceCode from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/SourceCode";

export default class ChartExampleBox extends React.Component {

    private data = [
        {
            name: "Page A", uv: 4000, pv: 2400, amt: 2400,
        },
        {
            name: "Page B", uv: 3000, pv: 1398, amt: 2210,
        },
        {
            name: "Page C", uv: 2000, pv: 9800, amt: 2290,
        },
        {
            name: "Page D", uv: 2780, pv: 3908, amt: 2000,
        },
        {
            name: "Page E", uv: 1890, pv: 4800, amt: 2181,
        },
        {
            name: "Page F", uv: 2390, pv: 3800, amt: 2500,
        },
        {
            name: "Page G", uv: 3490, pv: 4300, amt: 2100,
        },
    ];

    private lineSettings: Array<{ dataKey: string; pointColor: string; lineColor: string; }> = [
        {
            dataKey: "uv",
            pointColor: "#00b4ec",
            lineColor: "#00b4ec"
        },
        {
            dataKey: "pv",
            pointColor: "#0abf00",
            lineColor: "#0abf00"
        },
        {
            dataKey: "amt",
            pointColor: "#ff6600",
            lineColor: "#ff6600"
        }
    ];

    private barSettings: Array<{ dataKey: string; fill: string; }> = [
        {
            dataKey: "uv",
            fill: "#00b4ec"
        },
        {
            dataKey: "pv",
            fill: "#0abf00"
        },
        {
            dataKey: "amt",
            fill: "#ff6600"
        },
    ];

    private pieData = [
        { name: "Group A", value: 400 },
        { name: "Group B", value: 300 },
        { name: "Group C", value: 300 },
        { name: "Group D", value: 200 },
      ];

    public render() {
        return (
            <Ui.PageBox title="Chart">
                <Ui.PageBox.Body>
                    <h4>LineChart</h4>
                    <Ui.Chart.Line
                        width={800}
                        height={200}
                        data={this.data}
                        XAxisDataKey="name"
                        margin={{ top: 10, left: 0, bottom: 0, right: 30 }}
                        lineSettings={this.lineSettings}
                        isLegendEnabled
                        isTooltipEnabled
                    />
                    <Ui.SingleAccordion title="Example code">
                        <SourceCode language="typescript">
                            {`<Ui.Chart.Line
width={800}
height={200}
data={this.data}
XAxisDataKey="name"
margin={{ top: 10, left: 0, bottom: 0, right: 30 }}
lineSettings={this.lineSettings}
isLegendEnabled
isTooltipEnabled
/>`}
                        </SourceCode>
                    </Ui.SingleAccordion>
                    <h4>BarChart</h4>
                    <Ui.Chart.Bar
                        width={800}
                        height={200}
                        data={this.data}
                        XAxisDataKey="name"
                        margin={{ top: 10, left: 0, bottom: 0, right: 30 }}
                        barSettings={this.barSettings}
                        isLegendEnabled
                        isTooltipEnabled
                    />
                    <h4>AreaChart</h4>
                    <Ui.Chart.Area
                        width={800}
                        height={200}
                        data={this.data}
                        XAxisDataKey="name"
                        margin={{ top: 10, left: 0, bottom: 0, right: 30 }}
                        areaSettings={this.lineSettings}
                        isLegendEnabled
                        isTooltipEnabled
                    />
                    <h4>PieChart</h4>
                    <Ui.Chart.Pie
                        width={300}
                        height={300}
                        cx={120}
                        cy={120}
                        dataKey={"value"}
                        data={this.pieData}
                        colors={["#0088FE", "#00C49F", "#FFBB28", "#FF8042"]}
                        isLegendEnabled
                        isTooltipEnabled
                        renderLabel
                    />
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}