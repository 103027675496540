import Di from "@Di";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import DialogResult from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResult";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class ToastStore {

    @State.bound
    @State.action
    public okDialog() {
        this.dialogService.ok("Warning", "I am an OK dialog.");
    }

    @State.bound
    @State.action
    public okCancelDialog() {
        this.dialogService.okCancel("Confirmation", "I am an OK/Cancel dialog.");
    }

    @State.bound
    @State.action
    public yesNoDialog() {
        this.dialogService.yesNo("Confirmation", "I am a Yes/No dialog.");
    }

    @State.bound
    @State.action
    public yesNoCancelDialog() {
        this.dialogService.yesNoCancel("Confirmation", "I am a Yes/No/Cancel dialog.");
    }

    @State.bound
    public async awaitableDialogAsync() {
        const result: DialogResult = await this.dialogService.yesNo("Confirmation", "Choose a button:");
        this.dialogService.ok("Warning", "Your previous answer was: " + DialogResultCode[result.resultCode]);
    }

    constructor(
        @Di.inject("IDialogService") private dialogService: IDialogService
    ) { }
}
