import React from "react";
import * as Ui from "@CommonControls";
import SourceCode from "./SourceCode";

export default class MessageExampleBox extends React.Component {
    public render() {
        return (
            <Ui.PageBox title="Message">
                <Ui.PageBox.Body>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6}>
                            <Ui.Message type="primary">Primary message with <a href="#/developer">linked text</a></Ui.Message>
                            <Ui.Message type="secondary">Secondary message with <a href="#/developer">linked text</a></Ui.Message>
                            <Ui.Message type="success">Success message with <a href="#/developer">linked text</a></Ui.Message>
                            <Ui.Message type="danger">Danger message with <a href="#/developer">linked text</a></Ui.Message>
                            <Ui.Message type="warning">Warning message with <a href="#/developer">linked text</a></Ui.Message>
                            <Ui.Message type="info">Info message with <a href="#/developer">linked text</a></Ui.Message>
                            <Ui.Message type="light">Light message with <a href="#/developer">linked text</a></Ui.Message>
                            <Ui.Message type="dark">Dark message with <a href="#/developer">linked text</a></Ui.Message>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                        <h3>Page</h3>
                            <SourceCode language="html">
                                {`<Ui.Message type="primary">Primary message with <a href="#/developer">linked text</a></Ui.Message>
<Ui.Message type="secondary">Secondary message with <a href="#/developer">linked text</a></Ui.Message>
<Ui.Message type="success">Success message with <a href="#/developer">linked text</a></Ui.Message>
<Ui.Message type="danger">Danger message with <a href="#/developer">linked text</a></Ui.Message>
<Ui.Message type="warning">Warning message with <a href="#/developer">linked text</a></Ui.Message>
<Ui.Message type="info">Info message with <a href="#/developer">linked text</a></Ui.Message>
<Ui.Message type="light">Light message with <a href="#/developer">linked text</a></Ui.Message>
<Ui.Message type="dark">Dark message with <a href="#/developer">linked text</a></Ui.Message>
`}</SourceCode>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}