import React from "react";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import * as DiContainer from "@DiContainer";
import SelectorExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/SelectorExampleStore";
import SourceCode from "./SourceCode";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import VendorSelector from "@HisPlatform/Packages/Patients/Components/VendorSelector/VendorSelector";

@State.observer
export default class SelectorExampleBox extends React.Component {

    @DiContainer.lazyResolve("SelectorExampleStore") private store: SelectorExampleStore;

    public render() {
        return (
            <Ui.PageBox title="Selector">
                <Ui.Flex>
                    <Ui.Flex.Item xs={4}>
                        <p>A Selector is a select form field, where huge amount of possible options available.</p>
                        <ValidationBoundary validationResults={this.store.validationResults} entityTypeName="test">
                            <HisUi.CountrySelector
                                label="CountrySelector"
                                value={this.store.selectedCountryId}
                                onChange={this.store.countryIdChanged}
                                disabled={this.store.isDisabled}
                                propertyIdentifier="testValue"
                                automationId="" />
                            <HisUi.CountrySelector
                                label="CitizenshipCountrySelector"
                                variant="citizenship"
                                value={this.store.selectedCitizenshipId}
                                onChange={this.store.citizenshipIdChanged}
                                disabled={this.store.isDisabled}
                                propertyIdentifier="testValue"
                                automationId="" />
                            <HisUi.AssistantSelector
                                label="AssistantSelector"
                                value={this.store.selectedAssistantId}
                                onChange={this.store.assistantIdChanged}
                                disabled={this.store.isDisabled}
                                propertyIdentifier="testValue"
                                automationId="" />

                            <VendorSelector
                                label="Szolgáltató:"
                                automationId="vs"
                                value={this.store.selectedVendor}
                                onChange={this.store.setVendor}
                            />
                        </ValidationBoundary>

                        <Ui.Button onClick={this.store.removeValidation} automationId="">Remove validation</Ui.Button>
                        <Ui.Button onClick={this.store.addError} automationId="">Add validation error</Ui.Button>
                        <Ui.Button onClick={this.store.addWarning} automationId="">Add validation warning</Ui.Button>
                        <Ui.Button onClick={this.store.setDisable} automationId="">{this.store.isDisabled ? "Enable" : "Disable"}</Ui.Button>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={8}>
                        <h3>Store</h3>
                        <SourceCode language="typescript">
                            {`
public selectorStore = new SelectorStore<BnoSelectorFilterStore, any, any>(
    new BnoSelectorFilterStore());

@State.computed public get selectedValue() {
    if (this.selectorStore.selectedValue) {
        return this.selectorStore.selectedValue.Id + " - " + 
            this.selectorStore.selectedValue.Name;
    }

    return "Select value";
}

constructor() {

    this.selectorStore.setFetchAction(
        (queryOptions: DataSourceQueryOptions, filterStore: BnoSelectorFilterStore) => {
        // set fetch action to retrieve data from server
    });

    this.selectorStore.setValuesMapper((dto) => {
        // map values from retrieved dto
    });

    this.selectorStore.setTotalCountMapper((dto) => {
        // map total count value from retrieved dto
    });

}

@State.bound
@State.action
public showSelector() {
    this.selectorStore.open();
}
`}
                        </SourceCode>
                        <h3>Page</h3>
                        <SourceCode language="html">
                            {`
<Ui.Selector store={this.store.selectorStore} title="Selector title" 
    dataFlex.Items={this.selectorColumns}>
<Ui.Selector.FilterPane>
    <label>Search:</label>
    <Ui.TextBox valueStore={this.store.selectorStore.filterStore.Value} />
</Ui.Selector.FilterPane>
<Ui.Button content={this.store.selectedValue} onClick={this.store.showSelector} />
</Ui.Selector>
`}
                        </SourceCode>
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.PageBox>
        );
    }
}
