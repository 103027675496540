import Di from "@Di";
import PointOfCareId from "@Primitives/PointOfCareId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import IEntityCollectionsService from "@HisPlatform/Services/Definition/EntityCollectionService/IEntityCollectionsService";
import UserId from "@Primitives/UserId.g";
import EntityCollectionStore from "@HisPlatform/BoundedContexts/Productivity/ApplicationLogic/Model/Personalization/EntityCollectionStore";
import EntityCollectionId from "@Primitives/EntityCollectionId.g";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import { IComplexSearchItemGroup } from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/ComplexSearchPanel";
import EntityCollectionOwner from "@HisPlatform/BoundedContexts/Productivity/Api/Personalization/Enum/EntityCollectionOwner.g";

@Di.injectable()
export default class UniversalCodeSelectorExampleStore {

    public get items(): ICodeSelectorExampleComplexSearchEntity[] {
        switch (this.selectedGroup?.name) {
            case "group1":
                return testItemsGroup1;
            case "group2":
                return testItemsGroup2;
            case "group3":
                return testItemsGroup3;
            default:
                return testItems;
        }
    }

    public get allItems(): ICodeSelectorExampleComplexSearchEntity[] {
        return [...testItems, ...testItemsGroup1, ...testItemsGroup2, ...testItemsGroup3];
    }

    public get itemGroups(): IComplexSearchItemGroup[] {
        return this.showAdditionalGroups ? itemGroups : null;
    }

    @State.observable public isMultiSelect: boolean = false;
    @State.observable public hasComplexSearch: boolean = true;
    @State.observable public showFavoritesAndGroup: boolean = true;
    @State.observable public showAddNewItem: boolean = true;
    @State.observable public showAdditionalGroups: boolean = false;
    public selectedGroup: IComplexSearchItemGroup = null;

    @State.observable.ref private singleValue: ICodeSelectorExampleEntity = null;
    @State.observable.ref private multiValue: ICodeSelectorExampleEntity[] = [];

    public fakePointOfCareId = new PointOfCareId("42");

    @State.computed
    public get value() {
        return this.isMultiSelect ? this.multiValue : this.singleValue;
    }

    @State.action.bound
    public setIsMultiSelect(value: boolean) {
        this.isMultiSelect = value;
    }

    @State.action.bound
    public setHasComplexSearch(value: boolean) {
        this.hasComplexSearch = value;
    }

    @State.action.bound
    public setShowFavoritesAndGroup(value: boolean) {
        this.showFavoritesAndGroup = value;
    }

    @State.action.bound
    public setShowAddNewItem(value: boolean) {
        this.showAddNewItem = value;
    }

    @State.action.bound
    public setShowAdditionalGroups(value: boolean) {
        this.showAdditionalGroups = value;
    }

    @State.action.bound
    public onChange(item: ICodeSelectorExampleEntity | ICodeSelectorExampleEntity[]) {
        if (this.isMultiSelect) {
            this.multiValue = item as ICodeSelectorExampleEntity[];
        } else {
            this.singleValue = item as ICodeSelectorExampleEntity;
        }
    }

    @State.bound
    public searchItems(text: string): ICodeSelectorExampleComplexSearchEntity[] {
        const searchText = text?.trim().toUpperCase();
        return this.items.filter(item => item.code.toUpperCase().includes(searchText) || item.name.toUpperCase().includes(searchText));
    }

    @State.bound
    public addNewItem(code: string, name: string): Promise<{ item: ICodeSelectorExampleComplexSearchEntity, validationResults: IClientValidationResult[] }> {
        const idValues = this.items.map(i => parseInt(i.id.value, 10));
        const id = Math.max(...idValues) + 1;
        const item: ICodeSelectorExampleComplexSearchEntity = {
            name: name,
            code: code,
            id: { value: id.toString() }
        };
        this.items.push(item);
        return Promise.resolve({ item: item, validationResults: [] });
    }
}

export interface ICodeSelectorExampleEntity {
    id: { value: string };
}

export interface ICodeSelectorExampleComplexSearchEntity {
    id: { value: string };
    code: string;
    name: string;
}


@Di.injectable()
export class FakeEntityCollectionsService implements IEntityCollectionsService {
    private mostUsed = [{ value: "1" }, { value: "2" }];
    private items = [
        new EntityCollectionStore(new EntityCollectionId("2"), [{ value: "3" }, { value: "3" }], "Group", "Test", "Group 1"),
        new EntityCollectionStore(new EntityCollectionId("3"), [{ value: "4" }, { value: "4" }], "Group", "Test", "Group 2")
    ];

    @State.bound
    public getEntityCollectionsAsync(entityName: string, ownerId: PointOfCareId | UserId, ownerType: EntityCollectionOwner): Promise<EntityCollectionStore[]> {
        return Promise.resolve(this.items);
    }

    @State.bound
    public getMostUsedEntityIdsAsync(entityName: string, pointOfCareId: PointOfCareId, count: number): Promise<IStringEntityId[]> {
        return Promise.resolve(this.mostUsed);
    }

    @State.bound
    public setEntityCollectionAsync(store: EntityCollectionStore, entityName: string, entityCollectionOwner: EntityCollectionOwner, ownerId: IStringEntityId): Promise<EntityCollectionStore> {
        let result: EntityCollectionStore;
        if (!store.id) {
            result = new EntityCollectionStore(new EntityCollectionId("3"), store.idList, store.type, store.code, store.name);
        } else {
            result = store;
        }

        return Promise.resolve(result);
    }

}

const testItems: ICodeSelectorExampleComplexSearchEntity[] = [
    {
        id: { value: "1" },
        code: "code1",
        name: "name1"
    },
    {
        id: { value: "2" },
        code: "code2",
        name: "name2"
    },
    {
        id: { value: "3" },
        code: "code3",
        name: "name3"
    },
    {
        id: { value: "4" },
        code: "code4",
        name: "name4"
    },
    {
        id: { value: "5" },
        code: "code5",
        name: "name5"
    },
    {
        id: { value: "6" },
        code: "code6",
        name: "name6"
    },
    {
        id: { value: "7" },
        code: "code7",
        name: "name7"
    },
    {
        id: { value: "8" },
        code: "code8",
        name: "name8"
    },
    {
        id: { value: "9" },
        code: "code9",
        name: "name9"
    },
    {
        id: { value: "10" },
        code: "code10",
        name: "name10"
    },
    {
        id: { value: "11" },
        code: "code11",
        name: "name11"
    },
    {
        id: { value: "12" },
        code: "code12",
        name: "name12"
    },
    {
        id: { value: "13" },
        code: "code13",
        name: "name13"
    },
];

const testItemsGroup1: ICodeSelectorExampleComplexSearchEntity[] = [
    {
        id: { value: "14" },
        code: "code14",
        name: "name14"
    },
    {
        id: { value: "15" },
        code: "code15",
        name: "name15"
    },
    {
        id: { value: "16" },
        code: "code16",
        name: "name16"
    },
    {
        id: { value: "17" },
        code: "code17",
        name: "name17"
    },
];

const testItemsGroup2: ICodeSelectorExampleComplexSearchEntity[] = [
    {
        id: { value: "18" },
        code: "code18",
        name: "name18"
    },
    {
        id: { value: "19" },
        code: "code19",
        name: "name19"
    },
    {
        id: { value: "20" },
        code: "code20",
        name: "name20"
    },
    {
        id: { value: "21" },
        code: "code21",
        name: "name21"
    },
];

const testItemsGroup3: ICodeSelectorExampleComplexSearchEntity[] = [
    {
        id: { value: "22" },
        code: "code22",
        name: "name22"
    },
    {
        id: { value: "23" },
        code: "code23",
        name: "name23"
    },
    {
        id: { value: "24" },
        code: "code24",
        name: "name24"
    },
    {
        id: { value: "25" },
        code: "code25",
        name: "name25"
    },
];

const itemGroups: IComplexSearchItemGroup[] = [
    {
        name: "group1",
        localizedName: "Group 1",
        icon: "addressCardSolid"
    },
    {
        name: "group2",
        localizedName: "Group 2"
    },
    {
        name: "group3",
        localizedName: "Group 3",
        icon: "calculator"
    }
];
