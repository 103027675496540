import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class SidebarExampleStore {
    
    @State.observable public selectedItem: string = "patient";

    @State.action.bound
    public selectPatientTab() {
        this.selectedItem = "patient";
    }

    @State.action.bound
    public selectCareTab() {
        this.selectedItem = "care";
    }

    @State.action.bound
    public selectPrescriptionsTab() {
        this.selectedItem = "prescriptions";
    }
}