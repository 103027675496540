import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IValidationState from "@Toolkit/ReactClient/Components/ValidationBoundary/IValidationState";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

@Di.injectable()
export default class TabControlExampleStore {

    @State.observable.ref public validationResults: IClientValidationResult[] = null;

    @State.action.bound
    public addValidationResults() {
        this.validationResults = [
            {
                entityName: "tabA",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "tabATestValue1",
                        severity: "error",
                        message: "--Error message1--",
                    },
                    {
                        propertyPath: "tabATestValue2",
                        severity: "error",
                        message: "--Error message2--",
                    }
                ]
            },
            {
                entityName: "tabB",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "tabBTestValue",
                        severity: "warning",
                        message: "--Warning message--",
                    }
                ]
            },
            {
                entityName: "tabC",
                entityId: null,
                checkedRules: [],
                problems: []
            }
        ];
    }

    @State.action.bound
    public removeValidationResults() {
        this.validationResults = null;
    }

    @State.observable public activeIndex: number = 0;

    @State.action.bound
    public selectTab1() {
        this.activeIndex = 0;
    }

    @State.action.bound
    public selectTab2() {
        this.activeIndex = 1;
    }

    @State.action.bound
    public selectTab3() {
        this.activeIndex = 2;
    }

    @State.action.bound
    public setIndex(newIndex: number) {
        this.activeIndex = newIndex;
    }
}
