import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";


@Di.injectable()
export default class TextBoxExampleStore {

    @State.observable public simpleValue: string = "";
    @State.observable public maskedValue: string = "";
    @State.observable public passwordValue: string = "";

    @State.observable public isRequired: boolean = false;
    @State.observable public isCompact: boolean = false;

    @State.computed public get size(): "compact" | "standard" {
        return this.isCompact ? "compact" : undefined;
    }

    @State.observable.ref public validationResults: IClientValidationResult[] = null;

    @State.action.bound
    public changeSimpleValue(newValue: string) {
        this.simpleValue = newValue;
    }

    @State.action.bound
    public changeMaskedValue(newValue: string) {
        this.maskedValue = newValue;
    }

    @State.action.bound
    public changePasswordValue(newValue: string) {
        this.passwordValue = newValue;
    }

    @State.action.bound
    public addError() {
        this.validationResults = [
            {
                entityName: "test",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "testValue",
                        severity: "error",
                        message: "This filed is required. Please fill it with proper information.",
                    }
                ]
            },
            {
                entityName: "test2",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "testValue",
                        severity: "error",
                        message: "Multi validation problem source...",
                    }
                ]
            }
        ];
    }

    @State.action.bound
    public removeValidation() {
        this.validationResults = null;
    }

    @State.bound
    @State.action
    public addWarning() {
        this.validationResults = [
            {
                entityName: "test",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "testValue",
                        severity: "warning",
                        message: "This filed is required. Please fill it with proper information.",
                    }
                ]
            }
        ];
    }

    @State.action.bound
    public setRequired(newValue: boolean) {
        this.isRequired = newValue;
    }

    @State.action.bound
    public setCompact(newValue: boolean) {
        this.isCompact = newValue;
    }

}
