import UserId from "@Primitives/UserId.g";
import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class MultiSelectorExampleStore {
    
    @State.observable.ref public userIds: UserId[] = [];

    @State.action.bound
    public setUserIds(ids: UserId[]) {
        this.userIds = ids;
    }
}