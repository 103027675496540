import Di from "@Di";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class ToastStore {
    
    @State.bound
    public showSuccessToast() {
        this.notificationService.success("The operation was successful");
    }

    @State.bound
    public showErrorToast() {
        this.notificationService.error("The server is down again");
    }

    @State.bound
    public showWarningToast() {
        this.notificationService.warning("The server will explode soon");
    }

    @State.bound
    public showInfoToast() {
        this.notificationService.info("Nothing special");
    }

    constructor(
        @Di.inject("INotificationService") private notificationService: INotificationService
    ) { }
}
