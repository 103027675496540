import React from "react";
import * as Ui from "@CommonControls";
import SourceCode from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/SourceCode";

export default class HeaderExampleBox extends React.Component {

    public render() {
        return (
            <Ui.PageBox title="HeaderGroup and Header">
                <div style={{ padding: "30px" }}>
                    H1
                <Ui.Header.Group headingType={1}>
                        <Ui.Header>Main heading</Ui.Header>
                        <Ui.Header.Separator />
                        <Ui.Header alternateColor>Secondary heading</Ui.Header>
                    </Ui.Header.Group>
                    H2
                <Ui.Header.Group headingType={2}>
                        <Ui.Header>Main heading</Ui.Header>
                        <Ui.Header.Separator />
                        <Ui.Header alternateColor>Secondary heading</Ui.Header>
                    </Ui.Header.Group>
                    H3
                <Ui.Header.Group headingType={3}>
                        <Ui.Header>Main heading</Ui.Header>
                        <Ui.Header.Separator />
                        <Ui.Header alternateColor>Secondary heading</Ui.Header>
                    </Ui.Header.Group>
                    H4
                <Ui.Header.Group headingType={4}>
                        <Ui.Header>Main heading</Ui.Header>
                        <Ui.Header.Separator />
                        <Ui.Header alternateColor>Secondary heading</Ui.Header>
                    </Ui.Header.Group>
                    H5
                <Ui.Header.Group headingType={5}>
                        <Ui.Header>Main heading</Ui.Header>
                        <Ui.Header.Separator />
                        <Ui.Header alternateColor>Secondary heading</Ui.Header>
                    </Ui.Header.Group>
                    H6
                <Ui.Header.Group headingType={6}>
                        <Ui.Header>Main heading</Ui.Header>
                        <Ui.Header.Separator />
                        <Ui.Header alternateColor>Secondary heading</Ui.Header>
                    </Ui.Header.Group>
                </div>
                <Ui.SingleAccordion title="Source">
                    <SourceCode language="html">
                        {`<Ui.Header.Group headingType={1}>
    <Ui.Header>Main heading</Ui.Header>
    <Ui.Header.Separator />
    <Ui.Header alternateColor>Secondary heading</Ui.Header>
</Ui.Header.Group>
`}
                    </SourceCode>
                </Ui.SingleAccordion>
            </Ui.PageBox>
        );
    }
}