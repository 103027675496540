import Di from "@Di";
import CountryId from "@Primitives/CountryId.g";
import ILocalizationService from "@Toolkit/CommonWeb/Abstractions/Localization/ILocalizationService";
import PractitionerId from "@Primitives/PractitionerId.g";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import VendorId from "@Primitives/VendorId.g";

class BnoSelectorFilterStore {
}


@Di.injectable()
export default class SelectorExampleStore {

    @State.observable public bnoSelectorOpen = false;
    @State.observable.ref public selectedDoctorId: PractitionerId = null;
    @State.observable.ref public selectedAssistantId: PractitionerId = null;
    @State.observable.ref public selectedCountryId: CountryId = null;
    @State.observable.ref public selectedCitizenshipId: CountryId = null;
    @State.observable.ref public isDisabled: boolean = false;
    @State.observable.ref public validationResults: IClientValidationResult[] = null;

    @State.observable.ref public selectedVendor: VendorId = new VendorId("1");
    @State.action.bound public setVendor(v: VendorId) { this.selectedVendor = v; }

    public bnoChanged(bnoCode: string) {
        console.log(bnoCode);
    }

    @State.action.bound
    public doctorIdChanged(doctor: PractitionerId) {
        this.selectedDoctorId = doctor;
    }

    @State.action.bound
    public clearDoctor1() {
        this.selectedDoctorId = null;
    }

    @State.action.bound
    public assistantIdChanged(assistantId: PractitionerId) {
        this.selectedAssistantId = assistantId;
    }

    @State.action.bound
    public clearAssistant() {
        this.selectedAssistantId = null;
    }

    @State.action.bound
    public countryIdChanged(country: CountryId) {
        this.selectedCountryId = country;
    }

    @State.action.bound
    public clearCountry() {
        this.selectedCountryId = null;
    }

    @State.action.bound
    public citizenshipIdChanged(country: CountryId) {
        this.selectedCitizenshipId = country;
    }

    @State.action.bound
    public clearCitizenship() {
        this.selectedCitizenshipId = null;
    }

    constructor(@Di.inject("ILocalizationService") private localizationService: ILocalizationService) {
    }

    @State.action.bound
    public bnoSelectorClosed() {
        this.bnoSelectorOpen = false;
    }

    @State.bound
    @State.action
    public showSelector() {
        this.bnoSelectorOpen = true;
    }

    @State.action.bound
    public addError() {
        this.validationResults = [
            {
                entityName: "test",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "testValue",
                        severity: "error",
                        message: "This filed is required. Please fill it with proper information.",
                    }
                ]
            }
        ];
    }

    @State.action.bound
    public removeValidation() {
        this.validationResults = null;
    }

    @State.bound
    @State.action
    public addWarning() {
        this.validationResults = [
            {
                entityName: "test",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "testValue",
                        severity: "warning",
                        message: "This filed is required. Please fill it with proper information.",
                    }
                ]
            }
        ];
    }

    @State.action.bound
    public setDisable() {
        this.isDisabled = !this.isDisabled;
    }
}
