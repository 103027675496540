import React from "react";
import * as Ui from "@CommonControls";
import * as DiContainer from "@DiContainer";
import AccordionExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/AccordionExampleStore";
import SourceCode from "./SourceCode";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class AlertCardExampleBox extends React.Component {

    public render() {
        return (
            <>
                <Ui.PageBox title="AlertCard">
                    <Ui.PageBox.Body>
                        <Ui.Panel inverted>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={6}>
                                    <b>ALERTCARDS (OPENED)</b><br />
                                    Height: 40px<br />
                                    Margin (between Cards and Container): 4px<br />
                                    Left-margin (between first-child Card and the Labels of the Secondary Header): 24px<br />
                                    Horizontal Margins (between the Labels of a Card): 8px<br />
                                    Card Padding: 8px<br />
                                    Line-height (of the colored Labels of a Card): 14px<br /><br />
                                    <Ui.AlertCard label="Allergia" visualStyle="warning" />
                                    <Ui.AlertCard label="Allergia" visualStyle="error" />

                                    <br />
                                    <Ui.AlertCard label="Allergia" innerLabel={{ lowerText: "életveszélyes", upperText: "Penicilin" }} visualStyle="error" />
                                    <Ui.AlertCard label="Allergia" innerLabel={{ lowerText: "súlyos", upperText: "Parlagfű" }} visualStyle="warning" />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={6}>
                                    <b>ALERTCARDS (CLOSED)</b><br />
                                    Width: 16px<br />
                                    Height: 40px<br />
                                    Margin (between Cards and Container): 4px<br /><br />
                                    <Ui.AlertCard label="Allergia" visualStyle="error" collapsed />
                                    <Ui.AlertCard label="Allergia" visualStyle="warning" collapsed />
                                    <br />
                                    <Ui.AlertCard label="Allergia" innerLabel={{ lowerText: "életveszélyes", upperText: "Penicilin" }} visualStyle="error" collapsed />
                                    <Ui.AlertCard label="Allergia" innerLabel={{ lowerText: "súlyos", upperText: "Parlagfű" }} visualStyle="warning" collapsed />
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={6}>
                                    <Ui.SingleAccordion title="Source">
                                        <SourceCode language="html">
                                            {`
<Ui.AlertCard label="Allergia" visualStyle="error" />
<Ui.AlertCard label="Allergia" visualStyle="warning" />

<Ui.AlertCard label="Allergia" innerLabel={{ lowerText: "életveszélyes", upperText: "penicilin" }} visualStyle="error" />
<Ui.AlertCard label="Allergia" innerLabel={{ lowerText: "súlyos", upperText: "parlagfű" }} visualStyle="warning" />
`}
                                        </SourceCode>
                                    </Ui.SingleAccordion>
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </Ui.Panel>
                    </Ui.PageBox.Body>
                </Ui.PageBox >
                <Ui.PageBox title="AlertCardContainer">
                    <Ui.PageBox.Body>
                        <Ui.Panel inverted>
                            <Ui.Flex>
                                <Ui.Flex.Item xs={12}>
                                    Az AlertCardok alapvetően nyitott állapotban jelennek meg a Secondary Headeren belül. Szélességüket minden esetben a bennük lévő<br />
                                    szöveg határozza meg. Az AlertCardok súlyosságuk alapján rendeződnek: bal oldalra kerül a legsúlyosabb (életveszélyes) tényező.<br />
                                    Amennyiben nincs elég hely az összes nyitott állapotú AlertCard megjelenítéséhez, úgy a legkevésbé súlyos AlertCardok (értelemszerűen<br />
                                    a jobb oldalról kezdve) összezáródnak, a bennük lévő tartalmat a user hover állapotban tekintheti meg. <br /><br />

                                    <b>ALERTCARD OVERFLOW (CLOSED ITEMS - OPENED DROPDOWN)</b><br />
                                    <div style={{ maxWidth: "450px" }}>
                                        <Ui.AlertCardContainer>
                                            <Ui.AlertCard label="Allergia" innerLabel={{ lowerText: "életveszélyes", upperText: "Penicilin" }} visualStyle="error" />
                                            <Ui.AlertCard label="Allergia" innerLabel={{ lowerText: "életveszélyes", upperText: "Penicilin" }} visualStyle="error" />
                                            <Ui.AlertCard label="Allergia" innerLabel={{ lowerText: "súlyos", upperText: "Parlagfű" }} visualStyle="warning" />
                                            <Ui.AlertCard label="Allergia" innerLabel={{ lowerText: "súlyos", upperText: "Parlagfű" }} visualStyle="warning" />
                                        </Ui.AlertCardContainer>
                                    </div>
                                </Ui.Flex.Item>

                                <Ui.Flex.Item xs={12}><br />
                                    Az AlertCardok kinyílását és áthelyeződését Css Animációval tesszük a user számára egyértelművé.<br />
                                    <Ui.SingleAccordion title="Source">
                                        <SourceCode language="html">
                                            {`
                            <Ui.AlertCardContainer>
                                <Ui.AlertCard label="Allergia" innerLabel={{ lowerText: "életveszélyes", upperText: "Penicilin" }} visualStyle="error" />
                                <Ui.AlertCard label="Allergia" innerLabel={{ lowerText: "életveszélyes", upperText: "Penicilin" }} visualStyle="error" />
                                <Ui.AlertCard label="Allergia" innerLabel={{ lowerText: "súlyos", upperText: "Parlagfű" }} visualStyle="warning" />
                                <Ui.AlertCard label="Allergia" innerLabel={{ lowerText: "súlyos", upperText: "Parlagfű" }} visualStyle="warning" />
                            </Ui.AlertCardContainer>
`}
                                        </SourceCode>
                                    </Ui.SingleAccordion>
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </Ui.Panel>
                    </Ui.PageBox.Body>
                </Ui.PageBox >
            </>
        );
    }
}