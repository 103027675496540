import Di from "@Di";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class DateRangePickerExampleStore {

    @State.observable.ref public value: LocalDateRange = null;
    @State.observable.ref public validatableValue: LocalDateRange = null;
    @State.observable.ref public isDisabled: boolean = false;

    @State.observable public isRequired: boolean = false;
    @State.observable public isCompact: boolean = false;

    @State.observable.ref public validationResults: IClientValidationResult[] = null;

    @State.computed public get size(): "compact" | "standard" {
        return this.isCompact ? "compact" : undefined;
    }

    @State.action.bound
    public setValue(newValue: LocalDateRange) {
        this.value = newValue;
    }

    @State.action.bound
    public setValidatableValue(newValue: LocalDateRange) {
        this.validatableValue = newValue;
    }
    @State.action.bound
    public setRequired(newValue: boolean) {
        this.isRequired = newValue;
    }

    @State.action.bound
    public setCompact(newValue: boolean) {
        this.isCompact = newValue;
    }

    @State.action.bound
    public addError() {
        this.validationResults = [
            {
                entityName: "test",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "testValue",
                        severity: "error",
                        message: "This filed is required. Please fill it with proper information.",
                    }
                ]
            }
        ];
    }

    @State.action.bound
    public removeValidation() {
        this.validationResults = null;
    }

    @State.bound
    @State.action
    public addWarning() {
        this.validationResults = [
            {
                entityName: "test",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "testValue",
                        severity: "warning",
                        message: "This filed is required. Please fill it with proper information.",
                    }
                ]
            }
        ];
    }

    @State.action.bound
    public setDisable() {
        this.isDisabled = !this.isDisabled;
    }
}
