import React from "react";
import * as Ui from "@CommonControls";
import ListPanel from "@Toolkit/ReactClient/Components/ListPanel/ListPanel";
import State, { IObservableArray } from "@Toolkit/ReactClient/Common/StateManaging";
import StoreBase from "@Toolkit/CommonWeb/Model/StoreBase";
import QueryOperationInfo from "@Toolkit/CommonWeb/ApiAdapter/OperationInfo/QueryOperationInfo";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import _ from "@HisPlatform/Common/Lodash";
import IDialogService from "@Toolkit/ReactClient/Services/Definition/DialogService/IDialogService";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import DialogResultCode from "@Toolkit/ReactClient/Services/Definition/DialogService/DialogResultCode";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";





class Item {
    @State.observable.ref public addressType: string;
    @State.observable.ref public address: string;
    @State.observable.ref public validFrom: LocalDate;
    @State.observable.ref public validTo: LocalDate;

    private _snapshot: Item = null;

    constructor(addressType: string, address: string, validFrom: LocalDate, validTo: LocalDate) {
        this.addressType = addressType;
        this.address = address;
        this.validFrom = validFrom;
        this.validTo = validTo;
    }

    public takeSnapshot() {
        this._snapshot = new Item(this.addressType, this.address, this.validFrom, this.validTo);
    }

    public isDirty() {
        return !(
            this._snapshot.addressType === this.addressType &&
            this._snapshot.address === this.address &&
            LocalDate.areEquals(this._snapshot.validFrom, this.validFrom) &&
            LocalDate.areEquals(this._snapshot.validTo, this.validTo)
        );
    }

    public isEmpty() {
        return !this.addressType && !this.address;
    }

    @State.action.bound
    public revertToSnapshot() {
        this.addressType = this._snapshot.addressType;
        this.address = this._snapshot.address;
        this.validFrom = this._snapshot.validFrom;
        this.validTo = this._snapshot.validTo;
    }

    @State.action.bound
    public setAddressType(newValue: string) {
        this.addressType = newValue;
    }

    @State.action.bound
    public setAddress(newValue: string) {
        this.address = newValue;
    }

    @State.action.bound
    public setValidFrom(newValue: LocalDate) {
        this.validFrom = newValue;
    }

    @State.action.bound
    public setValidTo(newValue: LocalDate) {
        this.validTo = newValue;
    }
}

class List {
    public items: IObservableArray<Item>;
}

interface IListPanelExampleBoxDependencies {
    dialogService: IDialogService;
}

interface IListPanelExampleBoxProps extends IRoutingFrameContentProps {
    _dependencies?: IListPanelExampleBoxDependencies;
}

type TypedListPanel = new () => ListPanel<Item>;
const TypedListPanel = ListPanel as unknown as TypedListPanel;

@State.observer
class ListPanelExampleBox extends React.Component<IListPanelExampleBoxProps> {

    @State.observable.ref private actionButtonsOrientation: "horizontal" | "vertical" = "vertical";
    @State.observable.ref private list = new List();

    @State.observable.ref private validationResults: IClientValidationResult[] = [];

    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
    }

    public render() {
        return (
            <>
                <Ui.PageTitle title="List Panel" />
                {/* <Ui.PageBox title="Explicit edit mode">
                    <Ui.PageBox.Body>
                        <p>ListPanel is a list visualizer and editor component. It can be used for a master-detail behavior, when the detail form is simple and small (e.g.: max. 10 fields).</p>
                        <p>In explicit edit mode, the each item has an edit button. Changes of the existing items can be rolled back and adding a new item is transactional (confirm/cancel buttons).</p>

                        <label>Action buttons orientation:</label>
                        <StringRadioButtonGroup
                            items={["horizontal", "vertical"]}
                            value={this.actionButtonsOrientation}
                            onChange={newValue => State.runInAction(() => this.actionButtonsOrientation = newValue as any)}
                        />

                        <TypedListPanel
                            onCreateNewAsync={this.createNewItemAsync}
                            renderItemEditor={this.renderItemEditor}
                            actionButtonsOrientation={this.actionButtonsOrientation}
                            items={this.list.items}
                            onDropChangesConfirmationAsync={this.dropChangesConfirmationAsync}
                            onDeleteItemConfirmationAsync={this.deleteItemConfirmationAsync}
                            noItemsMessage="No addresses found."
                        />
                    </Ui.PageBox.Body>
                </Ui.PageBox>
                <Ui.PageBox title="Always edit mode">
                    <p>In always edit mode, all items are editable, no edit or confirm/revert changes buttons. Newly created items are added to the list immediately.</p>
                    <Ui.PageBox.Body>
                        <TypedListPanel
                            onCreateNewAsync={this.createNewItemAsync}
                            renderItemEditor={this.renderItemEditor}
                            actionButtonsOrientation="vertical"
                            items={this.list.items}
                            onDeleteItemConfirmationAsync={this.deleteItemConfirmationAsync}
                            noItemsMessage="No addresses found."
                            alwaysEdit
                        />
                    </Ui.PageBox.Body>
                </Ui.PageBox> */}
                <Ui.PageBox title="Validation trick">
                    <Ui.PageBox.Body>
                        <ValidationBoundary
                            validationResults={this.validationResults}
                            onValidateAsync={this.validateAsync}
                            entityTypeName="Test"
                            validateOnMount
                        >
                            <TypedListPanel
                                onCreateNewAsync={this.createNewItemAsync}
                                renderItemEditor={this.renderItemEditor}
                                actionButtonsOrientation="vertical"
                                items={this.list.items}
                                onDeleteItemConfirmationAsync={this.deleteItemConfirmationAsync}
                                noItemsMessage="No addresses found."
                                alwaysEdit
                                propertyIdentifier="Addresses"
                            />
                        </ValidationBoundary>
                    </Ui.PageBox.Body>
                </Ui.PageBox>
            </>
        );
    }

    @State.bound
    private validateAsync(): Promise<IClientValidationResult[]> {
        const result: IClientValidationResult = {
            entityName: "Test",
            checkedRules: [],
            problems: [],
            entityId: "1"
        };

        this.list.items.forEach((i, idx) => {
            result.checkedRules.push({ propertyPath: `Addresses[${idx}].Type`, ruleId: "ShouldBeFilled", severity: "error" });

            if (!i.addressType) {
                result.problems.push({ propertyPath: `Addresses[${idx}].Type`, ruleId: "ShouldBeFilled", severity: "error", message: "Ejnye na." });
            }
        });

        return Promise.resolve([result]);
    }

    @State.bound
    private async deleteItemConfirmationAsync(item: Item) {
        const dialogResult = await this.props._dependencies.dialogService.yesNo("Figyelem", `Biztosan törli a(z) ${item.addressType} címet? (${item.address})`);
        return dialogResult.resultCode === DialogResultCode.Yes;
    }

    @State.bound
    private async dropChangesConfirmationAsync(item: Item) {
        const dialogResult = await this.props._dependencies.dialogService.yesNo("Figyelem", `A ${item.addressType} cím módosítva lett. Biztosan elveti a módosításokat?`);
        return dialogResult.resultCode === DialogResultCode.Yes;
    }

    private createNewItemAsync() {
        return Promise.resolve(new Item("", "", LocalDate.today(), LocalDate.createFromMoment(DateTimeService.now().add(1, "year"))));
    }

    @State.bound
    private loadAsync(): Promise<void> {
        return new Promise((resolve: () => void) => {
            setTimeout(() => {
                const res = new List();
                res.items = State.observable([
                    new Item("Elsődleges", "1116 Bp Kondorosi út 296.", LocalDate.createFromMoment(DateTimeService.now().subtract(1, "year")), LocalDate.createFromMoment(DateTimeService.now().add(1, "year"))),
                    new Item("Levelezési", "1196 Bp. Áchim A. utca 99.", LocalDate.createFromMoment(DateTimeService.now().subtract(2, "year")), LocalDate.createFromMoment(DateTimeService.now().add(2, "year"))),
                ]);
                State.runInAction(() => {
                    this.list = res;
                });
                resolve();
            }, 500);
        });
    }

    @State.bound
    private renderItemEditor(item: Item, index: number) {
        return (
            <State.Observer>{() => (
                <>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <Ui.TextBox automationId=""
                                label="Típus"
                                value={item.addressType}
                                onChange={item.setAddressType}
                                propertyIdentifier="Type"
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={8}>
                            <Ui.TextBox automationId=""
                                label="Cím"
                                value={item.address}
                                onChange={item.setAddress}
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6}>
                            <Ui.DatePicker automationId=""
                                label="Érvényesség kezdete"
                                value={item.validFrom}
                                onChange={item.setValidFrom}
                            />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            <Ui.DatePicker automationId=""
                                label="Érvényesség vége"
                                value={item.validTo}
                                onChange={item.setValidTo}
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </>
            )}</State.Observer>
        );
    }

    private valueSetterFactory(item: Item, propertyName: string) {
        return (newValue: any) => {
            State.runInAction(() => {
                item[propertyName] = newValue;
            });
        };
    }
}

export default connect(
    ListPanelExampleBox,
    new DependencyAdapter<IListPanelExampleBoxProps, IListPanelExampleBoxDependencies>(c => ({
        dialogService: c.resolve<IDialogService>("IDialogService"),
    }))
);