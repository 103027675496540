import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";
import SourceCode from "./SourceCode";

@State.observer
export default class ValidationResultExampleBox extends React.Component {

    @State.observable.ref public highlightedProblem: IClientValidationProblem = null;
    
    @State.action.bound
    public setHighlightedProblem(highlightedProblem: IClientValidationProblem) {
        this.highlightedProblem = highlightedProblem;
    }

    private makeResult(severity: "error" | "warning"): IClientValidationProblem {
        return {
            message: "PerformedServiceList.ShouldBeCompliantWithFinancedServiceHealthCareProfessionRules",
            propertyPath: "",
            ruleId: "ShouldBeCompliantWithFinancedServiceHealthCareProfessionRules",
            severity: severity,
            parameters:
                {
                    PerformedServiceHealthCareProfessionRuleViolationParametersDto:
                    {
                        ServiceId:
                        { Value: 65 },
                        CurrentHealthcareProfessionId: { Value: 311 },
                        CorrectHealthcareProfessionIds: [{ Value: 351 }],
                    }
                }
        };
    }

    @State.observable.ref private results = [
        this.makeResult("error"),
        this.makeResult("error"),
        this.makeResult("warning"),
        this.makeResult("warning"),
    ] as IClientValidationProblem[];

    @State.action.bound
    public setResults(newValue: IClientValidationProblem[]) {
        this.results = newValue;
    }

    @State.bound
    private addWarning() {
        this.setResults(this.results.concat(this.makeResult("warning")));
    }

    @State.bound
    private addError() {
        this.setResults(this.results.concat(this.makeResult("error")));
    }

    @State.bound
    private clear() {
        this.setResults([]);
    }

    private renderInfo(info: IClientValidationProblem) {
        return (
            <div>
                <h2>This is a tooltip for: </h2>
                <p>{info.message}</p>
            </div>
        );
    }

    public render() {
        return (
            <Ui.PageBox title="ValidationResultSummary">
                <Ui.PageBox.Body>
                    <Ui.Flex>
                        <Ui.Button text="Add error" onClick={this.addError} automationId="addErrorButton" />
                        <Ui.Button text="Add warning" onClick={this.addWarning} automationId="addWarningButton" />
                        <Ui.Button text="Clear"  onClick={this.clear} automationId="clearButton" />
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <h1>Summary</h1>
                            <Ui.ValidationResultSummary displayMode="summary" results={this.results}
                                highlightedId={this.highlightedProblem}
                                setHighlightedId={this.setHighlightedProblem}
                                infoRenderer={this.renderInfo}
                            />
                            <Ui.SingleAccordion title="Source">
                                <SourceCode language="html">
                                    {`<Ui.ValidationResultSummary displayMode="summary"
    results={this.results}
    highlightedId={this.highlightedProblem}
    setHighlightedId={this.setHighlightedProblem}
    infoRenderer={this.renderInfo}
/>`}
                                </SourceCode>
                            </Ui.SingleAccordion>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={12}>
                            <h1>List</h1>
                            <Ui.ValidationResultSummary displayMode="list" results={this.results} />
                            <Ui.SingleAccordion title="Source">
                                <SourceCode language="html">
                                    {`<Ui.ValidationResultSummary displayMode="list" results={this.results} />`}
                                </SourceCode>
                            </Ui.SingleAccordion>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}