import React from "react";
import * as Ui from "@CommonControls";
import DrawerExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/DrawerExampleStore";
import * as DiContainer from "@DiContainer";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SourceCode from "./SourceCode";
import IDemoExtensionPointProps, { IDemoStore } from "@PluginInterface/BoundedContexts/Care/PatientRegister/PatientBaseData/ExtensionPoints/IDemoExtensionPointProps";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import HisPlatformExtensionPoint from "@HisPlatform/Components/HisPlatformExtensionPoint/HisPlatformExtensionPoint";

class InnerStore implements IDemoStore {
    @State.observable.ref public value: string = "";
    @State.observable.ref public type: "international" | "hun" = null;

    @State.action.bound
    public setValue(newValue: string) {
        this.value = newValue;
    }

    @State.action.bound
    public setType(newType: "international" | "hun") {
        this.type = newType;
    }
}

const typeItems: Array<ISelectBoxItem<"international" | "hun">> = [
    {
        text: "International",
        value: "international"
    },
    {
        text: "Hun",
        value: "hun"
    },
];


const PlatformComponent: React.FC<{ innerStore: InnerStore }> = props => (
    <State.Observer>
        {() => (
            <>
                <Ui.SelectBox automationId="" label="Type" items={typeItems} value={props.innerStore.type} onChange={props.innerStore.setType} />
                This is the fallback UI: <Ui.TextBox automationId="" value={props.innerStore.value} onChange={props.innerStore.setValue} />
            </>
        )}
    </State.Observer>
);


@State.observer
export default class ExtensionPointExampleBox extends React.Component {

    private innerStore = new InnerStore();

    private extensionProps: IDemoExtensionPointProps = {
        store: this.innerStore
    };

    public render() {
        return (
            <>
                <Ui.PageBox title="ExtensionPoint">
                    <Ui.PageBox.Body>
                        <p>HisPlatform defines several UI extendibility points using the ExtensionPoint component.</p>
                        <p>To create a new extension point:
                            <ol>
                                <li>Define a new ExtensionPointType. (It is a simple string type-union, you can find it in the PluginInterface.)</li>
                                <li>Define a component props interface (also in PluginInterface). This interface defines all the props required by the extension component to operate. (e.g.: a MobX store)</li>
                                <li>Add your newly defined props interface to the IExtensionPointProps type union (in PluginInterface).</li>
                                <li>Use the HisPlatformExtensionPoint component somewhere in HisPlatform to place an extension point. It will display its children (as international version) or the extension component.</li>
                                <li>Create a discriminator getter method. In this method you should 'touch' (MobX will track the accessed observables) all properties from extension props which properties used to determine the extension component to display.</li>
                            </ol>
                        </p>
                        <p>
                            To create a new extension:
                            <ol>
                                <li>Create a new component in your plugin with a shouldOverride static method. This method should decide to override the platform implementation or not.</li>
                                <li>Register your component as an extension in the plugin's configuration section (IPlatformPlugin.configureExtensionComponents).</li>
                            </ol>
                        </p>
                        <div style={{ border: "1px dashed black", padding: 16 }}>
                            <HisPlatformExtensionPoint
                                extensionProps={this.extensionProps}
                                type="demo_extension_1"
                            >
                                <PlatformComponent innerStore={this.innerStore} />
                            </HisPlatformExtensionPoint>
                        </div>
                        <Ui.SingleAccordion title="Source">
                            <SourceCode language="">
                                {`

// ... Define a new ExtensionPointType:

type ExtensionPointType = 
    "demo_extension_1" |
    "demo_extension_2";

// ... Define a props interface:

interface IDemoExtensionPointProps {
    store: IDemoStore;
}

// ... Add the new props interface to type-union:

export type IExtensionPointProps = 
    IDemoExtensionPointProps |
    IDemo2ExtensionPointProps;

// ... Place an extension point:

<HisPlatformExtensionPoint
    extensionProps={this.extensionProps}
    getDiscriminator={this.getDiscriminator}
    type="demo_extension_1"
>
    <PlatformComponent innerStore={this.innerStore} />
</HisPlatformExtensionPoint>

// Define a getDiscriminator method:

@State.bound
private getDiscriminator(props: IDemoExtensionPointProps) {
    return props.store.type;
}

// ... Create an extension component in plugin:

@State.observer
export default class DemoExtension1 extends React.Component<IDemoExtensionPointProps> {

    public static shouldOverride = (props: IDemoExtensionPointProps) => props.store.type === "hun";

    public render() {
        return (
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 3" width="100%" height={100} style={{ margin: 8 }}>
                    <rect fill="#436F4D" width="6" height="3" />
                    <rect fill="#FFF" width="6" height="2" />
                    <rect fill="#CD2A3E" width="6" height="1" />
                </svg>
                <Ui.SelectBox label="Type" items={typeItems} value={this.props.store.type} onChange={this.props.store.setType} />
                This is the Hungarian UI: <Ui.TextBox value={this.props.store.value} onChange={this.props.store.setValue} />
            </div>
        );
    }
}

`}
                            </SourceCode>
                        </Ui.SingleAccordion>
                    </Ui.PageBox.Body>
                </Ui.PageBox>
            </>
        );
    }
}