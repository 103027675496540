import React from "react";
import * as Ui from "@CommonControls";
import * as DiContainer from "@DiContainer";
import RadioButtonExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/RadioButtonExampleStore";
import GenderId from "@Primitives/GenderId.g";
import SourceCode from "./SourceCode";
import { StringRadioButtonGroup, RadioButtonGroupFactory } from "@CommonControls/RadioButtonGroup";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import State from "@Toolkit/ReactClient/Common/StateManaging";


const GenderRadioButtonGroup = RadioButtonGroupFactory<GenderId>();

@State.observer
export default class RadioButtonExampleBox extends React.Component {

    @DiContainer.lazyResolve("RadioButtonExampleStore") private store: RadioButtonExampleStore;

    public render() {
        return (
            <Ui.PageBox title="Radio button">
                <Ui.PageBox.Body>
                    <h3>Direction: row</h3>
                    <StringRadioButtonGroup
                        direction="row"
                        items={this.store.items1}
                        value={this.store.selectedValue1}
                        onChange={this.store.setSelectedValue1} />
                    <pre>Value: {this.store.selectedValue1}</pre>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`<StringRadioButtonGroup
direction="row"
items={this.store.items1}
value={this.store.selectedValue1}
onChange={this.store.setSelectedValue1} />

@State.observable public selectedValue1: string = "First";

public items1 = ["First", "Second", "Third"];

@State.action.bound
    public setSelectedValue1(newValue: string) {
        this.selectedValue1 = newValue;
    }
`}
                        </SourceCode>
                    </Ui.SingleAccordion>
                    <h3>Direction: column</h3>
                    <StringRadioButtonGroup
                        direction="column"
                        items={this.store.items2}
                        value={this.store.selectedValue2}
                        onChange={this.store.setSelectedValue2} />
                    <pre>Value: {this.store.selectedValue2}</pre>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`<StringRadioButtonGroup
direction="column"
items={this.store.items2}
value={this.store.selectedValue2}
onChange={this.store.setSelectedValue2} />

@State.observable public selectedValue2: string = "Apple";

public items2 = ["Apple", "Orange", "Banana"];

@State.action.bound
    public setSelectedValue2(newValue: string) {
        this.selectedValue2 = newValue;
    }
`}
                        </SourceCode>
                    </Ui.SingleAccordion>
                    <h3>Usage with extensible enums</h3>
                    <GenderRadioButtonGroup
                        direction="column"
                        items={this.store.genderIds}
                        value={this.store.selectedGenderId}
                        onChange={this.store.setGenderId}
                        getDisplayValue={this.store.getGenderDisplayValue} />
                    <pre>Value: {this.store.selectedGenderId && this.store.selectedGenderId.value}</pre>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`<GenderRadioButtonGroup
direction="column"
items={this.store.genderIds}
value={this.store.selectedGenderId}
onChange={this.store.setGenderId}
getDisplayValue={this.store.getGenderDisplayValue} />

@State.observable public selectedGenderId: GenderId = new GenderId("Male");

public genderIds = [new GenderId("Male"), new GenderId("Female")];

@State.action.bound
    public setGenderId(newValue: GenderId) {
        this.selectedGenderId = newValue;
    }
`}
                        </SourceCode>
                    </Ui.SingleAccordion>

                    <h3>Display type: buttons</h3>
                    <StringRadioButtonGroup
                        direction="row"
                        items={this.store.items1}
                        value={this.store.selectedValue1}
                        onChange={this.store.setSelectedValue1}
                        getIconName={this.store.getIconNameForValues}
                        displayType="buttons" />
                    <pre>Value: {this.store.selectedValue1}</pre>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`<StringRadioButtonGroup
direction="row"
items={this.store.items1}
value={this.store.selectedValue1}
onChange={this.store.setSelectedValue1}
getIconName={this.store.getIconNameForValues}
displayType="buttons" />

@State.observable public selectedValue1: string = "First";

public items1 = ["First", "Second", "Third"];

@State.action.bound
    public setSelectedValue1(newValue: string) {
        this.selectedValue1 = newValue;
    }

    public getIconNameForValues(value: string) {
        if (value === "First") {
            return "search";
        } else if (value === "Second") {
            return "pen";
        }
        return "check";
    }
`}
                        </SourceCode>
                    </Ui.SingleAccordion>
                    <h3>Display type: grouped buttons</h3>
                    <StringRadioButtonGroup
                        direction="row"
                        items={this.store.items1}
                        value={this.store.selectedValue1}
                        onChange={this.store.setSelectedValue1}
                        displayType="groupedButtons" />
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`<StringRadioButtonGroup
direction="row"
items={this.store.items1}
value={this.store.selectedValue1}
onChange={this.store.setSelectedValue1}
displayType="groupedButtons" />

@State.observable public selectedValue1: string = "First";

public items1 = ["First", "Second", "Third"];

@State.action.bound
    public setSelectedValue1(newValue: string) {
        this.selectedValue1 = newValue;
    }
`}
                        </SourceCode>
                    </Ui.SingleAccordion>

                    <h3>Display type: grouped buttons - with label</h3>
                    <StringRadioButtonGroup
                        direction="row"
                        items={this.store.items1}
                        value={this.store.selectedValue1}
                        onChange={this.store.setSelectedValue1}
                        displayType="groupedButtons"
                        label="Label" />

                    <h3>Extensible enums with icons</h3>
                    <GenderRadioButtonGroup
                        direction="row"
                        displayType="groupedButtons"
                        items={this.store.genderIds}
                        value={this.store.selectedGenderId}
                        onChange={this.store.setGenderId}
                        getDisplayValue={this.store.getGenderDisplayValue}
                        getIconName={this.store.getGenderIcons} 
                        getIconStyle={this.store.getGenderIconStyle}
                    />
                    <pre>Value: {this.store.selectedGenderId && this.store.selectedGenderId.value}</pre>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`<GenderRadioButtonGroup
direction="row"
displayType="groupedButtons"
items={this.store.genderIds}
value={this.store.selectedGenderId}
onChange={this.store.setGenderId}
getDisplayValue={this.store.getGenderDisplayValue}
getIconName={this.store.getGenderIcons} />

@State.observable public selectedGenderId: GenderId = new GenderId("Male");

public genderIds = [new GenderId("Male"), new GenderId("Female")];

@State.action.bound
    public setGenderId(newValue: GenderId) {
        this.selectedGenderId = newValue;
    }

    public getGenderIcons(value: GenderId) {
        if (value.value === "Male") {
            return "male";
        } else {
            return "female";
        }
    }
`}
                        </SourceCode>
                    </Ui.SingleAccordion>
                    <hr />
                    <h3>Validation</h3>
                    <ValidationBoundary validationResults={this.store.validationResults} entityTypeName="test">

                        <GenderRadioButtonGroup
                            direction="column"
                            items={this.store.genderIds}
                            value={this.store.selectedGenderId}
                            onChange={this.store.setGenderId}
                            getDisplayValue={this.store.getGenderDisplayValue}
                            getIconName={this.store.getGenderIcons}
                            getIconStyle={this.store.getGenderIconStyle}
                            propertyIdentifier="testValue" />

                        <GenderRadioButtonGroup
                            direction="row"
                            displayType="groupedButtons"
                            items={this.store.genderIds}
                            value={this.store.selectedGenderId}
                            onChange={this.store.setGenderId}
                            getDisplayValue={this.store.getGenderDisplayValue}
                            getIconName={this.store.getGenderIcons}
                            getIconStyle={this.store.getGenderIconStyle}
                            propertyIdentifier="testValue" />

                        <GenderRadioButtonGroup
                            direction="row"
                            displayType="buttons"
                            items={this.store.genderIds}
                            value={this.store.selectedGenderId}
                            onChange={this.store.setGenderId}
                            getDisplayValue={this.store.getGenderDisplayValue}
                            getIconName={this.store.getGenderIcons}
                            getIconStyle={this.store.getGenderIconStyle}
                            propertyIdentifier="testValue" />

                        <h3>Display type: grouped buttons - with label - with validation</h3>
                        <StringRadioButtonGroup
                            direction="row"
                            items={this.store.items1}
                            value={this.store.selectedValue1}
                            onChange={this.store.setSelectedValue1}
                            displayType="groupedButtons"
                            label="Label"
                            propertyIdentifier="testValue" />

                    </ValidationBoundary>
                    <br />
                    <Ui.Button automationId="" onClick={this.store.removeValidation}>Remove validation</Ui.Button>
                    <Ui.Button automationId="" onClick={this.store.addError}>Add validation error</Ui.Button>
                    <Ui.Button automationId="" onClick={this.store.addWarning}>Add validation warning</Ui.Button>

                    <hr />
                    <h3>Disabled</h3>

                    <GenderRadioButtonGroup
                        direction="row"
                        items={this.store.genderIds}
                        value={this.store.selectedGenderId}
                        onChange={this.store.setGenderId}
                        getDisplayValue={this.store.getGenderDisplayValue}
                        getIconName={this.store.getGenderIcons}
                        getIconStyle={this.store.getGenderIconStyle}
                        propertyIdentifier="testValue"
                        disabled={true} />

                    <GenderRadioButtonGroup
                        direction="row"
                        displayType="groupedButtons"
                        items={this.store.genderIds}
                        value={this.store.selectedGenderId}
                        onChange={this.store.setGenderId}
                        getDisplayValue={this.store.getGenderDisplayValue}
                        getIconName={this.store.getGenderIcons}
                        getIconStyle={this.store.getGenderIconStyle}
                        propertyIdentifier="testValue"
                        disabled={true} />

                    <GenderRadioButtonGroup
                        direction="row"
                        displayType="buttons"
                        items={this.store.genderIds}
                        value={this.store.selectedGenderId}
                        onChange={this.store.setGenderId}
                        getDisplayValue={this.store.getGenderDisplayValue}
                        getIconName={this.store.getGenderIcons}
                        getIconStyle={this.store.getGenderIconStyle}
                        propertyIdentifier="testValue"
                        disabled={true} />
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}