import React from "react";
import * as Ui from "@CommonControls";
import * as DiContainer from "@DiContainer";
import DatePickerExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/DatePickerExampleStore";
import SourceCode from "./SourceCode";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class MonthPickerExampleBox extends React.Component {

    @DiContainer.lazyResolve("DatePickerExampleStore") private store: DatePickerExampleStore;

    public render() {
        return (
            <Ui.PageBox title="MonthPicker">
                <Ui.PageBox.Body>
                <ValidationBoundary validationResults={this.store.validationResults} entityTypeName="test">
                        <p>Month picker variant of the DatePicker component.</p>
                        <Ui.MonthPicker value={this.store.yearMonthValue}
                            onChange={this.store.setYearMonthValue}
                            propertyIdentifier="testValue"
                            disabled={this.store.isDisabled}
                            label="My value:"
                            automationId=""
                        />
                        <br />
                        <Ui.MonthPicker value={this.store.yearMonthValue}
                            onChange={this.store.setYearMonthValue}
                            propertyIdentifier="testValue"
                            disabled={this.store.isDisabled} required
                            label="My value:"
                            automationId=""
                        />
                        <br />
                        <Ui.MonthPicker value={this.store.yearMonthValue}
                            onChange={this.store.setYearMonthValue}
                            propertyIdentifier="testValue"
                            disabled={this.store.isDisabled}
                            label="My value:"
                            size="compact"
                            automationId=""
                        />
                        <br />
                        <Ui.MonthPicker value={this.store.yearMonthValue}
                            onChange={this.store.setYearMonthValue}
                            propertyIdentifier="testValue"
                            disabled={this.store.isDisabled} required
                            label="My value:"
                            size="compact"
                            automationId=""
                        />
                        <br />
                        <p>Readonly</p>
                        <Ui.MonthPicker value={this.store.yearMonthValue}
                            onChange={this.store.setYearMonthValue}
                            propertyIdentifier="testValue"
                            disabled={this.store.isDisabled}
                            label="My value:"
                            size="compact"
                            isReadOnly
                            automationId=""
                        />
                        <br />
                        <Ui.MonthPicker value={this.store.yearMonthValue}
                            onChange={this.store.setYearMonthValue}
                            propertyIdentifier="testValue"
                            disabled={this.store.isDisabled} required
                            label="My value:"
                            size="compact"
                            isReadOnly
                            automationId=""
                        />

                        <pre>Value: {this.store.yearMonthValue && this.store.yearMonthValue.stringify()}</pre>
                    </ValidationBoundary>

                    <Ui.Button automationId="" onClick={this.store.removeValidation}>Remove validation</Ui.Button>
                    <Ui.Button automationId="" onClick={this.store.addError}>Add validation error</Ui.Button>
                    <Ui.Button automationId="" onClick={this.store.addWarning}>Add validation warning</Ui.Button>
                    <Ui.Button automationId="" onClick={this.store.setDisable}>{this.store.isDisabled ? "Enable" : "Disable"}</Ui.Button>

                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
<Ui.MonthPicker value={this.store.yearMonthValue} onChange={this.store.setYearMonthValue} masked={false} />
`}
                        </SourceCode>

                        <SourceCode language="typescript">
                            {`
@State.observable.ref public yearMonthValue: YearMonth = null;

@State.action.bound
public setYearMonthValue(newValue: YearMonth) {
    this.yearMonthValue = newValue;
}
`}
                        </SourceCode>
                    </Ui.SingleAccordion>

                    <hr />

                    <h3>Validatable value</h3>
                    <label>My value:</label>
                    {/* <Ui.DatePicker value={this.store.validatedDate} onChange={this.store.setValidatedDateValue} />
                    <pre>Value: {this.store.validatedDate.value && this.store.validatedDate.value.stringify()}</pre>

                    <Ui.Button onClick={this.store.addError}>Add validation error</Ui.Button>
                    <Ui.Button onClick={this.store.addWarning}>Add validation warning</Ui.Button> */}

                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
<Ui.MonthPicker value={this.store.validatedDate} onChange={this.store.setValidatedDateValue} />
`}
                        </SourceCode>
                        <SourceCode language="typescript">
                            {`
public validatedDate = new ValidatableProperty<LocalDate>(null);

@State.action.bound
public setValidatedDateValue(newValue: LocalDate) {
    this.validatedDate.value = newValue;
}
`}
                        </SourceCode>
                    </Ui.SingleAccordion>
                </Ui.PageBox.Body>
            </Ui.PageBox >
        );
    }
}
