import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LargeDataToken from "@Primitives/LargeDataToken.g";

@State.observer
export default class PdfViewer2ExampleBox extends React.Component {

    private documentPath = new LargeDataToken("eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2Mzg0NjIwNzAsImRhdGEiOiJ7XCJEYXRhSWRcIjp7XCJWYWx1ZVwiOjM3fSxcIlVzZXJJZFwiOlwiVXNlckFcIn0ifQ.fA1YdfPqTKZhCObYmUrjnOyykrSBD32tzwB4xx4nvHc");
    private downloadFileName = "37";

    public render() {
        return (
            <Ui.PageBox title="Pdf Viewer 2">
                <Ui.PageBox.Body hasNoGaps>
                    <Ui.PdfViewer2 documentPath={this.documentPath} downloadFileName={this.downloadFileName} />
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}