import React from "react";
import * as Ui from "@CommonControls";
import * as DiContainer from "@DiContainer";
import DatePickerExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/DatePickerExampleStore";
import SourceCode from "./SourceCode";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class DatePickerExampleBox extends React.Component {

    @DiContainer.lazyResolve("DatePickerExampleStore") private store: DatePickerExampleStore;

    public render() {
        return (
            <Ui.PageBox title="Date(Time) picker">
                <Ui.PageBox.Body>
                <ValidationBoundary validationResults={this.store.validationResults} entityTypeName="test">
                        <p>A date picker or popup calendar is a graphical user interface widget which allows the user to select a date from a calendar.</p>
                        <Ui.DatePicker value={this.store.dateValue}
                            onChange={this.store.setDateValue}
                            propertyIdentifier="testValue"
                            disabled={this.store.isDisabled}
                            label="My value:"
                            automationId=""
                        />
                        <br />
                        <Ui.DatePicker value={this.store.dateValue}
                            onChange={this.store.setDateValue}
                            propertyIdentifier="testValue"
                            disabled={this.store.isDisabled} required
                            label="My value:"
                            automationId=""
                        />
                        <br />
                        <Ui.DatePicker value={this.store.dateValue}
                            onChange={this.store.setDateValue}
                            propertyIdentifier="testValue"
                            disabled={this.store.isDisabled}
                            label="My value:"
                            size="compact"
                            automationId=""
                        />
                        <br />
                        <Ui.DatePicker value={this.store.dateValue}
                            onChange={this.store.setDateValue}
                            propertyIdentifier="testValue"
                            disabled={this.store.isDisabled} required
                            label="My value:"
                            size="compact"
                            automationId=""
                        />
                        <br />
                        <p>Readonly</p>
                        <Ui.DatePicker value={this.store.dateValue}
                            onChange={this.store.setDateValue}
                            propertyIdentifier="testValue"
                            disabled={this.store.isDisabled}
                            label="My value:"
                            size="compact"
                            isReadOnly
                            automationId=""
                        />
                        <br />
                        <Ui.DatePicker value={this.store.dateValue}
                            onChange={this.store.setDateValue}
                            propertyIdentifier="testValue"
                            disabled={this.store.isDisabled} required
                            label="My value:"
                            size="compact"
                            isReadOnly
                            automationId=""
                        />

                        <pre>Value: {this.store.dateValue && this.store.dateValue.stringify()}</pre>
                    </ValidationBoundary>

                    <Ui.Button automationId="" onClick={this.store.removeValidation}>Remove validation</Ui.Button>
                    <Ui.Button automationId="" onClick={this.store.addError}>Add validation error</Ui.Button>
                    <Ui.Button automationId="" onClick={this.store.addWarning}>Add validation warning</Ui.Button>
                    <Ui.Button automationId="" onClick={this.store.setDisable}>{this.store.isDisabled ? "Enable" : "Disable"}</Ui.Button>

                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
<Ui.DatePicker value={this.store.dateValue} onChange={this.store.setDateValue} masked={false} />
`}
                        </SourceCode>

                        <SourceCode language="typescript">
                            {`
@State.observable.ref public dateValue: LocalDate = null;

@State.action.bound
public setDateValue(newValue: LocalDate) {
    this.dateValue = newValue;
}
`}
                        </SourceCode>
                    </Ui.SingleAccordion>

                    <hr />

                    <h3>Validatable value</h3>
                    <label>My value:</label>
                    {/* <Ui.DatePicker value={this.store.validatedDate} onChange={this.store.setValidatedDateValue} />
                    <pre>Value: {this.store.validatedDate.value && this.store.validatedDate.value.stringify()}</pre>

                    <Ui.Button onClick={this.store.addError}>Add validation error</Ui.Button>
                    <Ui.Button onClick={this.store.addWarning}>Add validation warning</Ui.Button> */}

                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
<Ui.DatePicker value={this.store.validatedDate} onChange={this.store.setValidatedDateValue} />
`}
                        </SourceCode>
                        <SourceCode language="typescript">
                            {`
public validatedDate = new ValidatableProperty<LocalDate>(null);

@State.action.bound
public setValidatedDateValue(newValue: LocalDate) {
    this.validatedDate.value = newValue;
}
`}
                        </SourceCode>
                    </Ui.SingleAccordion>

                    <hr />

                    <h2>DateTimePicker</h2>

                    <label>My value:</label>
                    <Ui.DateTimePicker automationId="" value={this.store.dateTimeValue} onChange={this.store.setDateTimeValue} />
                    <pre>Value: {this.store.dateTimeValue && this.store.dateTimeValue.format()}</pre>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
<Ui.DateTimePicker value={this.store.dateTimeValue} onChange={this.store.setDateTimeValue} masked={false} />
`}
                        </SourceCode>

                        <SourceCode language="typescript">
                            {`
@State.observable.ref public dateTimeValue: moment.Moment = null;

@State.action.bound
public setDateTimeValue(newValue: moment.Moment) {
    this.dateTimeValue = newValue;
}
`}
                        </SourceCode>
                    </Ui.SingleAccordion>

                    <hr />

                    <Ui.Flex>

                        <Ui.Flex.Item xs={6}>

                            <h3>Validatable value</h3>

                            <label>My value:</label>
                            {/* <Ui.DateTimePicker value={this.store.validatedDateTime} onChange={this.store.setValidatedDateTimeValue} />
                            <pre>Value: {this.store.validatedDateTime.value && this.store.validatedDateTime.value.format()}</pre>

                            <Ui.Button onClick={this.store.addError2}>Add validation error</Ui.Button>
                            <Ui.Button onClick={this.store.addWarning2}>Add validation warning</Ui.Button> */}

                        </Ui.Flex.Item>

                        <Ui.Flex.Item xs={6}>

                            <h3>Validatable, seconds precision</h3>

                            <label>My value:</label>
                            {/* <Ui.DateTimePicker value={this.store.validatedDateTime2} onChange={this.store.setValidatedDateTime2Value} precision="seconds" />
                            <pre>Value: {this.store.validatedDateTime2.value && this.store.validatedDateTime2.value.format()}</pre> */}

                        </Ui.Flex.Item>

                    </Ui.Flex>

                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
<Ui.DateTimePicker value={this.store.validatedDateTime} onChange={this.store.setValidatedDateTimeValue} />
`}
                        </SourceCode>
                        <SourceCode language="typescript">
                            {`
public validatedDateTime = new ValidatableProperty<moment.Moment>(null);

@State.action.bound
public setValidatedDateTimeValue(newValue: moment.Moment) {
    this.validatedDateTime.value = newValue;
}
`}
                        </SourceCode>
                    </Ui.SingleAccordion>
                </Ui.PageBox.Body>
            </Ui.PageBox >
        );
    }
}
