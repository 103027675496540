import React from "react";
import * as Ui from "@CommonControls";
import * as DiContainer from "@DiContainer";
import ToastStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/ToastStore";
import SourceCode from "./SourceCode";

export default class ToastExampleBox extends React.Component {

    @DiContainer.lazyResolve("ToastStore") private toastStore: ToastStore;

    public render() {
        return (
            <Ui.PageBox title="Toast">
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <p>A Toast is a non modal, unobtrusive window element used to display brief, auto-expiring windows of information to a user.</p>
                        <Ui.Button onClick={this.toastStore.showSuccessToast} automationId="">Success</Ui.Button>
                        <Ui.Button onClick={this.toastStore.showInfoToast} automationId="">Info</Ui.Button>
                        <Ui.Button onClick={this.toastStore.showWarningToast} automationId="">Warning</Ui.Button>
                        <Ui.Button onClick={this.toastStore.showErrorToast} automationId="">Error</Ui.Button>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={6}>
                        <h3>Store</h3>
                        <SourceCode language="typescript">
                            {`
constructor(
    private notificationService: INotificationService,
) { }

@State.bound
@State.action
public doSomeOperation() {
    // ...
    this.toastService.success("The operation was successful");
    // ...
}
`}
                        </SourceCode>
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.PageBox>
        );
    }
}