import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import { createNestedRoutes } from "@Toolkit/ReactClient/Routing/CreateNestedRoutes";
import ApplicationRoutes from "@HisPlatform/Application/Routes/ApplicationRoutes";

const DeveloperPageRoutes = createNestedRoutes(ApplicationRoutes.developer, {
    icon: new RouteDefinition("/icon"),
    alertCard: new RouteDefinition("/alertCard"),
    button: new RouteDefinition("/button"),
    dataGrid: new RouteDefinition("/dataGrid"),
    toast: new RouteDefinition("/toast"),
    dialog: new RouteDefinition("/dialog"),
    documentViewerModal: new RouteDefinition("/documentViewerModal"),
    modal: new RouteDefinition("/modal"),
    flex: new RouteDefinition("/flex"),
    workflowStepsIndicator: new RouteDefinition("/workflowStepsIndicator"),
    tabControl: new RouteDefinition("/tabControl"),
    contextMenu: new RouteDefinition("/contextMenu"),
    selector: new RouteDefinition("/selector"),
    textBox: new RouteDefinition("/textBox"),
    datePicker: new RouteDefinition("/datePicker"),
    dateRangePicker: new RouteDefinition("/dateRangePicker"),
    accordion: new RouteDefinition("/accordion"),
    checkBox: new RouteDefinition("/checkBox"),
    checkBoxGroup: new RouteDefinition("/checkBoxGroup"),
    selectBox: new RouteDefinition("/selectBox"),
    numberBox: new RouteDefinition("/numberBox"),
    universalCodeSelector: new RouteDefinition("/universalCodeSelector"),
    financedServiceCodeSelector: new RouteDefinition("/financedServiceCodeSelector"),
    documentEditor: new RouteDefinition("/documentEditor"),
    badge: new RouteDefinition("/badge"),
    sidebar: new RouteDefinition("/sidebar"),
    pdfViewer: new RouteDefinition("/pdfViewer"),
    pageTitle: new RouteDefinition("/pageTitle"),
    pageBox: new RouteDefinition("/pageBox"),
    documentViewer: new RouteDefinition("/documentViewer"),
    message: new RouteDefinition("/message"),
    table: new RouteDefinition("/table"),
    tooltip: new RouteDefinition("/tooltip"),
    loadingIndicator: new RouteDefinition("/loadingIndicator"),
    multiSelector: new RouteDefinition("/multiSelector"),
    drawer: new RouteDefinition("/drawer"),
    radioButton: new RouteDefinition("/radioButton"),
    listPanel: new RouteDefinition("/listPanel"),
    settlementTextbox: new RouteDefinition("/settlementTextbox"),
    sideMenuTextbox: new RouteDefinition("/sideMenu"),
    groupBox: new RouteDefinition("/groupBox"),
    dateTimePicker2: new RouteDefinition("/dateTimePicker2"),
    header: new RouteDefinition("/header"),
    extensionPoint: new RouteDefinition("/extensionPoint"),
    treeGrid: new RouteDefinition("/treeGrid"),
    treeView: new RouteDefinition("/treeView"),
    chart: new RouteDefinition("/chart"),
    infoButton: new RouteDefinition("/infoButton"),
    validationResultSummary: new RouteDefinition("/validationResultsSummary"),
    led: new RouteDefinition("/led"),
    masterDetail: new RouteDefinition("/masterDetail"),
    documentEditor2: new RouteDefinition("/documentEditor2"),
    signalr: new RouteDefinition("/signalR"),
    pdfViewer2: new RouteDefinition("/pdfViewer2"),
    scheduler: new RouteDefinition("/scheduler"),
    titleGroup: new RouteDefinition("/titleGroup"),
    infoBox: new RouteDefinition("/infoBox"),
    monthPicker: new RouteDefinition("/monthPicker"),
    durationBox: new RouteDefinition("/durationBox"),
    frontendActions: new RouteDefinition("/frontendActions/:selected?/:subDisplayMode?/:subAction?"),
    money: new RouteDefinition("/money"),
});

export default DeveloperPageRoutes;
