import State from "@Toolkit/ReactClient/Common/StateManaging";
import NotificationClient from "@Toolkit/CommonWeb/Notifications/NotificationClient";
import { ITestChatMessage } from "./ITestChatMessage";
import UserId from "@Primitives/UserId.g";

// !!! This is a custom solution, you should mostly use an encapsulated NotificationHandler component !!!
export class TestChatGroupClient extends NotificationClient {
    constructor(private onMessageReceived: (message: ITestChatMessage) => void) {
        super("TestChatGroup", (args: any) => {
            const message = JSON.parse(args);

            this.onMessageReceived({
                text: message.Message,
                userId: UserId.fromJS(message.UserId)
            });
        });
    }

    public setOnMessageReceived(onMessageReceived: (message: ITestChatMessage) => void) {
        this.onMessageReceived = onMessageReceived;
    }
    
    @State.bound
    public sendMessage(message: ITestChatMessage) {
        this.sendMessageToServer(JSON.stringify({
            UserId: message.userId.toJSON(),
            Message: message.text
        }));
    }
}
