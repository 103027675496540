exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CalendarStyles_high_2WLZE {\n  color: #ff0000;\n}\n.CalendarStyles_medium_1jpXn {\n  color: #fe8443;\n}\n.CalendarStyles_low_YGYQW {\n  color: #21c313;\n}\n", ""]);

// exports
exports.locals = {
	"high": "CalendarStyles_high_2WLZE",
	"medium": "CalendarStyles_medium_1jpXn",
	"low": "CalendarStyles_low_YGYQW"
};