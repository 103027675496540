import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DurationBox from "@CommonControls/DurationBox/DurationBox";
import moment from "moment";

@State.observer
export default class DurationBoxExampleBox extends React.Component {
    @State.observable.ref public from: moment.Moment = moment();
    @State.observable.ref public to: moment.Moment = moment().add(450, "days").add(20, "minutes");

    @State.action.bound
    public setFrom(from: moment.Moment) {
        this.from = from;
    }

    @State.action.bound
    public setTo(to: moment.Moment) {
        this.to = to;
    }

    public render() {
        return (
            <Ui.PageBox title="Duration box">
                <Ui.Flex>
                    <Ui.Flex.Item xs={4}>
                        <Ui.DateTimePicker
                            automationId=""
                            label="From"
                            value={this.from}
                            onChange={this.setFrom}
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        <Ui.DateTimePicker
                            automationId=""
                            label="To"
                            value={this.to}
                            onChange={this.setTo}
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={4}>
                        <DurationBox
                            automationId=""
                            label="Duration"
                            from={this.from}
                            to={this.to}
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.PageBox>
        );
    }
}
