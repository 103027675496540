import * as React from "react";

interface ISourceCodeProps {
    children: string;
    language: string;
}

export default class SourceCode extends React.Component<ISourceCodeProps, undefined> {

    public render() {
        return (
            <pre style={{ overflowX: "auto", backgroundColor: "#e8e8e8", color: "black", padding: "16px", border: "1px solid #cdcdcd" }}>{this.props.children}</pre>
        );
    }
}