import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import moment from "moment";
import Style from "./SchedulerExampleBox.less";
import Calendar from "@CommonControls/DateTimePicker/Calendar";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import { ISchedulerEvent } from "@CommonControls/Scheduler/ISchedulerProps";
import { combineClasses } from "@Toolkit/ReactClient/Common/CompositeClassName";
import { isNullOrUndefined } from "@Toolkit/CommonWeb/NullCheckHelpers";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";

enum MyEntryState {
    Pending,
    Done,
    Current
}

class MySchedulerEntry implements ISchedulerEvent {

    @State.observable.ref public startTime: moment.Moment;
    @State.observable.ref public endTime: moment.Moment;
    public isReadOnly: boolean;

    constructor(
        public readonly id: number,
        startTime: moment.Moment,
        endTime: moment.Moment,
        public readonly state: MyEntryState
    ) {
        this.startTime = startTime;
        this.endTime = endTime;
        this.isReadOnly = state !== MyEntryState.Current;
    }

    @State.bound
    public getDurationInMinutes() {
        return this.endTime.clone().diff(this.startTime, "minutes");
    }
}


@State.observer
export default class SchedulerExampleBox extends React.Component {

    private testData = State.createObservableShallowArray([
        new MySchedulerEntry(0, moment("2020-06-09T10:30:00+02:00"), moment("2020-06-09T11:00:00+02:00"), MyEntryState.Pending),
        new MySchedulerEntry(1, moment("2020-06-09T11:00:00+02:00"), moment("2020-06-09T11:15:00+02:00"), MyEntryState.Done),
        new MySchedulerEntry(2, moment("2020-06-09T11:15:00+02:00"), moment("2020-06-09T11:30:00+02:00"), MyEntryState.Done),
    ]);

    private testBlockData = State.createObservableShallowArray([
        { id: 100, startTime: moment("2020-06-10T10:00:00+02:00"), endTime: moment("2020-06-10T12:00:00+02:00") } as ISchedulerEvent,
    ]);

    @State.observable.ref private currentDate = LocalDate.today();
    @State.observable.ref private currentMoment = DateTimeService.now();
    @State.observable.ref private timeSlotCount = 4;
    @State.observable.ref private scrollToTarget: moment.Moment = DateTimeService.now();

    @State.observable.ref private newEntry: MySchedulerEntry = null;

    private dayStart = new TimeOfDay(9, 0);
    private dayEnd = new TimeOfDay(17, 30);

    public render() {
        return (
            <SingleLayout>
                <Ui.PageBox2>
                    <Ui.PageBox2.Header title="Scheduler" />
                    <Ui.PageBox2.Body>

                        <Ui.SidebarLayout
                            className={Style.container}
                            leftSidebarClassName={Style.sidebar}
                            leftSidebar={(
                                <>
                                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                        <Ui.Button automationId="" text="Modify entries" onClick={this.modify} />
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <Ui.DateTimePicker automationId="" value={this.scrollToTarget} onChange={this.setScrollToTarget} className={Style.scrollToInput} />
                                            <Ui.Button automationId="" text="Scroll to" onClick={this.setScrollTo} />
                                        </div>
                                        <Ui.Button automationId="" text="Change timeslot" onClick={this.setTimeslot} />
                                        <Ui.Button automationId="" text="New event" onClick={this.addNew} />
                                    </div>
                                    <Calendar
                                        value={this.currentDate}
                                        onChange={this.setCurrentDate}
                                    />
                                </>
                            )}
                        >
                            <Ui.Scheduler
                                events={this.testData}
                                onGetEntryClassName={this.getEntryClassName}
                                onGetBlockClassName={this.getBlockClassName}
                                onEntryChange={this.setEntry}
                                highlightFreeSpace
                                blockingEvents={this.testBlockData}
                                currentMoment={this.currentMoment}
                                timeSlotMinorCount={this.timeSlotCount}
                                workHoursStart={this.dayStart}
                                workHoursEnd={this.dayEnd}
                                onPlaceNewEntry={this.placeNew}
                                onCancelNewEntry={this.cancelNew}
                                isNewEntryPlaceable={this.isNewPlaceable}
                                listView={(
                                    <p>This is the list view. It is highly customizable, you can draw anything here.</p>
                                )}
                            />
                        </Ui.SidebarLayout>
                    </Ui.PageBox2.Body>
                </Ui.PageBox2>
            </SingleLayout>
        );
    }

    @State.action.bound
    private setCurrentDate(value: LocalDate) {
        this.currentDate = value;
        this.currentMoment = value.toUtcDayStartMoment();
    }

    @State.action.bound
    private setScrollToTarget(value: moment.Moment) {
        this.scrollToTarget = value;
    }

    @State.action.bound
    private addNew() {
        this.newEntry = new MySchedulerEntry(
            this.testData.length,
            moment("2020-06-09T12:00:00+02:00"),
            moment("2020-06-09T12:40:00+02:00"),
            MyEntryState.Current
        );
    }

    @State.action.bound
    private placeNew(slot: moment.Moment) {
        this.testData.push(new MySchedulerEntry(
            this.newEntry.id,
            slot,
            slot.clone().add(this.newEntry.endTime.diff(this.newEntry.startTime)),
            MyEntryState.Current
        ));
        this.newEntry = null;
    }

    @State.bound
    private isNewPlaceable(slot: moment.Moment) {
        return this.testData.every(e => {

            if (slot.isAfter(e.startTime) && slot.isBefore(e.endTime)) {
                // new entry start time is within another event
                console.log(`Entry not fit, because Event(${e.id}) is from ${e.startTime.format()} to ${e.endTime.format()} and the new event starts at ${slot.format()}`);
                return false;
            }

            const newEntryEnd = slot.clone().add(this.newEntry.getDurationInMinutes(), "minutes");
            if (newEntryEnd.isAfter(e.startTime) && newEntryEnd.isBefore(e.endTime)) {
                // new event end time is within another event
                console.log(`Entry not fit, because Event(${e.id}) is from ${e.startTime.format()} to ${e.endTime.format()} and the new event ends at ${newEntryEnd.format()}`);
                return false;
            }

            return true;
        });
    }

    @State.action.bound
    private cancelNew() {
        this.newEntry = null;
    }

    @State.action.bound
    private setTimeslot() {
        this.timeSlotCount = this.timeSlotCount === 4 ? 2 : 4;
    }

    @State.action.bound
    private modify() {
        this.testData[0].startTime = moment("2020-06-09T09:30:00+02:00");
    }

    @State.action.bound
    private setScrollTo() {
        this.currentMoment = this.scrollToTarget;
    }

    @State.action.bound
    private setEntry(e: MySchedulerEntry, newStart: moment.Moment, newEnd: moment.Moment) {
        e.startTime = newStart;
        e.endTime = newEnd;
    }

    private getEntryClassName(entry: MySchedulerEntry, isPlaceable: boolean): string {
        if (!isNullOrUndefined(isPlaceable) && isPlaceable === false) {
            return combineClasses(Style.entryCurrent, Style.notFit);
        }
        switch (entry.state) {
            case MyEntryState.Pending:
                return Style.entryPending;
            case MyEntryState.Done:
                return Style.entryDone;
            case MyEntryState.Current:
                return Style.entryCurrent;
        }
    }

    private getBlockClassName(entry: ISchedulerEvent): string {
        return Style.block;
    }
}
