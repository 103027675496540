import * as Ui from "@CommonControls";
import React from "react";
import styles from "./FlexExampleBox.css";

export default class FlexExampleBox extends React.Component {

    public render() {
        return (
            <Ui.PageBox title="Grid container">
                <Ui.Flex>
                    <Ui.Flex.Item className={styles.testDiv} xs={1} content={<p>1</p>} />
                    <Ui.Flex.Item className={styles.testDiv} xs={1} content={<p>2</p>} />
                    <Ui.Flex.Item className={styles.testDiv} xs={1} content={<p>3</p>} />
                    <Ui.Flex.Item className={styles.testDiv} xs={1} content={<p>4</p>} />
                    <Ui.Flex.Item className={styles.testDiv} xs={1} content={<p>5</p>} />
                    <Ui.Flex.Item className={styles.testDiv} xs={1} content={<p>6</p>} />
                    <Ui.Flex.Item className={styles.testDiv} xs={1} content={<p>7</p>} />
                    <Ui.Flex.Item className={styles.testDiv} xs={1} content={<p>8</p>} />
                    <Ui.Flex.Item className={styles.testDiv} xs={1} content={<p>9</p>} />
                    <Ui.Flex.Item className={styles.testDiv} xs={1} content={<p>10</p>} />
                    <Ui.Flex.Item className={styles.testDiv} xs={1} content={<p>11</p>} />
                    <Ui.Flex.Item className={styles.testDiv} xs={1} content={<p>12</p>} />
                </Ui.Flex>

                <h3>Responsive row</h3>
                <Ui.Flex>
                    <Ui.Flex.Item className={styles.testDiv} xs={12} sm={6} md={3} lg={4}><p className={styles.red}>xs={12} sm={6} md={3} lg={4}</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={12} sm={6} md={3} lg={4}><p className={styles.green}>xs={12} sm={6} md={3} lg={4}</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={12} sm={6} md={3} lg={4}><p className={styles.blue}>xs={12} sm={6} md={3} lg={4}</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={12} sm={6} md={3} lg={12}><p className={styles.yellow}>xs={12} sm={6} md={3} lg={12}</p></Ui.Flex.Item>
                </Ui.Flex>

                <h3>Offsets</h3>
                <Ui.Flex>
                    <Ui.Flex.Item className={styles.testDiv} xs={1} xsOffset={1}><p className={styles.red}>xs={1} xsOffset={1}</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={2} xsOffset={2}><p className={styles.green}>xs={2} xsOffset={2}</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={3} xsOffset={1}><p className={styles.blue}>xs={3} xsOffset={1}</p></Ui.Flex.Item>
                </Ui.Flex>

                <h3>Justify</h3>
                <h4>Auto</h4>
                <Ui.Flex>
                    <Ui.Flex.Item className={styles.testDiv} xs><p className={styles.red}>xs</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={2}><p className={styles.green}>xs=2</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs><p className={styles.blue}>xs</p></Ui.Flex.Item>
                </Ui.Flex>

                <h4>Center</h4>
                <Ui.Flex xsJustify={"center"}>
                    <Ui.Flex.Item className={styles.testDiv} xs={2}><p className={styles.red}>xs=2</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={2}><p className={styles.green}>xs=2</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={2}><p className={styles.blue}>xs=2</p></Ui.Flex.Item>
                </Ui.Flex>

                <h4>Between</h4>
                <Ui.Flex xsJustify={"between"}>
                    <Ui.Flex.Item className={styles.testDiv} xs={2}><p className={styles.red}>xs=2</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={2}><p className={styles.green}>xs=2</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={2}><p className={styles.blue}>xs=2</p></Ui.Flex.Item>
                </Ui.Flex>

                <h4>Around</h4>
                <Ui.Flex xsJustify={"around"}>
                    <Ui.Flex.Item className={styles.testDiv} xs={2}><p className={styles.red}>xs=2</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={2}><p className={styles.green}>xs=2</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={2}><p className={styles.blue}>xs=2</p></Ui.Flex.Item>
                </Ui.Flex>

                <h4>Spacing</h4>

                <h5>none (0px) - default</h5>
                <Ui.Flex spacing="none">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h5>tiny (8px)</h5>
                <Ui.Flex spacing="tiny">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h5>small (16px)</h5>
                <Ui.Flex spacing="small">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h5>regular (24px)</h5>
                <Ui.Flex spacing="regular">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h5>medium (40px)</h5>
                <Ui.Flex spacing="medium">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h5>large (64px)</h5>
                <Ui.Flex spacing="large">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h5>extra large (80px)</h5>
                <Ui.Flex spacing="extra large">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h4>Inner spacing</h4>

                <h5>none (0px) - default</h5>
                <Ui.Flex innerSpacing="none">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h5>tiny (8px)</h5>
                <Ui.Flex innerSpacing="tiny">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h5>small (16px)</h5>
                <Ui.Flex innerSpacing="small">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h5>regular (24px)</h5>
                <Ui.Flex innerSpacing="regular">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h5>medium (40px)</h5>
                <Ui.Flex innerSpacing="medium">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h5>large (64px)</h5>
                <Ui.Flex innerSpacing="large">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h5>extra large (80px)</h5>
                <Ui.Flex innerSpacing="extra large">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h4>Outer spacing</h4>

                <h5>none (0px) - default</h5>
                <Ui.Flex outerSpacing="none">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h5>tiny (8px)</h5>
                <Ui.Flex outerSpacing="tiny">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h5>small (16px)</h5>
                <Ui.Flex outerSpacing="small">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h5>regular (24px)</h5>
                <Ui.Flex outerSpacing="regular">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h5>medium (40px)</h5>
                <Ui.Flex outerSpacing="medium">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h5>large (64px)</h5>
                <Ui.Flex outerSpacing="large">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

                <h5>extra large (80px)</h5>
                <Ui.Flex outerSpacing="extra large">
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.red}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.green}>xs=4</p></Ui.Flex.Item>
                    <Ui.Flex.Item className={styles.testDiv} xs={4}><p className={styles.blue}>xs=4</p></Ui.Flex.Item>
                </Ui.Flex>

            </Ui.PageBox>
        );
    }
}