import React from "react";
import * as Ui from "@CommonControls";
import SourceCode from "./SourceCode";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class LedExamplebox extends React.Component {
    public render() {
        return (
            <Ui.PageBox title="Led">
                <Ui.PageBox.Body>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <br /><Ui.Led bordered={false} ledColor="#21C313" />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /><Ui.Led bordered={true} ledColor="white" />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /><Ui.Led ledColor="red" />
                        </Ui.Flex.Item>
                    </Ui.Flex>

                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
                                <Ui.Led bordered={false} ledColor="#21C313" />
                                <Ui.Led bordered={true} ledColor="white" />
                                <Ui.Led ledColor="red" />
                            `}
                        </SourceCode>
                    </Ui.SingleAccordion>
                    
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}