import CheckBoxGroupItemStore from "@CommonControls/CheckBox/CheckBoxGroupItemStore";
import CheckBoxGroupStore from "@CommonControls/CheckBox/CheckBoxGroupStore";
import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class CheckBoxGroupExampleStore {
    @State.observable public nullableStore: CheckBoxGroupStore<string> = 
        new CheckBoxGroupStore<string>(
            new Array<CheckBoxGroupItemStore<string>>(
                new CheckBoxGroupItemStore<string>("First", false),
                new CheckBoxGroupItemStore<string>("Second", false),
                new CheckBoxGroupItemStore<string>("Third", false)
            )
        );
    @State.observable public simpleStore: CheckBoxGroupStore<string> = 
    new CheckBoxGroupStore<string>(
        new Array<CheckBoxGroupItemStore<string>>(
            new CheckBoxGroupItemStore<string>("Fourth", false),
            new CheckBoxGroupItemStore<string>("Fifth", false),
            new CheckBoxGroupItemStore<string>("Sixth", false)
        )
    );
        
    @State.action.bound
    public changeSimpleValue(key: string, newValue: boolean) {
        this.simpleStore.get(key).setSelectionState(newValue);
    }

    @State.action.bound
    public changeNullableValue(key: string, newValue: boolean | null) {
        this.nullableStore.get(key).setSelectionState(newValue);
    }
}
