import React from "react";
import * as Ui from "@CommonControls";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import PageBox2 from "@CommonControls/PageBox2/PageBox2";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import MasterDetailLayout, { MasterDetail } from "@CommonControls/Layout/MasterDetailLayout";
import { StringRadioButtonGroup } from "@CommonControls/RadioButtonGroup";

@State.observer
export default class PageBoxExampleBox extends React.Component {

    private readonly examples = {
        "Simple pagebox with single title": () => this.render_Simple_pagebox_with_single_title(),
        "Simple pagebox with title and icon": () => this.render_Simple_pagebox_with_title_and_icon(),
        "Simple pagebox with title, subtitle and icon": () => this.render_Simple_pagebox_with_title_subtitle_and_icon(),
        "Simple pagebox with title and detail close button": () => this.render_Simple_pagebox_with_title_and_detail_close_button(),
        "Simple pagebox with title, icon and detail close button": () => this.render_Simple_pagebox_with_title_icon_and_detail_close_button(),
        "Simple pagebox with title, icon and toolbar": () => this.render_Simple_pagebox_with_title_icon_and_toolbar(),
        "Simple pagebox with title, icon and footer": () => this.render_Simple_pagebox_with_title_icon_and_footer(),
        "PageBox with tabs": () => this.render_PageBox_with_tabs(),
    };

    @State.observable.ref private currentExample: string = Object.keys(this.examples)[0];
    @State.observable.ref private isMasterDetail = false;

    public render() {

        if (this.isMasterDetail) {
            return (
                <SingleLayout>
                    <MasterDetailLayout selectedItem={1}>
                        <MasterDetail.Master title="Master">
                            <TestMaster />
                        </MasterDetail.Master>
                        <MasterDetail.Detail hasFooter={this.currentExample === "Simple pagebox with title, icon and footer"}>
                            {this.examples[this.currentExample]()}
                        </MasterDetail.Detail>
                    </MasterDetailLayout>
                </SingleLayout>
            );
        }

        return (
            <SingleLayout>
                {this.examples[this.currentExample]()}
            </SingleLayout>
        );
    }

    private renderBody() {
        return (
            <>
                <StringRadioButtonGroup
                    value={this.currentExample}
                    onChange={this.setExample}
                    items={Object.keys(this.examples)}
                    direction="column"
                />
                <Ui.CheckBox automationId="" value={this.isMasterDetail} onChange={this.setMasterDetail} label="This is a MasterDetailLayout.Detail" />
            </>
        );
    }

    @State.action.bound
    private setExample(example: string) {
        this.currentExample = example;
    }

    @State.action.bound
    private setMasterDetail(value: boolean) {
        this.isMasterDetail = value;
    }

    private render_Simple_pagebox_with_single_title() {
        return (
            <PageBox2 fullHeight key="render_Simple_pagebox_with_single_title">
                <PageBox2.Header title="Simple pagebox with single title" />
                <PageBox2.Body>
                    <p>This is my content</p>
                    {this.renderBody()}
                </PageBox2.Body>
            </PageBox2>
        );
    }

    private render_Simple_pagebox_with_title_and_icon() {
        return (
            <PageBox2 fullHeight key="render_Simple_pagebox_with_title_and_icon">
                <PageBox2.Header
                    iconName="cog"
                    title="Simple pagebox with title and icon"
                />
                <PageBox2.Body hasSidePadding>
                    <p>This is my content with some side padding.</p>
                    {this.renderBody()}
                </PageBox2.Body>
            </PageBox2>
        );
    }

    private render_Simple_pagebox_with_title_subtitle_and_icon() {
        return (
            <PageBox2 fullHeight key="render_Simple_pagebox_with_title_subtitle_and_icon">
                <PageBox2.Header
                    iconName="cog"
                    title="Simple pagebox with title, subtitle and icon"
                    subTitle="And subtitle"
                />
                <PageBox2.Body>
                    <p>This is my content</p>
                    {this.renderBody()}
                </PageBox2.Body>
            </PageBox2>
        );
    }

    private render_Simple_pagebox_with_title_and_detail_close_button() {
        return (
            <PageBox2 fullHeight key="render_Simple_pagebox_with_title_and_detail_close_button">
                <PageBox2.Header
                    title="Simple pagebox with title and detail close button"
                    subTitle="And subtitle"
                    hasDetailCloseButton
                />
                <PageBox2.Body>
                    <p>This is my content</p>
                    {this.renderBody()}
                </PageBox2.Body>
            </PageBox2>
        );
    }

    private render_Simple_pagebox_with_title_icon_and_detail_close_button() {
        return (
            <PageBox2 fullHeight key="render_Simple_pagebox_with_title_icon_and_detail_close_button">
                <PageBox2.Header
                    iconName="cog"
                    title="Simple pagebox with title, icon and detail close button"
                    subTitle="And subtitle"
                    hasDetailCloseButton
                />
                <PageBox2.Body>
                    <p>This is my content</p>
                    {this.renderBody()}
                </PageBox2.Body>
            </PageBox2>
        );
    }

    private render_Simple_pagebox_with_title_icon_and_toolbar() {
        return (
            <PageBox2 fullHeight key="render_Simple_pagebox_with_title_icon_and_toolbar">
                <PageBox2.Header
                    iconName="cog"
                    title="Simple pagebox with title, icon and toolbar"
                    subTitle="And subtitle"
                    toolbar={(
                        <>
                            <Ui.Button automationId="" text="Button 1" iconName="calculator" visualStyle="primary" />
                            <Ui.Button automationId="" iconName="cardiology" />
                        </>
                    )}
                />
                <PageBox2.Body>
                    <p>This is my content</p>
                    {this.renderBody()}
                </PageBox2.Body>
            </PageBox2>
        );
    }

    private render_Simple_pagebox_with_title_icon_and_footer() {
        return (
            <PageBox2 fullHeight key="render_Simple_pagebox_with_title_icon_and_footer">
                <PageBox2.Header
                    iconName="cog"
                    title="Simple pagebox with title, icon and footer"
                    subTitle="And subtitle"
                />
                <PageBox2.Body hasSidePadding>
                    <p>This is my content</p>
                    {this.renderBody()}
                </PageBox2.Body>
                <PageBox2.Footer
                    leftToolbar={<Ui.Button automationId=""  text="Button at left side" />}
                    rightToolbar={<Ui.Button automationId=""  text="Button at right side" />}
                />
            </PageBox2>
        );
    }

    private render_PageBox_with_tabs() {
        return (
            <PageBox2
                hasTabs
                initiallyActiveTabName="two"
                fullHeight
                key="render_PageBox_with_tabs"
            >
                <PageBox2.Tab tabName="one" tabTitle="First tab">
                    <PageBox2.Header
                        iconName="cog"
                        title="PageBox with tabs - First tab"
                        subTitle="And subtitle"
                    />
                    <PageBox2.Body>
                        <p>This is my first content</p>
                        {this.renderBody()}
                    </PageBox2.Body>
                </PageBox2.Tab>

                <PageBox2.Tab tabName="two" tabTitle="Second tab">
                    <PageBox2.Header
                        iconName="cog"
                        title="PageBox with tabs - Second tab"
                        subTitle="Yeah"
                    />
                    <PageBox2.Body>
                        <p>This is my second content</p>
                        {this.renderBody()}
                    </PageBox2.Body>
                </PageBox2.Tab>
            </PageBox2>
        );
    }
}


const TestMaster = () => {
    return <b>Test master</b>;
};