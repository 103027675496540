import React from "react";
import * as Ui from "@CommonControls";
import * as DiContainer from "@DiContainer";
import CheckBoxExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/CheckBoxExampleStore";
import SourceCode from "./SourceCode";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class CheckBoxExampleBox extends React.Component {

    @DiContainer.lazyResolve("CheckBoxExampleStore") private store: CheckBoxExampleStore;

    private renderBool(bool: boolean) {
        if (bool === true) {
            return "true";
        } else if (bool === false) {
            return "false";
        } else if (bool === null) { // === null
            return "null";
        } else {
            return "something else: " + (bool as any).toString();
        }
    }

    public render() {
        return (
            <Ui.PageBox title="Check box">
                <Ui.PageBox.Body>
                    <p>A checkbox (check box, tickbox, tick box) is a GUI widget that permits the user to make a binary choice, i.e. a choice between one of two possible mutually exclusive options.</p>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <h3>Checkbox </h3>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2} xsOffset={2}>
                            <b>Three states:</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2} xsOffset={4}>
                            <b>Two states:</b>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Align</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            left:
                            <Ui.CheckBox align="left"
                                canBeNull
                                value={this.store.nullableValue}
                                onChange={this.store.changeNullableValue}
                                label="Item1"
                                labelPosition="left"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.nullableValue)}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            center:
                            <Ui.CheckBox align="center"
                                canBeNull
                                value={this.store.nullableValue}
                                onChange={this.store.changeNullableValue}
                                label="Item2"
                                disabled={true}
                                labelPosition="left"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.nullableValue)}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            right:
                            <Ui.CheckBox align="right"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item3"
                                labelPosition="left"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.simpleValue)}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex><br />
                    <Ui.Flex>
                        <Ui.Flex.Item xs={3} xsOffset={2}>
                            Label align left:
                            <Ui.CheckBox align="left"
                                canBeNull
                                value={this.store.nullableValue}
                                onChange={this.store.changeNullableValue}
                                label="Item4"
                                labelPosition="left"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.nullableValue)}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            Label align center:
                            <Ui.CheckBox align="center"
                                canBeNull
                                value={this.store.nullableValue}
                                onChange={this.store.changeNullableValue}
                                label="Item5"
                                labelPosition="left"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.nullableValue)}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            Label align right:
                            <Ui.CheckBox align="right"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item6"
                                labelPosition="left"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.simpleValue)}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Container style</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            no padding
                            <Ui.CheckBox align="left"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item7"
                                displayMode="check"
                                verticalAlign="noPadding"
                                labelPosition="left"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.simpleValue)}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            normal
                            <Ui.CheckBox align="left"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item8"
                                displayMode="check"
                                labelPosition="left"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.simpleValue)}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            dark mode
                            <Ui.CheckBox align="left"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item8"
                                displayMode="check"
                                visualMode="dark"
                                labelPosition="left"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.simpleValue)}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            vertical-align inline-input
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.TextBox
                                iconName="filter"
                                label="Normal with icon"
                                placeholder="Hint"
                                propertyIdentifier="testValue"
                                automationId="" />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.CheckBox align="left"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item9"
                                displayMode="check"
                                verticalAlign="inlineInput"
                                labelPosition="right"
                                automationId="" />
                        </Ui.Flex.Item>
                    </Ui.Flex><br />
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            Label position
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            Left:
                            <Ui.CheckBox align="left"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item11"
                                displayMode="check"
                                verticalAlign="normal"
                                labelPosition="left"
                                automationId="" />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            Right:
                            <Ui.CheckBox align="left"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item12"
                                displayMode="check"
                                verticalAlign="normal"
                                labelPosition="right"
                                automationId="" />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <h3>Switch </h3>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Align</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            Left:
                            <Ui.CheckBox align="left"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item1"
                                displayMode="switch"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.simpleValue)}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            Center:
                            <Ui.CheckBox align="center"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item1"
                                displayMode="switch"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.simpleValue)}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            Right:
                            <Ui.CheckBox align="right"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item1"
                                displayMode="switch"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.simpleValue)}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Container style</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            no padding
                            <Ui.CheckBox align="left"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item1"
                                displayMode="switch"
                                verticalAlign="noPadding"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.simpleValue)}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            normal
                            <Ui.CheckBox align="left"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item1"
                                displayMode="switch"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.simpleValue)}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                           vertical-align inline-input
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.TextBox
                                iconName="filter"
                                label="Normal with icon"
                                placeholder="Hint"
                                propertyIdentifier="testValue"
                                automationId="" />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.CheckBox align="left"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item1"
                                displayMode="switch"
                                verticalAlign="inlineInput"
                                automationId="" />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <br/>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            Label position
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            Left:
                            <Ui.CheckBox align="left"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item1"
                                displayMode="switch"
                                verticalAlign="normal"
                                labelPosition="left"
                                automationId="" />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            Right:
                            <Ui.CheckBox align="left"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item1"
                                displayMode="switch"
                                verticalAlign="normal"
                                labelPosition="right"
                                automationId="" />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <h3>Toggle Button </h3>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Align</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            Left:
                            <Ui.CheckBox align="left"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item1"
                                displayMode="toggle-button"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.simpleValue)}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            Center:
                            <Ui.CheckBox align="center"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item1"
                                displayMode="toggle-button"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.simpleValue)}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            Right:
                            <Ui.CheckBox align="right"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item1"
                                displayMode="toggle-button"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.simpleValue)}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Container style</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            no padding
                            <Ui.CheckBox align="left"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item1"
                                displayMode="toggle-button"
                                verticalAlign="noPadding"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.simpleValue)}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            normal
                            <Ui.CheckBox align="left"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item1"
                                displayMode="toggle-button"
                                automationId="" />
                            <pre>Value: {this.renderBool(this.store.simpleValue)}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                           vertical-align inline-input
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.TextBox
                                iconName="filter"
                                label="Normal with icon"
                                placeholder="Hint"
                                propertyIdentifier="testValue"
                                automationId="" />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.CheckBox align="left"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                label="Item1"
                                displayMode="toggle-button"
                                verticalAlign="inlineInput"
                                automationId="" />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
<Ui.CheckBox value={this.store.simpleValue} onChange={this.store.changeSimpleValue} />
`}
                        </SourceCode>
                        <SourceCode language="typescript">
                            {`
@State.observable public simpleValue: boolean = false;

@State.action.bound
public changeSimpleValue(newValue: boolean) {
    this.simpleValue = newValue;
}
`}
                        </SourceCode>

                    </Ui.SingleAccordion>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}