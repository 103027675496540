/* tslint:disable */
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import FrontendActionId from "@Toolkit/ReactClient/ActionProcessing/FrontendActionId";
import IActionProcessor from "@Toolkit/ReactClient/ActionProcessing/IActionProcessor";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";

export class NotPermittedSampleAction extends FrontendActionBase {
    public static id = new FrontendActionId("NotPermittedSampleAction");

    constructor(
        public readonly message: string
    ) {
        super(NotPermittedSampleAction.id);
    }
}

export class NotPermittedSampleActionProcessor implements IActionProcessor<NotPermittedSampleAction> {

    constructor(
        private readonly notificationService: INotificationService
    ) { }

    public processAsync(_action: NotPermittedSampleAction): Promise<void> {
        this.notificationService.info("Not permitted");
        return Promise.resolve();
    }

}