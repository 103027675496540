import React from "react";
import connect from "@Toolkit/ReactClient/Components/Connect/ConnectHoc";
import DependencyAdapter from "@Toolkit/ReactClient/Components/DependencyInjection/DependencyAdapter";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import { dispatchAsyncErrors } from "@Toolkit/CommonWeb/AsyncHelpers";
import IAuthenticationService from "@HisPlatform/Services/Definition/Authentication/IAuthenticationService";
import UserContext from "@HisPlatform/Model/DomainModel/UserContext/UserContext";
import UserManagementDataProviderStore from "@HisPlatform/BoundedContexts/UserManagement/ApplicationLogic/Model/UserManagementDataProviderStore";
import * as Ui from "@CommonControls";
import { TestChatGroupClient } from "./SignalRExampleBox/TestChatGroupClient";
import { ITestChatMessage } from "./SignalRExampleBox/ITestChatMessage";

interface ISignalRExampleBoxDependencies {
    authenticationService: IAuthenticationService;
    userContext: UserContext;
    userManagementReferenceDataStore: UserManagementDataProviderStore;
}

interface ISignalRExampleBoxProps extends IRoutingFrameContentProps {
    _dependencies?: ISignalRExampleBoxDependencies;
}

@State.observer
class SignalRExampleBox extends React.Component<ISignalRExampleBoxProps> {

    @State.observable.ref private messages: ITestChatMessage[] = [];

    @State.observable private messageText = "";

    private testChatGroupClient = new TestChatGroupClient(this.onMessageReceived);

    @State.action.bound
    private setMessage(newMessage: string) {
        this.messageText = newMessage;
    }

    @State.bound
    private send() {
        this.testChatGroupClient.sendMessage({
            userId: this.props._dependencies.userContext.id,
            text: this.messageText
        });
        this.setMessage("");
    }

    @State.bound
    private async loadAsync() {
        await this.props._dependencies.userManagementReferenceDataStore.users.ensureAllLoadedAsync();
    }

    // !!! This is a custom solution, you should mostly use an encapsulated NotificationHandler component !!!
    public componentDidMount() {
        dispatchAsyncErrors(this.loadAsync(), this);
        this.testChatGroupClient.buildConnection(this.props._dependencies.authenticationService);
        dispatchAsyncErrors(this.testChatGroupClient.startAndJoinGroupAsync(), this);
    }

    public componentWillUnmount() {
        this.testChatGroupClient.disconnect();
    }

    @State.bound
    private renderMessage(message: ITestChatMessage, key: number) {
        const user = message.userId && this.props._dependencies.userManagementReferenceDataStore.users.get(message.userId);
        return (
            <div key={key}><b>{user.displayName}:</b> {message.text}<br/></div>
        );
    }

    public render() {
        return (
            <Ui.PageBoxContainer>
                <Ui.PageBox title="Test SignalR chat">
                    <Ui.PageBox.Body>
                        <h3>Messages: </h3>
                        <Ui.ScrollView height={500}>
                            {this.messages.map(this.renderMessage)}
                        </Ui.ScrollView>
                        <Ui.TextBox automationId="" placeholder="Message..." value={this.messageText} onChange={this.setMessage} onEnterKeyDown={this.send} />
                    </Ui.PageBox.Body>
                </Ui.PageBox>
            </Ui.PageBoxContainer>
        );
    }

    @State.action.bound
    private onMessageReceived(message: ITestChatMessage) {
        this.messages = this.messages.concat(message);
    }
}

export default connect(
    SignalRExampleBox,
    new DependencyAdapter<ISignalRExampleBoxProps, ISignalRExampleBoxDependencies>(c => ({
        authenticationService: c.resolve("IAuthenticationService"),
        userContext: c.resolve("UserContext"),
        userManagementReferenceDataStore: c.resolve("UserManagementDataProviderStore"),
    }))
);