import Di from "@Di";
import Container from "@DiContainer";
import IContainerService from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import PanelStoreBase from "@Toolkit/CommonWeb/PanelStore/PanelStoreBase";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import AuthorizationService from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/AuthorizationService";
import { PermittedFrontendAction } from "@HisPlatform/BoundedContexts/WebAppBackend/ApplicationLogic/Services/Authorization/PermittedFrontendAction";
import { ToastSampleAction, ToastSampleActionProcessor } from "./ToastSampleAction";
import IScreenRegistry from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import GlobalActionRegistry from "@Toolkit/ReactClient/ActionProcessing/GlobalActionRegistry";
import { NotPermittedSampleAction } from "./NotPermittedSampleAction";
import ShowSampleMdScreenAction from "./Screens/ShowSampleMdScreenAction";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ShowSampleScreenAction from "./Screens/ShowSampleScreenAction";
import SampleScreen from "./Screens/SampleScreen";
import SampleMdScreen from "./Screens/SampleMdScreen";

// Client side action
export function toastSampleActionProcessorFactory(c: IContainerService) { return new ToastSampleActionProcessor(c.resolve<INotificationService>("INotificationService")); }

@Di.injectable()
export default class FrontendActionsExampleStore extends PanelStoreBase {

    private readonly originalAuthorizationService: AuthorizationService = null;
    private readonly authorizationService: AuthorizationService = null;

    constructor(
        @Di.inject("IScreenRegistry") screenRegistry: IScreenRegistry,
        @Di.inject("GlobalActionRegistry") actionRegistry: GlobalActionRegistry,
        @Di.inject("AuthorizationService") authorizationService: AuthorizationService
    ) {
        super();

        actionRegistry.register(
            ToastSampleAction.id,
            toastSampleActionProcessorFactory
        );

        actionRegistry.register(
            NotPermittedSampleAction.id,
            toastSampleActionProcessorFactory
        );

        screenRegistry.register<ShowSampleScreenAction>(
            ShowSampleScreenAction.id,
            {
                component: SampleScreen,
                getScreenContextData: _ => null,
                onSavedExisting: (e) => {
                    console.log("ShowSampleScreenAction saving");
                    e.continue?.();
                    console.log("ShowSampleScreenAction saved");
                    e.close();
                    console.log("ShowSampleScreenAction closed");
                }
            }
        );

        screenRegistry.register<ShowSampleMdScreenAction>(
            ShowSampleMdScreenAction.id,
            {
                component: SampleMdScreen,
                getScreenContextData: _ => null,
                onSavedExisting: (e) => {
                    console.log("ShowSampleMdScreenAction saving");
                    e.continue?.();
                    console.log("ShowSampleMdScreenAction saved");
                    e.close();
                    console.log("ShowSampleMdScreenAction closed");
                }
            }
        );

        if (this.authorizationService === null) {
            this.originalAuthorizationService = authorizationService;
            this.authorizationService = new AuthorizationService({ getFrontendActionPermissionsAsync: this.getPermittedActionsAsync });
            Container.rebind("AuthorizationService").toConstantValue(this.authorizationService);
        }
    }

    public readonly loadAsync = this.async(async () => {
        await this.authorizationService.loadPermissionsAsync();
    });

    public unload(): void {
        super.unload();
        Container.rebind("AuthorizationService").toConstantValue(this.originalAuthorizationService);
    }

    private getPermittedActionsAsync(): Promise<{ isUnresctrictedUser: boolean, permittedActions: PermittedFrontendAction[] }> {
        return Promise.resolve({
            isUnresctrictedUser: false,
            permittedActions: [
                new PermittedFrontendAction(ToastSampleAction.id, []),
                new PermittedFrontendAction(ShowSampleScreenAction.id, []),
                new PermittedFrontendAction(ShowSampleMdScreenAction.id, []),
            ]
        });
    }
}