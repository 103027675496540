import React from "react";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import * as DiContainer from "@DiContainer";
import SelectorExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/SelectorExampleStore";
import SourceCode from "./SourceCode";
import SettlementTextbox from "@HisPlatform/BoundedContexts/CommonReferenceData/Components/Controls/SettlementTextbox";
import SettlementTextboxExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/SettlementTextboxExampleStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class SettlementTextboxExampleBox extends React.Component {

    @DiContainer.lazyResolve("SettlementTextboxExampleStore") private store: SettlementTextboxExampleStore;

    public render() {
        return (
            <Ui.PageBox title="Selector">
                <Ui.Flex>
                    <Ui.Flex.Item xs={4}>
                        <SettlementTextbox
                            onChange={this.store.setSettlementName}
                            value={this.store.settlementName}
                            automationId=""
                        />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={8}>
                        {this.store.settlementName}
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.PageBox>
        );
    }
}