import * as Ui from "@CommonControls";
import React from "react";
import SourceCode from "./SourceCode";
import SideMenuItem from "@CommonControls/SideMenu/SideMenuItem";
import State from "@Toolkit/ReactClient/Common/StateManaging";



@State.observer
export default class SideMenuTextboxExampleBox extends React.Component {

    public render() {
        return (
            <Ui.PageBox title="SideMenu" style={{ minHeight: 400 }}>
                <Ui.PageBox.Body>
                    <h3>SideMenu</h3>
                    <Ui.SideMenu style={{ height: "500px" }} collapsable>
                        <SideMenuItem text="Alapadatok" iconName="cog" disabled onClick={() => alert("1")} />
                        <SideMenuItem text="Címek" iconName="cog" isActive onClick={() => alert("2")} />
                        <SideMenuItem text="Igazolványok" iconName="cog" onClick={() => alert("3")} />
                        <SideMenuItem text="Biztosítások" iconName="cog" onClick={() => alert("3")} />
                        <SideMenuItem text="Hozzátartozók" iconName="cog" onClick={() => alert("3")} />
                        <SideMenuItem text="Krónikus betegségek" iconName="cog" onClick={() => alert("3")} />
                        <SideMenuItem text="Allergiák, intoleranciák" iconName="cog" onClick={() => alert("3")} />
                        <SideMenuItem text="Foglalkozási adatok" iconName="cog" onClick={() => alert("3")} />
                        <SideMenuItem text="Oltási adatok" iconName="cog" onClick={() => alert("3")} />
                        <SideMenuItem text="Családi anamnézis" iconName="cog" onClick={() => alert("3")} />
                        <SideMenuItem text="Rendszeres gyógyszerek" iconName="cog" onClick={() => alert("3")} />
                        <SideMenuItem text="Rizikó faktorok" iconName="cog" onClick={() => alert("3")} />
                        <SideMenuItem text="Nyilatkozatok" iconName="cog" onClick={() => alert("3")} />
                        <SideMenuItem text="Vitális adatok" iconName="cog" onClick={() => alert("3")} />
                        <SideMenuItem text="Csatolmányok" iconName="cog" onClick={() => alert("3")} />
                    </Ui.SideMenu>
                    <br/>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="typescript">
                            {`
                        <Ui.SideMenu style={{ height: "500px" }}>
                            <SideMenuItem text="Alapadatok" iconName="cog" onClick={() => alert("1")} />
                            <SideMenuItem text="Címek" iconName="cog" isActive onClick={() => alert("2")} />
                            <SideMenuItem text="Igazolványok" iconName="cog" onClick={() => alert("3")} />
                            <SideMenuItem text="Biztosítások" iconName="cog" onClick={() => alert("3")} />
                            <SideMenuItem text="Hozzátartozók" iconName="cog" onClick={() => alert("3")} />
                            <SideMenuItem text="Krónikus betegségek" iconName="cog" onClick={() => alert("3")} />
                            <SideMenuItem text="Allergiák, intoleranciák" iconName="cog" onClick={() => alert("3")} />
                            <SideMenuItem text="Foglalkozási adatok" iconName="cog" onClick={() => alert("3")} />
                            <SideMenuItem text="Oltási adatok" iconName="cog" onClick={() => alert("3")} />
                            <SideMenuItem text="Családi anamnézis" iconName="cog" onClick={() => alert("3")} />
                            <SideMenuItem text="Rendszeres gyógyszerek" iconName="cog" onClick={() => alert("3")} />
                            <SideMenuItem text="Rizikó faktorok" iconName="cog" onClick={() => alert("3")} />
                            <SideMenuItem text="Nyilatkozatok" iconName="cog" onClick={() => alert("3")} />
                            <SideMenuItem text="Vitális adatok" iconName="cog" onClick={() => alert("3")} />
                            <SideMenuItem text="Csatolmányok" iconName="cog" onClick={() => alert("3")} />
                        </Ui.SideMenu>
                            `}
                        </SourceCode>
                    </Ui.SingleAccordion>
                </Ui.PageBox.Body>
            </Ui.PageBox >
        );
    }
}