import React from "react";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import * as DiContainer from "@DiContainer";
import SourceCode from "./SourceCode";
import TooltipExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/TooltipExampleStore";
import ConditionCodeSelector from "@HisPlatform/BoundedContexts/Care/Components/Controls/ReferenceData/ConditionCodeSelector";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class TooltipExampleBox extends React.Component {
    @DiContainer.lazyResolve("TooltipExampleStore") private store: TooltipExampleStore;

    private tooltipTextAlignLeft = `Tooltip text align left!
    Tooltip text align left!
    Tooltip text align left!Tooltip text align left!
    Tooltip text align left!
    `;
    private tooltipTextAlignCenter = `Tooltip text align center!
    Tooltip text align center!
    Tooltip text align center!Tooltip text align center!Tooltip text align center!
    Tooltip text align center!
    `;
    private tooltipTextAlignRight = `Tooltip text align right!
    Tooltip text align right!
    Tooltip text align right!Tooltip text align right!
    Tooltip text align right!Tooltip text align right!Tooltip text align right!
    Tooltip text align right!
    `;

    public render() {
        return (
            <Ui.PageBox title="Tooltip">
                <h3>Tooltip positions</h3>
                <Ui.Flex>
                    <Ui.Flex.Item xs={3}>
                        <Ui.Button text="click me" tooltipContent="Tooltip on top!" tooltipPosition="top" automationId="" />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3}>
                        <Ui.Button text="click me" tooltipContent="Tooltip on left!" tooltipPosition="left" automationId="" />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3}>
                        <Ui.Button text="click me" tooltipContent="Tooltip on right!" tooltipPosition="right" automationId="" />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3}>
                        <Ui.Button text="click me" tooltipContent="Tooltip on bottom!" tooltipPosition="bottom" automationId="" />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <h3>Tooltip textalign</h3>
                <Ui.Flex>
                    <Ui.Flex.Item xs={3}>
                        <Ui.Button text="click me" tooltipContent={this.tooltipTextAlignLeft} tooltipPosition="bottom" tooltipTextAlign="left" automationId=""/>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3}>
                        <Ui.Button text="click me" tooltipContent={this.tooltipTextAlignCenter} tooltipPosition="bottom" tooltipTextAlign="center" automationId=""/>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3}>
                        <Ui.Button text="click me" tooltipContent={this.tooltipTextAlignRight} tooltipPosition="bottom" tooltipTextAlign="right" automationId="" />
                    </Ui.Flex.Item>                    
                </Ui.Flex>
                <h3>Tooltip on components</h3>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <Ui.CheckBox
                            value={this.store.checkBoxValue}
                            onChange={this.store.changeCheckBoxValue}
                            label="Checkbox with tooltip"
                            tooltipContent="Tooltip on checkbox!"
                            tooltipPosition="bottom"
                            automationId="" />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={6}>
                        <Ui.CheckBox
                            isReadOnly={true}
                            value={this.store.checkBoxValue}
                            label="Read-only checkbox with tooltip"
                            tooltipContent="Tooltip on read-only checkbox!"
                            tooltipPosition="bottom"
                            automationId="" />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <Ui.CheckBox
                            displayMode="toggle-button"
                            value={this.store.checkBoxValue}
                            onChange={this.store.changeCheckBoxValue}
                            label="Toggle button with tooltip"
                            tooltipContent="Toggle button on checkbox!"
                            tooltipPosition="bottom"
                            automationId="" />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={6}>
                        <Ui.CheckBox
                            isReadOnly={true}
                            displayMode="toggle-button"
                            value={this.store.checkBoxValue}
                            label="Read-only toggle button with tooltip"
                            tooltipContent="Tooltip on read-only toggle button!"
                            tooltipPosition="bottom"
                            automationId="" />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <Ui.CheckBox
                            displayMode="switch"
                            value={this.store.checkBoxValue}
                            onChange={this.store.changeCheckBoxValue}
                            label="Switch with tooltip"
                            tooltipContent="Switch on checkbox!"
                            tooltipPosition="bottom"
                            automationId="" />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={6}>
                        <Ui.CheckBox
                            isReadOnly={true}
                            displayMode="switch"
                            value={this.store.checkBoxValue}
                            label="Read-only switch with tooltip"
                            tooltipContent="Tooltip on read-only switch!"
                            tooltipPosition="bottom"
                            automationId="" />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <Ui.DateTimePicker
                            value={this.store.dateTimePickerValue}
                            onChange={this.store.setDateTimePickerValue}
                            tooltipContent="Tooltip on DateTimePicker!"
                            tooltipPosition="right"
                            automationId="" />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={6}>
                        <Ui.DateTimePicker
                            isReadOnly={true}
                            value={this.store.dateTimePickerValue}
                            tooltipContent="Tooltip on read-only DateTimePicker!"
                            tooltipPosition="right"
                            automationId="" />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <Ui.Badge
                            visualStyle="secondary"
                            text="Tooltip on label"
                            tooltipContent="Tooltip on Label!"
                            tooltipPosition="top" />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <Ui.Message
                            type="primary"
                            tooltipContent="Tooltip on message!"
                            tooltipPosition="right">Tooltip on message</Ui.Message>
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <Ui.TextBox
                            value={this.store.textBoxValue}
                            onChange={this.store.setTextBoxValue}
                            tooltipContent="Tooltip on textbox!"
                            tooltipPosition="right"
                            automationId="" />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={6}>
                        <Ui.TextBox
                            isReadOnly={true}
                            value={this.store.textBoxValue}
                            tooltipContent="Tooltip on read-only textbox!"
                            tooltipPosition="right"
                            automationId="" />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <Ui.TextBox
                            mask="aaaaaa"
                            value={this.store.maskedTextBoxValue}
                            onChange={this.store.setMaskedTextBoxValue}
                            tooltipContent="Tooltip on masked textbox!"
                            tooltipPosition="right"
                            automationId="" />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={6}>
                        <Ui.TextBox
                            isReadOnly={true}
                            mask="aaaaaa"
                            value={this.store.maskedTextBoxValue}
                            tooltipContent="Tooltip on read-only masked textbox!"
                            tooltipPosition="right"
                            automationId="" />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <Ui.NameTextBox
                            value={this.store.nameTextBoxValue}
                            onChange={this.store.setNameTextBoxValue}
                            tooltipContent="Tooltip on name textbox!"
                            tooltipPosition="right"
                            automationId="" />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={6}>
                        <Ui.NameTextBox
                            isReadOnly={true}
                            value={this.store.nameTextBoxValue}
                            tooltipContent="Tooltip on read-only name textbox!"
                            tooltipPosition="right"
                            automationId="" />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <Ui.NumberBox
                            value={this.store.numberBoxValue}
                            onChange={this.store.setNumberBoxValue}
                            tooltipContent="Tooltip on numberbox!"
                            tooltipPosition="right"
                            onlyNumbersAllowedMessage="Only numbers are allowed!"/>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={6}>
                        <Ui.NumberBox
                            isReadOnly={true}
                            value={this.store.numberBoxValue}
                            tooltipContent="Tooltip on read-only numberbox!"
                            tooltipPosition="right"
                            onlyNumbersAllowedMessage="Only numbers are allowed!"/>
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <Ui.SelectBox
                            items={this.store.selectBoxOptions}
                            value={this.store.selectBoxValue}
                            onChange={this.store.setSelectBoxValue}
                            tooltipContent="Tooltip on selectbox!"
                            tooltipPosition="right"
                            automationId="" />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={6}>
                        <Ui.SelectBox
                            isReadOnly={true}
                            items={this.store.selectBoxOptions}
                            value={this.store.selectBoxValue}
                            tooltipContent="Tooltip on read-only selectbox!"
                            tooltipPosition="right"
                            automationId="" />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <ConditionCodeSelector
                            value={this.store.conditionCodeSelectorValue}
                            onChange={this.store.setConditionCodeSelectorValue}
                            tooltipContent="Tooltip on diagnosis code selector!"
                            tooltipPosition="right"
                            automationId="" />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={6}>
                        <ConditionCodeSelector
                            isReadOnly={true}
                            value={this.store.conditionCodeSelectorValue}
                            tooltipContent="Tooltip on read-only diagnosis code selector!"
                            tooltipPosition="right"
                            automationId="" />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <Ui.AlertCard
                            label="Allergia"
                            innerLabel={{ lowerText: "életveszélyes", upperText: "Penicilin" }}
                            visualStyle="error"
                            tooltipContent="Tooltip on alert card!"
                            tooltipPosition="right" />
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={12}>
                        <h3>How to use</h3>
                        <Ui.SingleAccordion title="Source">
                            <SourceCode language="typescript">
                                {`<Ui.Flex.Item xs={3}>
    <Ui.Button text="click me" tooltipContent="Tooltip on top!" tooltipPosition="top" />
</Ui.Flex.Item>
<Ui.Flex.Item xs={3}>
    <Ui.Button text="click me" tooltipContent="Tooltip on left!" tooltipPosition="left" />
</Ui.Flex.Item>
<Ui.Flex.Item xs={3}>
    <Ui.Button text="click me" tooltipContent="Tooltip on right!" tooltipPosition="right" />
</Ui.Flex.Item>
<Ui.Flex.Item xs={3}>
    <Ui.Button text="click me" tooltipContent="Tooltip on bottom!" tooltipPosition="bottom" />
</Ui.Flex.Item>
`}
                            </SourceCode>
                        </Ui.SingleAccordion>
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex>
                    <Ui.Flex.Item xs={12}>
                        <h3>How to implement on new component</h3>
                        <ol>
                            <li>Component's props must be inherited from ICommonControlProps<br /><Ui.SingleAccordion title="Source"><SourceCode language="typescript">{`interface IMessageProps extends ICommonControlProps {
    type: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark";
}`}</SourceCode></Ui.SingleAccordion></li>
                            <li>Wrap your component in a Tooltip component<br /><Ui.SingleAccordion title="Source"><SourceCode language="typescript">{`<Tooltip content="Hello World!" contentAlignment="center" placement="top">
    <YourComponent />
</Tooltip>`
}</SourceCode></Ui.SingleAccordion></li>
                        </ol>
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.PageBox>
        );
    }
}