import React from "react";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import * as Ui from "@CommonControls";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import IClientValidationProblem from "@Toolkit/ReactClient/Components/ValidationContext/IClientValidationProblem";

interface IRow {
    index: number;
    name: string;
    value: string;
}

@State.observer
export default class DataGridWithValidationResults extends React.Component {

    private data: IRow[] = [
        { index: 1, name: "Name X", value: "Value X" },
        { index: 2, name: "Name 2", value: "Value 2" },
        { index: 3, name: "Name 3", value: "Value 3" },
        { index: 4, name: "Name 4", value: "Value 4" },
        { index: 5, name: "Name 5", value: "Value 4" },
        { index: 6, name: "Name 6", value: "Value 6" },
        { index: 7, name: "Name 7", value: "Value 7" },
    ];

    @State.observable.ref private showProblems = true;
    @State.observable.ref private isValuesShouldBeUniqueProblemActive = true;
    @State.observable.ref private isInvalidValueProblemActive = true;
    @State.observable.ref private isMaybeInvalidNameProblemActive = true;

    @State.computed private get validationResults(): IClientValidationResult[] {
        return [
            {
                entityName: "TestEntity",
                entityId: "1",
                checkedRules: [],
                problems: [
                    {
                        message: "Error message",
                        severity: "error",
                        propertyPath: "Data",
                        ruleId: "Values should be unique",
                        parameters: { referredIndices: [4, 5] }
                    },
                    {
                        message: "Error message",
                        severity: "error",
                        propertyPath: "Data",
                        ruleId: "Invalid value",
                        parameters: { referredIndices: [1] }
                    },
                    {
                        message: "Warning message",
                        severity: "warning",
                        propertyPath: "Data",
                        ruleId: "Maybe invalid name",
                        parameters: { referredIndices: [1] }
                    },
                ]
            }
        ];
    }

    public render() {
        return (
            <Ui.PageBox title="Data grid with validation results" fullHeight={false}>
                <Ui.PageBox.Body>
                    <ValidationBoundary
                        validationResults={this.validationResults}
                        entityTypeName="TestEntity"
                        problemFilterPredicate={this.filterProblems}
                    >
                        <Ui.DataGrid
                            dataSource={this.data}
                            hasHeader
                            rowId="index"
                            actionsColumn={false}
                            rowHeight={35}
                            onMapValidationProblem={this.showProblems ? this.mapValidationProblem : undefined}
                            propertyIdentifier="Data"
                            automationId="dataGridWithValidationResult_dataGrid"
                        >
                            <DataGridColumn dataGetter="index" header="Index" />
                            <DataGridColumn dataGetter="name" header="Name" />
                            <DataGridColumn dataGetter="value" header="Value" />
                        </Ui.DataGrid>
                    </ValidationBoundary>

                    <Ui.GroupBox title="Example settings" isCollapsible={false} hasBorder>
                        <Ui.CheckBox
                            displayMode="switch"
                            label="Show problems"
                            onChange={this.setShowProblems}
                            value={this.showProblems}
                            automationId="showProblemsCheckBox" />
                        <Ui.CheckBox
                            displayMode="switch"
                            label="Problem: Values should be unique (severity: error, affected indices: 4, 5)"
                            value={this.isValuesShouldBeUniqueProblemActive}
                            onChange={this.setValuesShouldBeUniqueProblemActiveState}
                            automationId="isValuesShouldBeUniqueProblemActiveCheckBox"
                        />
                        <Ui.CheckBox
                            displayMode="switch"
                            label="Problem: Invalid value (severity: error, affected indices: 1)"
                            value={this.isInvalidValueProblemActive}
                            onChange={this.setInvalidValueProblemActiveState}
                            automationId="isInvalidValueProblemActiveCheckBox"
                        />
                        <Ui.CheckBox
                            displayMode="switch"
                            label="Problem: Maybe invalid name (severity: warning, affected indices: 1)"
                            value={this.isMaybeInvalidNameProblemActive}
                            onChange={this.setMaybeInvalidNameProblemActiveState}
                            automationId="isMaybeInvalidNameProblemActiveCheckBox"
                        />
                    </Ui.GroupBox>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }

    @State.bound
    private filterProblems(problem: IClientValidationProblem) {
        switch (problem.ruleId) {
            case "Values should be unique":
                return this.isValuesShouldBeUniqueProblemActive;
            case "Invalid value":
                return this.isInvalidValueProblemActive;
            case "Maybe invalid name":
                return this.isMaybeInvalidNameProblemActive;
            default:
                return true;
        }
    }

    @State.bound
    private mapValidationProblem(problem: IClientValidationProblem) {
        return problem.parameters.referredIndices.map((idx: number) => this.data.find(d => d.index === idx));
    }

    @State.action.bound
    private setShowProblems(newValue: boolean) {
        this.showProblems = newValue;
    }

    @State.action.bound
    private setValuesShouldBeUniqueProblemActiveState(newValue: boolean) {
        this.isValuesShouldBeUniqueProblemActive = newValue;
    }

    @State.action.bound
    private setInvalidValueProblemActiveState(newValue: boolean) {
        this.isInvalidValueProblemActive = newValue;
    }

    @State.action.bound
    private setMaybeInvalidNameProblemActiveState(newValue: boolean) {
        this.isMaybeInvalidNameProblemActive = newValue;
    }
}