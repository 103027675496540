import React from "react";
import * as Ui from "@CommonControls";
import * as DiContainer from "@DiContainer";
import TextBoxExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/TextBoxExampleStore";
import SourceCode from "./SourceCode";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import ClientSideValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ClientSideValidationBoundary";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class TextBoxExampleBox extends React.Component {

    @DiContainer.lazyResolve("TextBoxExampleStore") private store: TextBoxExampleStore;

    public render() {
        return (
            <Ui.PageBox title="Text box">

                <Ui.PageBox.Body>
                    <h3>Inputs</h3>
                    <ValidationBoundary
                        validationResults={this.store.validationResults}
                        entityTypeName="test,test2"
                    >
                        <Ui.Flex>
                            <Ui.Flex.Item xs={2}>
                                <Ui.CheckBox label="Required" value={this.store.isRequired} onChange={this.store.setRequired} automationId="" /><br />
                                <Ui.CheckBox label="Compact" value={this.store.isCompact} onChange={this.store.setCompact} automationId="" />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={10}>
                                <Ui.Flex>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            label="Normal"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="Hint"
                                            required={this.store.isRequired}
                                            size={this.store.size}
                                            propertyIdentifier="testValue"
                                            automationId="test1"
                                            showMultipleValidationProblems
                                        />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            label="Disabled"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="Hint"
                                            disabled
                                            required={this.store.isRequired}
                                            size={this.store.size}
                                            propertyIdentifier="testValue"
                                            automationId="" />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            label="Error"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="Error"
                                            required={this.store.isRequired}
                                            size={this.store.size}
                                            propertyIdentifier="testValue"
                                            automationId="" />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            label="Warning"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="Warning"
                                            required={this.store.isRequired}
                                            size={this.store.size}
                                            propertyIdentifier="testValue"
                                            automationId="" />
                                    </Ui.Flex.Item>
                                </Ui.Flex>

                                <Ui.Flex>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            iconName="filter"
                                            label="Normal with icon"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="Hint"
                                            required={this.store.isRequired}
                                            size={this.store.size}
                                            propertyIdentifier="testValue"
                                            automationId="" />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            iconName="filter"
                                            label="Disabled with icon"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="Hint"
                                            disabled
                                            required={this.store.isRequired}
                                            size={this.store.size}
                                            propertyIdentifier="testValue"
                                            automationId="" />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            iconName="filter"
                                            label="Error with icon"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="Error"
                                            required={this.store.isRequired}
                                            size={this.store.size}
                                            propertyIdentifier="testValue"
                                            automationId="" />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            iconName="filter"
                                            label="Warning with icon"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="Warning"
                                            required={this.store.isRequired}
                                            size={this.store.size}
                                            propertyIdentifier="testValue"
                                            automationId="" />
                                    </Ui.Flex.Item>
                                </Ui.Flex>

                                <Ui.Flex>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            label="Normal with inner label"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="00"
                                            innerLabel={{ upperText: "Label1", lowerText: "Label2" }}
                                            required={this.store.isRequired}
                                            propertyIdentifier="testValue"
                                            automationId="" />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            label="Disabled with inner label"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="00"
                                            innerLabel={{ upperText: "Label1", lowerText: "Label2" }}
                                            disabled
                                            required={this.store.isRequired}
                                            propertyIdentifier="testValue"
                                            automationId="" />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            label="Error with inner label"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="00"
                                            innerLabel={{ upperText: "Error", lowerText: "Label2" }}
                                            required={this.store.isRequired}
                                            propertyIdentifier="testValue"
                                            automationId="" />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            label="Warning with inner label"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="00"
                                            innerLabel={{ upperText: "Warning", lowerText: "Label2" }}
                                            required={this.store.isRequired}
                                            propertyIdentifier="testValue"
                                            automationId="" />
                                    </Ui.Flex.Item>
                                </Ui.Flex>
                                <Ui.Flex>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            label="Readonly with inner label"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="00"
                                            innerLabel={{ upperText: "Warning", lowerText: "Label2" }}
                                            required={this.store.isRequired}
                                            propertyIdentifier="testValue"
                                            isReadOnly
                                            automationId=""
                                        />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            label="Readonly with inner label and disabled"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="00"
                                            innerLabel={{ upperText: "Warning", lowerText: "Label2" }}
                                            required={this.store.isRequired}
                                            propertyIdentifier="testValue"
                                            disabled
                                            isReadOnly
                                            automationId=""
                                        />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            label="Readonly normal"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="Hint"
                                            required={this.store.isRequired}
                                            size={this.store.size}
                                            propertyIdentifier="testValue"
                                            automationId="test1"
                                            isReadOnly
                                        />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            iconName="filter"
                                            label="Readonly normal with icon"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="Hint"
                                            required={this.store.isRequired}
                                            size={this.store.size}
                                            propertyIdentifier="testValue"
                                            isReadOnly
                                            automationId="" />
                                    </Ui.Flex.Item>
                                </Ui.Flex>
                                <Ui.Flex style={{ backgroundColor: "#384252" }}>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            label="Darkmode"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="Hint"
                                            required={this.store.isRequired}
                                            size={this.store.size}
                                            propertyIdentifier="testValue"
                                            visualMode="dark"
                                            automationId=""
                                        />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            label="Darkmode disabled"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="Hint"
                                            required={this.store.isRequired}
                                            size={this.store.size}
                                            propertyIdentifier="testValue"
                                            visualMode="dark"
                                            disabled
                                            automationId=""
                                        />
                                        <br />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            iconName="filter"
                                            label="Darkmode with icon"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="Hint"
                                            required={this.store.isRequired}
                                            size={this.store.size}
                                            visualMode="dark"
                                            propertyIdentifier="testValue"
                                            automationId="" />
                                    </Ui.Flex.Item>
                                    <Ui.Flex.Item xs={3}>
                                        <Ui.TextBox
                                            label="Normal with inner label"
                                            value={this.store.simpleValue}
                                            onChange={this.store.changeSimpleValue}
                                            placeholder="00"
                                            innerLabel={{ upperText: "Label1", lowerText: "Label2" }}
                                            required={this.store.isRequired}
                                            propertyIdentifier="testValue"
                                            visualMode="dark"
                                            automationId="" />
                                    </Ui.Flex.Item>
                                </Ui.Flex>
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </ValidationBoundary>

                    <hr />
                    <p>A text box is a graphical control element intended to enable the user to input text information to be used by the program.</p>

                    <label>My value:</label>
                    <Ui.TextBox automationId="" value={this.store.simpleValue} onChange={this.store.changeSimpleValue} />
                    <pre>Value: {this.store.simpleValue}</pre>

                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
<Ui.TextBox value={this.store.simpleValue} onChange={this.store.changeSimpleValue} />
`}
                        </SourceCode>
                        <SourceCode language="typescript">
                            {`
@State.observable public simpleValue: string = "";

@State.action.bound
public changeSimpleValue(newValue: string) {
    this.simpleValue = newValue;
}
`}
                        </SourceCode>
                    </Ui.SingleAccordion>

                    <hr />

                    <h3>Validatable value</h3>
                    {/* <ValidationContext.Provider value={this.store.validationContext}> */}
                    <Ui.TextBox
                        label="My value:"
                        value={this.store.simpleValue}
                        onChange={this.store.changeSimpleValue}
                        propertyIdentifier="testValue"
                        automationId=""
                    />
                    <pre>Value: {this.store.simpleValue}</pre>
                    {/* </ValidationContext.Provider> */}

                    <Ui.Button onClick={this.store.removeValidation} automationId="">Remove validation</Ui.Button>
                    <Ui.Button onClick={this.store.addError} automationId="">Add validation error</Ui.Button>
                    <Ui.Button onClick={this.store.addWarning} automationId="">Add validation warning</Ui.Button>

                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
<Ui.TextBox value={this.store.validatableValue} onChange={this.store.changeValidatableValue} />
`}
                        </SourceCode>
                        <SourceCode language="typescript">
                            {`
public validatableValue = new ValidatableProperty<string>("");

@State.action.bound
public changeValidatableValue(newValue: string) {
    this.validatableValue.value = newValue;
}
`}
                        </SourceCode>

                    </Ui.SingleAccordion>
                    <hr />

                    <h3>Masked text box</h3>
                    <label>My masked value:</label>
                    <Ui.TextBox automationId="" value={this.store.maskedValue} onChange={this.store.changeMaskedValue} mask="111-111-111" />
                    <pre>Value: {this.store.maskedValue}</pre>

                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
<Ui.TextBox value={this.store.maskedValue} onChange={this.store.changeMaskedValue} mask="111-111-111" />
`}
                        </SourceCode>

                        <SourceCode language="typescript">
                            {`
@State.observable public maskedValue: string = "";

@State.action.bound
public changeMaskedValue(newValue: string) {
    this.maskedValue = newValue;
}
`}
                        </SourceCode>
                    </Ui.SingleAccordion>
                    <hr />

                    <h3>Password box</h3>
                    <label>My password:</label>
                    <Ui.TextBox automationId="" value={this.store.passwordValue} onChange={this.store.changePasswordValue} isPassword />
                    <pre>Value: {this.store.passwordValue}</pre>

                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
<Ui.TextBox value={this.store.passwordValue} onChange={this.store.changePasswordValue} isPassword />
`}
                        </SourceCode>

                        <SourceCode language="typescript">
                            {`
@State.observable public passwordValue: string = "";

@State.action.bound
public changePasswordValue(newValue: string) {
    this.passwordValue = newValue;
}
`}
                        </SourceCode>
                    </Ui.SingleAccordion>
                    <hr />

                    <h3>Autoresize box</h3>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6}>
                            <Ui.TextBox
                                label="Max height set"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                multiline
                                multilineMaxLineCount={6}
                                autoResize
                                automationId="" />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            <Ui.TextBox
                                label="Max height not set"
                                value={this.store.simpleValue}
                                onChange={this.store.changeSimpleValue}
                                multiline
                                autoResize
                                automationId="" />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}
