import React from "react";
import * as Ui from "@CommonControls";
import * as DiContainer from "@DiContainer";
import SourceCode from "./SourceCode";
import ButtonExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/ButtonExampleStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class ButtonExampleBox extends React.Component {

    @DiContainer.lazyResolve("ButtonExampleStore") private store: ButtonExampleStore;

    public render() {
        return (
            <Ui.PageBox title="Button">
                <Ui.PageBox.Body>
                    <h2>Button</h2>
                    <h3>Standard size</h3>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" text="button" visualStyle="primary" tabIndex={1001}/><br />Primary
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /><Ui.Button automationId="" text="button" visualStyle="primary" tabIndex={1001} disabled/><br />Primary disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Panel inverted>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={4}>
                                <b>Button inverted</b><br />
                                Width: 8px * X | Height: 32px |<br />
                                Horizontal Padding: 8px |<br />
                                Vertical Padding: 8px
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <br /> <Ui.Button automationId="" text="button" visualStyle="primary" tabIndex={1001} visualMode="inverted" /><br />Primary
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <br /> <Ui.Button automationId="" text="button" visualStyle="primary" tabIndex={1001} visualMode="inverted" disabled /><br />Primary disabled
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.Panel>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" text="button" visualStyle="secondary" tabIndex={1001} /><br />Secondary
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /><Ui.Button automationId="" text="button" visualStyle="secondary" tabIndex={1001} disabled /><br />Secondary disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Panel inverted>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={4}>
                                <b>Button inverted</b><br />
                                Width: 8px * X | Height: 32px |<br />
                                Horizontal Padding: 8px |<br />
                                Vertical Padding: 8px
                        </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <br /> <Ui.Button automationId="" text="button" visualStyle="secondary" tabIndex={1001} visualMode="inverted" /><br />Secondary
                        </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <br /> <Ui.Button automationId="" text="button" visualStyle="secondary" tabIndex={1001} visualMode="inverted" disabled /><br />Secondary disabled
                        </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.Panel>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" text="button" visualStyle="standard" tabIndex={1001} /><br />Standard
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /><Ui.Button automationId="" text="button" visualStyle="standard" tabIndex={1001} disabled /><br />Standard disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Panel inverted>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={4}>
                                <b>Button inverted</b><br />
                                Width: 8px * X | Height: 32px |<br />
                                Horizontal Padding: 8px |<br />
                                Vertical Padding: 8px
                        </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <br /> <Ui.Button automationId="" text="button" visualStyle="standard" tabIndex={1001} visualMode="inverted" /><br />Standard
                        </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <br /> <Ui.Button automationId="" text="button" visualStyle="standard" tabIndex={1001} visualMode="inverted" disabled /><br />Standard disabled
                        </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.Panel>

                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" text="button" visualStyle="negative" tabIndex={1001} /><br />Negative
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /><Ui.Button automationId="" text="button" visualStyle="negative" tabIndex={1001} disabled /><br />negative disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Panel inverted>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={4}>
                                <b>Button inverted</b><br />
                                Width: 8px * X | Height: 32px |<br />
                                Horizontal Padding: 8px |<br />
                                Vertical Padding: 8px
                        </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <br /> <Ui.Button automationId="" text="button" visualStyle="negative" tabIndex={1001} visualMode="inverted" /><br />Negative
                        </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <br /> <Ui.Button automationId="" text="button" visualStyle="negative" tabIndex={1001} visualMode="inverted" disabled /><br />Negative disabled
                        </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.Panel>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" text="button" visualStyle="positive" tabIndex={1001} /><br />Positive
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" text="button" visualStyle="positive" tabIndex={1001} disabled /><br />Positive
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" text="button" visualStyle="negative-standard" tabIndex={1001} /><br />Negative-standard
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" text="button" visualStyle="negative-standard" tabIndex={1001} disabled /><br />Negative-standard disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" text="button" visualStyle="flat" tabIndex={1001} /><br />Flat standard
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="primary" text="click me" active /><br />Primary active
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="secondary" text="click me" active /><br />Secondary active
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="positive" text="click me" active /><br />Positive active
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="negative" text="click me" active /><br />Negative active
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="negative-standard" text="click me" active /><br />Negative-standard active
                        </Ui.Flex.Item>
                    </Ui.Flex>

                    <Ui.Flex style={{ backgroundColor: "#384252" }}>
                        <Ui.Flex.Item xs={4} style={{ color: "#f4f7fc" }}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="primary" text="click me" visualMode="dark" tabIndex={1001} /><br />Primary dark mode
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="primary" text="click me" visualMode="dark" disabled tabIndex={1001} /><br />Primary dark mode disabled
                        </Ui.Flex.Item>

                        <Ui.Flex.Item xs={4} style={{ color: "#f4f7fc" }}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="secondary" text="click me" visualMode="dark" tabIndex={1001} /><br />Secondary dark mode
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="secondary" text="click me" visualMode="dark" disabled tabIndex={1001} /><br />Secondary dark mode disabled

                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4} style={{ color: "#f4f7fc" }}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="standard" text="click me" visualMode="dark" tabIndex={1001} /><br />Normal dark mode
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="standard" text="click me" visualMode="dark" disabled tabIndex={1001} /><br />Normal dark mode disabled
                        </Ui.Flex.Item>

                        <Ui.Flex.Item xs={4} style={{ color: "#f4f7fc" }}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="negative" text="click me" visualMode="dark" tabIndex={1001} /><br />Negative dark mode
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="negative" text="click me" visualMode="dark" disabled tabIndex={1001} /><br />Negative dark mode disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <br />

                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {` 
<Ui.Button automationId="" text="button" visualStyle="primary" tabIndex={1001} onClick={doSomething} />
<Ui.Button automationId="" text="button" visualStyle="primary" tabIndex={1001} disabled />
<Ui.Button automationId="" text="button" visualStyle="primary" tabIndex={1001} visualMode="inverted" onClick={doSomething} />
<Ui.Button automationId="" text="button" visualStyle="primary" tabIndex={1001} visualMode="inverted" disabled />
<Ui.Button automationId="" text="button" visualStyle="secondary" tabIndex={1001} onClick={doSomething} />
<Ui.Button automationId="" text="button" visualStyle="secondary" tabIndex={1001} disabled />
<Ui.Button automationId="" text="button" visualStyle="secondary" tabIndex={1001} visualMode="inverted" onClick={doSomething} />
<Ui.Button automationId="" text="button" visualStyle="secondary" tabIndex={1001} visualMode="inverted" disabled />
<Ui.Button automationId="" text="button" visualStyle="standard" tabIndex={1001} onClick={doSomething} />
<Ui.Button automationId="" text="button" visualStyle="standard" tabIndex={1001} disabled />
<Ui.Button automationId="" text="button" visualStyle="standard" tabIndex={1001} visualMode="inverted" onClick={doSomething} />
<Ui.Button automationId="" text="button" visualStyle="standard" tabIndex={1001} visualMode="inverted" disabled />
<Ui.Button automationId="" text="button" visualStyle="negative" tabIndex={1001} onClick={doSomething} />
<Ui.Button automationId="" text="button" visualStyle="negative" tabIndex={1001} disabled />
<Ui.Button automationId="" text="button" visualStyle="negative" tabIndex={1001} visualMode="inverted" onClick={doSomething} />
<Ui.Button automationId="" text="button" visualStyle="negative" tabIndex={1001} visualMode="inverted" disabled />
<Ui.Button automationId="" text="button" visualStyle="positive" tabIndex={1001} />
<Ui.Button automationId="" text="button" visualStyle="positive" tabIndex={1001} disabled/>
<Ui.Button automationId="" text="button" visualStyle="negative-standard" tabIndex={1001} />
<Ui.Button automationId="" text="button" visualStyle="negative-standard" tabIndex={1001} disabled />
<Ui.Button automationId="" visualStyle="primary" text="click me" visualMode="dark" tabIndex={1001} />
<Ui.Button automationId="" visualStyle="primary" text="click me" visualMode="dark" disabled tabIndex={1001} />
<Ui.Button automationId="" visualStyle="secondary" text="click me" visualMode="dark" tabIndex={1001} />
<Ui.Button automationId="" visualStyle="secondary" text="click me" visualMode="dark" disabled tabIndex={1001} />
<Ui.Button automationId="" visualStyle="standard" text="click me" visualMode="dark" tabIndex={1001} />
<Ui.Button automationId="" visualStyle="standard" text="click me" visualMode="dark" disabled tabIndex={1001} />
<Ui.Button automationId="" visualStyle="negative" text="click me" visualMode="dark" tabIndex={1001} />
<Ui.Button automationId="" visualStyle="negative" text="click me" visualMode="dark" disabled tabIndex={1001} />

`}
                        </SourceCode>
                    </Ui.SingleAccordion>

                    <h3>Compact size</h3>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 24px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" text="button" visualStyle="primary" tabIndex={1001} size="compact" /><br />Primary
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /><Ui.Button automationId="" text="button" visualStyle="primary" tabIndex={1001} disabled size="compact" /><br />Primary disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Panel inverted>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={4}>
                                <b>Button inverted</b><br />
                                Width: 8px * X | Height: 24px |<br />
                                Horizontal Padding: 8px |<br />
                                Vertical Padding: 8px
                        </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <br /> <Ui.Button automationId="" text="button" visualStyle="primary" tabIndex={1001} size="compact" visualMode="inverted" /><br />Primary
                        </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <br /> <Ui.Button automationId="" text="button" visualStyle="primary" tabIndex={1001} disabled size="compact" visualMode="inverted" /><br />Primary disabled
                        </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.Panel>

                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 24px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" text="button" visualStyle="secondary" tabIndex={1001} size="compact" /><br />Secondary
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /><Ui.Button automationId="" text="button" visualStyle="secondary" tabIndex={1001} disabled size="compact" /><br />Secondary disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Panel inverted>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={4}>
                                <b>Button inverted</b><br />
                                Width: 8px * X | Height: 24px |<br />
                                Horizontal Padding: 8px |<br />
                                Vertical Padding: 8px
                        </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <br /> <Ui.Button automationId="" text="button" visualStyle="secondary" tabIndex={1001} size="compact" visualMode="inverted" /><br />Secondary
                        </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <br /> <Ui.Button automationId="" text="button" visualStyle="secondary" tabIndex={1001} disabled size="compact" visualMode="inverted" /><br />Secondary disabled
                        </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.Panel>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 24px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" text="button" visualStyle="standard" tabIndex={1001} size="compact" /><br />Standard
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /><Ui.Button automationId="" text="button" visualStyle="standard" tabIndex={1001} disabled size="compact" /><br />Standard disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Panel inverted>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={4}>
                                <b>Button inverted</b><br />
                                Width: 8px * X | Height: 24px |<br />
                                Horizontal Padding: 8px |<br />
                                Vertical Padding: 8px
                        </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <br /> <Ui.Button automationId="" text="button" visualStyle="standard" tabIndex={1001} size="compact" visualMode="inverted" /><br />Standard
                        </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <br /> <Ui.Button automationId="" text="button" visualStyle="standard" tabIndex={1001} disabled size="compact" visualMode="inverted" /><br />Standard disabled
                        </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.Panel>

                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 24px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" text="button" visualStyle="negative" tabIndex={1001} size="compact" /><br />Negative
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /><Ui.Button automationId="" text="button" visualStyle="negative" tabIndex={1001} disabled size="compact" /><br />negative disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Panel inverted>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={4}>
                                <b>Button inverted</b><br />
                                Width: 8px * X | Height: 24px |<br />
                                Horizontal Padding: 8px |<br />
                                Vertical Padding: 8px
                        </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <br /> <Ui.Button automationId="" text="button" visualStyle="negative" tabIndex={1001} size="compact" visualMode="inverted" /><br />Negative
                        </Ui.Flex.Item>
                            <Ui.Flex.Item xs={4}>
                                <br /> <Ui.Button automationId="" text="button" visualStyle="negative" tabIndex={1001} disabled size="compact" visualMode="inverted" /><br />Negative disabled
                        </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.Panel>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 24px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" text="button" visualStyle="positive" tabIndex={1001} size="compact" /><br />Positive
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" text="button" visualStyle="positive" tabIndex={1001} disabled size="compact" /><br />Positive
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 24px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" text="button" visualStyle="negative-standard" tabIndex={1001} size="compact" /><br />Negative-standard
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" text="button" visualStyle="negative-standard" tabIndex={1001} disabled size="compact" /><br />Negative-standard disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 24px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" text="button" visualStyle="flat" tabIndex={1001} size="compact" /><br />Flat
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 24px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="primary" text="click me" active size="compact" /><br />Primary active
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 24px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="secondary" text="click me" active size="compact" /><br />Secondary active
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 24px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="positive" text="click me" active size="compact" /><br />Positive active
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 24px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="negative" text="click me" active size="compact" /><br />Negative active
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 24px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="negative-standard" text="click me" active size="compact" /><br />Negative-standard active
                        </Ui.Flex.Item>
                    </Ui.Flex>

                    <Ui.Flex style={{ backgroundColor: "#384252" }}>
                        <Ui.Flex.Item xs={4} style={{ color: "#f4f7fc" }}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="primary" text="click me" visualMode="dark" size="compact" tabIndex={1001} /><br />Primary dark mode
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="primary" text="click me" visualMode="dark" size="compact" disabled tabIndex={1001} /><br />Primary dark mode disabled
                        </Ui.Flex.Item>

                        <Ui.Flex.Item xs={4} style={{ color: "#f4f7fc" }}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="secondary" text="click me" visualMode="dark" size="compact" tabIndex={1001} /><br />Secondary dark mode
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="secondary" text="click me" visualMode="dark" size="compact" disabled tabIndex={1001} /><br />Secondary dark mode disabled

                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4} style={{ color: "#f4f7fc" }}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="standard" text="click me" visualMode="dark" size="compact" tabIndex={1001} /><br />Normal dark mode
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="standard" text="click me" visualMode="dark" size="compact" disabled tabIndex={1001} /><br />Normal dark mode disabled
                        </Ui.Flex.Item>

                        <Ui.Flex.Item xs={4} style={{ color: "#f4f7fc" }}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="negative" text="click me" visualMode="dark" size="compact" tabIndex={1001} /><br />Negative dark mode
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="negative" text="click me" visualMode="dark" size="compact" disabled tabIndex={1001} /><br />Negative dark mode disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {` 
<Ui.Button automationId="" text="button" visualStyle="primary" tabIndex={1001} size="compact" />
<Ui.Button automationId="" text="button" visualStyle="primary" tabIndex={1001} disabled size="compact" />
<Ui.Button automationId="" text="button" visualStyle="primary" tabIndex={1001} size="compact" visualMode="inverted" />
<Ui.Button automationId="" text="button" visualStyle="primary" tabIndex={1001} disabled size="compact" visualMode="inverted" />
<Ui.Button automationId="" text="button" visualStyle="secondary" tabIndex={1001} size="compact" />
<Ui.Button automationId="" text="button" visualStyle="secondary" tabIndex={1001} disabled size="compact" />
<Ui.Button automationId="" text="button" visualStyle="secondary" tabIndex={1001} size="compact" visualMode="inverted" />
<Ui.Button automationId="" text="button" visualStyle="secondary" tabIndex={1001} disabled size="compact" visualMode="inverted" />
<Ui.Button automationId="" text="button" visualStyle="standard" tabIndex={1001} size="compact" />
<Ui.Button automationId="" text="button" visualStyle="standard" tabIndex={1001} disabled size="compact" />
<Ui.Button automationId="" text="button" visualStyle="standard" tabIndex={1001} size="compact" visualMode="inverted" />
<Ui.Button automationId="" text="button" visualStyle="standard" tabIndex={1001} disabled size="compact" visualMode="inverted" />
<Ui.Button automationId="" text="button" visualStyle="negative" tabIndex={1001} size="compact" />
<Ui.Button automationId="" text="button" visualStyle="negative" tabIndex={1001} disabled size="compact" />
<Ui.Button automationId="" text="button" visualStyle="negative" tabIndex={1001} size="compact" visualMode="inverted" />
<Ui.Button automationId="" text="button" visualStyle="negative" tabIndex={1001} disabled size="compact" visualMode="inverted" />
<Ui.Button automationId="" text="button" visualStyle="positive" tabIndex={1001} size="compact"/>
<Ui.Button automationId="" text="button" visualStyle="positive" tabIndex={1001} disabled size="compact"/>
<Ui.Button automationId="" text="button" visualStyle="negative-standard" tabIndex={1001} size="compact"  />
<Ui.Button automationId="" text="button" visualStyle="negative-standard" tabIndex={1001} disabled size="compact"  />
<Ui.Button automationId="" visualStyle="primary" text="click me" visualMode="dark" size="compact" tabIndex={1001} />
<Ui.Button automationId="" visualStyle="primary" text="click me" visualMode="dark" size="compact" disabled tabIndex={1001} />
<Ui.Button automationId="" visualStyle="secondary" text="click me" visualMode="dark" size="compact" tabIndex={1001} />
<Ui.Button automationId="" visualStyle="secondary" text="click me" visualMode="dark" size="compact" disabled tabIndex={1001} />
<Ui.Button automationId="" visualStyle="standard" text="click me" visualMode="dark" size="compact" tabIndex={1001} />
<Ui.Button automationId="" visualStyle="standard" text="click me" visualMode="dark" size="compact" disabled tabIndex={1001} />
<Ui.Button automationId="" visualStyle="negative" text="click me" visualMode="dark" size="compact" tabIndex={1001} />
<Ui.Button automationId="" visualStyle="negative" text="click me" visualMode="dark" size="compact" disabled tabIndex={1001} />
`}
                        </SourceCode>
                    </Ui.SingleAccordion>

                    <h2>Inverted button with icon</h2>
                    <Ui.Panel inverted>
                        <Ui.Flex>
                            <Ui.Flex.Item xs={3}>
                                <h3>Standard size</h3>
                                Normal: <Ui.Button automationId="" iconName="cog" text="button" tabIndex={1000} visualMode="inverted" /><br />
                                Primary: <Ui.Button automationId="" iconName="cog" text="button" visualStyle="primary" tabIndex={1001} visualMode="inverted" /><br />
                                Secondary: <Ui.Button automationId="" iconName="cog" text="button" visualStyle="secondary" tabIndex={1002} visualMode="inverted" /><br />
                                Negative: <Ui.Button automationId="" iconName="cog" text="button" visualStyle="negative" tabIndex={1004} visualMode="inverted" /><br />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={3}>
                                <h3>Disabled</h3>
                                Normal: <Ui.Button automationId="" iconName="cog" text="button" tabIndex={1000} disabled visualMode="inverted" /><br />
                                Primary: <Ui.Button automationId="" iconName="cog" text="button" visualStyle="primary" tabIndex={1001} disabled visualMode="inverted" /><br />
                                Secondary: <Ui.Button automationId="" iconName="cog" text="button" visualStyle="secondary" tabIndex={1002} disabled visualMode="inverted" /><br />
                                Negative: <Ui.Button automationId="" iconName="cog" text="button" visualStyle="negative" tabIndex={1004} disabled visualMode="inverted" /><br />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={3}>
                                <h3>Compact</h3>
                                Normal: <Ui.Button automationId="" iconName="cog" text="button" tabIndex={1006} size="compact" visualMode="inverted" /><br />
                                Primary: <Ui.Button automationId="" iconName="cog" text="button" visualStyle="primary" tabIndex={1007} size="compact" visualMode="inverted" /><br />
                                Secondary: <Ui.Button automationId="" iconName="cog" text="button" visualStyle="secondary" tabIndex={1008} size="compact" visualMode="inverted" /><br />
                                Negative: <Ui.Button automationId="" iconName="cog" text="button" visualStyle="negative" tabIndex={1010} size="compact" visualMode="inverted" /><br />
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={3}>
                                <h3>Disabled</h3>
                                Normal: <Ui.Button automationId="" iconName="cog" text="button" tabIndex={1006} size="compact" disabled visualMode="inverted" /><br />
                                Primary: <Ui.Button automationId="" iconName="cog" text="button" visualStyle="primary" tabIndex={1007} size="compact" disabled visualMode="inverted" /><br />
                                Secondary: <Ui.Button automationId="" iconName="cog" text="button" visualStyle="secondary" tabIndex={1008} size="compact" disabled visualMode="inverted" /><br />
                                Negative: <Ui.Button automationId="" iconName="cog" text="button" visualStyle="negative" tabIndex={1010} size="compact" disabled visualMode="inverted" /><br />
                            </Ui.Flex.Item>
                        </Ui.Flex>
                    </Ui.Panel>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {` 
<Ui.Button automationId="" iconName="cog" text="button" tabIndex={1000} visualMode="inverted" /><br />
<Ui.Button automationId="" iconName="cog" text="button" visualStyle="primary" tabIndex={1001} visualMode="inverted" />
<Ui.Button automationId="" iconName="cog" text="button" visualStyle="secondary" tabIndex={1002} visualMode="inverted" />
<Ui.Button automationId="" iconName="cog" text="button" visualStyle="negative" tabIndex={1004} visualMode="inverted" />
<Ui.Button automationId="" iconName="cog" text="button" tabIndex={1000} disabled visualMode="inverted" />
<Ui.Button automationId="" iconName="cog" text="button" visualStyle="primary" tabIndex={1001} disabled visualMode="inverted" />
<Ui.Button automationId="" iconName="cog" text="button" visualStyle="secondary" tabIndex={1002} disabled visualMode="inverted" />
<Ui.Button automationId="" iconName="cog" text="button" visualStyle="negative" tabIndex={1004} disabled visualMode="inverted" />
`}
                        </SourceCode>
                    </Ui.SingleAccordion>
                    <h2>Fluid buttons</h2>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={3}>
                            <h3>Standard size</h3>
                            Normal: <Ui.Button automationId="" fluid text="button" tabIndex={1000} /><br />
                            Primary: <Ui.Button automationId="" fluid text="button" visualStyle="primary" tabIndex={1001} /><br />
                            Secondary: <Ui.Button automationId="" fluid text="button" visualStyle="secondary" tabIndex={1002} /><br />
                            Negative: <Ui.Button automationId="" fluid text="button" visualStyle="negative" tabIndex={1004} /><br />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <h3>Disabled</h3>
                            Normal: <Ui.Button automationId="" fluid text="button" tabIndex={1000} disabled /><br />
                            Primary: <Ui.Button automationId="" fluid text="button" visualStyle="primary" tabIndex={1001} disabled /><br />
                            Secondary: <Ui.Button automationId="" fluid text="button" visualStyle="secondary" tabIndex={1002} disabled /><br />
                            Negative: <Ui.Button automationId="" fluid text="button" visualStyle="negative" tabIndex={1004} disabled /><br />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <h3>Compact</h3>
                            Normal: <Ui.Button automationId="" fluid text="button" tabIndex={1006} size="compact" /><br />
                            Primary: <Ui.Button automationId="" fluid text="button" visualStyle="primary" tabIndex={1007} size="compact" /><br />
                            Secondary: <Ui.Button automationId="" fluid text="button" visualStyle="secondary" tabIndex={1008} size="compact" /><br />
                            Negative: <Ui.Button automationId="" fluid text="button" visualStyle="negative" tabIndex={1010} size="compact" /><br />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <h3>Disabled</h3>
                            Normal: <Ui.Button automationId="" fluid text="button" tabIndex={1006} size="compact" disabled /><br />
                            Primary: <Ui.Button automationId="" fluid text="button" visualStyle="primary" tabIndex={1007} size="compact" disabled /><br />
                            Secondary: <Ui.Button automationId="" fluid text="button" visualStyle="secondary" tabIndex={1008} size="compact" disabled /><br />
                            Negative: <Ui.Button automationId="" fluid text="button" visualStyle="negative" tabIndex={1010} size="compact" disabled /><br />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`  
  <Ui.Button automationId="" fluid text="button" tabIndex={1000} />
  <Ui.Button automationId="" fluid text="button" visualStyle="primary" tabIndex={1001} />
  <Ui.Button automationId="" fluid text="button" visualStyle="secondary" tabIndex={1002} />
  <Ui.Button automationId="" fluid text="button" visualStyle="negative" tabIndex={1004} />
  <Ui.Button automationId="" fluid text="button" tabIndex={1000} disabled />
  <Ui.Button automationId="" fluid text="button" visualStyle="primary" tabIndex={1001} disabled />
  <Ui.Button automationId="" fluid text="button" visualStyle="secondary" tabIndex={1002} disabled />
  <Ui.Button automationId="" fluid text="button" visualStyle="negative" tabIndex={1004} disabled />
  
  <Ui.Button automationId="" fluid text="button" tabIndex={1006} size="compact" />
  <Ui.Button automationId="" fluid text="button" visualStyle="primary" tabIndex={1007} size="compact" />
  <Ui.Button automationId="" fluid text="button" visualStyle="secondary" tabIndex={1008} size="compact" />
  <Ui.Button automationId="" fluid text="button" visualStyle="negative" tabIndex={1010} size="compact" />
  <Ui.Button automationId="" fluid text="button" tabIndex={1006} size="compact" disabled />
  <Ui.Button automationId="" fluid text="button" visualStyle="primary" tabIndex={1007} size="compact" disabled />
  <Ui.Button automationId="" fluid text="button" visualStyle="secondary" tabIndex={1008} size="compact" disabled />
  <Ui.Button automationId="" fluid text="button" visualStyle="negative" tabIndex={1010} size="compact" disabled />
`}
                        </SourceCode>
                    </Ui.SingleAccordion>
                    <hr />
                    <h2>Icon button</h2>
                    <h4>Standard:</h4><br />
                    <Ui.Flex>
                        <Ui.Flex.Item xs={3}>
                            <b>Icon</b><br />
                            Width: 32px | Height: 32px | <br />
                            Padding: 4px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.Button automationId="" iconName="cog" tabIndex={1017} /><br />Standard
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.Button automationId="" iconName="cog" tabIndex={1011} text="button" /><br />Standard
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.Button automationId="" iconName="cog" tabIndex={1018} disabled /><br />Standard disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={3} xsOffset={3}>
                            <Ui.Button automationId="" iconName="cog" visualStyle="primary" tabIndex={1019} /><br />Primary
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.Button automationId="" iconName="cog" visualStyle="primary" tabIndex={1020} disabled /><br />Primary disabled
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.Button automationId="" iconName="cog" visualStyle="secondary" tabIndex={1021} /><br />Secondary
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={3} xsOffset={3}>
                            <Ui.Button automationId="" iconName="cog" visualStyle="secondary" tabIndex={1022} disabled /><br />Secondary disabled
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3} >
                            <Ui.Button automationId="" visualStyle="negative-standard" text="click me" iconName="cog" /><br />Negative-standard
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3} >
                            <Ui.Button automationId="" visualStyle="negative-standard" text="click me" iconName="cog" disabled /><br /> Negative-standard disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <h4>Compact:</h4><br />
                    <Ui.Flex>
                        <Ui.Flex.Item xs={3}>
                            <b>Small icon</b><br />
                            Width: 24px | Height: 24px | <br />
                            Icon Size: 16px * 16px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.Button automationId="" iconName="cog" size="compact" tabIndex={1017} /><br />Standard
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.Button automationId="" iconName="cog" size="compact" tabIndex={1011} text="button" /><br />Standard
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.Button automationId="" iconName="cog" size="compact" tabIndex={1018} disabled /><br />Standard disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={3} xsOffset={3}>
                            <Ui.Button automationId="" iconName="cog" visualStyle="primary" tabIndex={1019} size="compact" /><br />Primary
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.Button automationId="" iconName="cog" visualStyle="primary" tabIndex={1020} size="compact" disabled /><br />Primary disabled
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.Button automationId="" iconName="cog" visualStyle="secondary" tabIndex={1021} size="compact" /><br />Secondary
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={3} xsOffset={3}>
                            <Ui.Button automationId="" iconName="cog" visualStyle="secondary" tabIndex={1022} size="compact" disabled /><br />Secondary disabled
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3} >
                            <Ui.Button automationId="" visualStyle="negative-standard" text="click me" iconName="cog" size="compact" /><br />Negative-standard
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3} >
                            <Ui.Button automationId="" visualStyle="negative-standard" text="click me" iconName="cog" disabled size="compact" /><br />Negative-standard disabled
                        </Ui.Flex.Item>
                    </Ui.Flex><br />

                    <Ui.Flex style={{ backgroundColor: "#384252" }}>
                        <Ui.Flex.Item xs={4} style={{ color: "#f4f7fc" }}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="primary" text="click me" visualMode="dark" iconName="cog" tabIndex={1001} /><br />Primary dark mode
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="primary" text="click me" visualMode="dark" iconName="cog" disabled tabIndex={1001} /><br />Primary dark mode disabled
                        </Ui.Flex.Item>

                        <Ui.Flex.Item xs={4} style={{ color: "#f4f7fc" }}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="secondary" text="click me" visualMode="dark" iconName="cog" tabIndex={1001} /><br />Secondary dark mode
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="secondary" text="click me" visualMode="dark" iconName="cog" disabled tabIndex={1001} /><br />Secondary dark mode disabled

                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4} style={{ color: "#f4f7fc" }}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="standard" text="click me" visualMode="dark" iconName="cog" tabIndex={1001} /><br />Normal dark mode
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="standard" text="click me" visualMode="dark" iconName="cog" disabled tabIndex={1001} /><br />Normal dark mode disabled
                        </Ui.Flex.Item>

                        <Ui.Flex.Item xs={4} style={{ color: "#f4f7fc" }}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="negative" text="click me" visualMode="dark" iconName="cog" tabIndex={1001} /><br />Negative dark mode
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="negative" text="click me" visualMode="dark" iconName="cog" disabled tabIndex={1001} /><br />Negative dark mode disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <b>Compact:</b><br />
                    <Ui.Flex style={{ backgroundColor: "#384252" }}>
                        <Ui.Flex.Item xs={4} style={{ color: "#f4f7fc" }}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="primary" text="click me" visualMode="dark" size="compact" iconName="cog" tabIndex={1001} /><br />Primary dark mode
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="primary" text="click me" visualMode="dark" size="compact" iconName="cog" disabled tabIndex={1001} /><br />Primary dark mode disabled
                        </Ui.Flex.Item>

                        <Ui.Flex.Item xs={4} style={{ color: "#f4f7fc" }}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="secondary" text="click me" visualMode="dark" size="compact" iconName="cog" tabIndex={1001} /><br />Secondary dark mode
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="secondary" text="click me" visualMode="dark" size="compact" iconName="cog" disabled tabIndex={1001} /><br />Secondary dark mode disabled

                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4} style={{ color: "#f4f7fc" }}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="standard" text="click me" visualMode="dark" size="compact" iconName="cog" tabIndex={1001} /><br />Normal dark mode
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="standard" text="click me" visualMode="dark" size="compact" iconName="cog" disabled tabIndex={1001} /><br />Normal dark mode disabled
                        </Ui.Flex.Item>

                        <Ui.Flex.Item xs={4} style={{ color: "#f4f7fc" }}>
                            <b>Button</b><br />
                            Width: 8px * X | Height: 32px |<br />
                            Horizontal Padding: 8px |<br />
                            Vertical Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="negative" text="click me" visualMode="dark" size="compact" iconName="cog" tabIndex={1001} /><br />Negative dark mode
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <br /> <Ui.Button automationId="" visualStyle="negative" text="click me" visualMode="dark" size="compact" iconName="cog" disabled tabIndex={1001} /><br />Negative dark mode disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
                            
<Ui.Button automationId="" iconName="cog" tabIndex={1017} />
<Ui.Button automationId="" iconName="cog" tabIndex={1011} text="button" />
<Ui.Button automationId="" iconName="cog" tabIndex={1018} disabled />
<Ui.Button automationId="" iconName="cog" visualStyle="primary" tabIndex={1019} />
<Ui.Button automationId="" iconName="cog" visualStyle="primary" tabIndex={1020} disabled />
<Ui.Button automationId="" iconName="cog" visualStyle="secondary" tabIndex={1021} />
<Ui.Button automationId="" iconName="cog" visualStyle="secondary" tabIndex={1022} disabled />

<Ui.Button automationId="" iconName="cog" size="compact" tabIndex={1017} />
<Ui.Button automationId="" iconName="cog" size="compact" tabIndex={1011} text="button" />
<Ui.Button automationId="" iconName="cog" size="compact" tabIndex={1018} disabled />
<Ui.Button automationId="" iconName="cog" visualStyle="primary" tabIndex={1019} size="compact" />
<Ui.Button automationId="" iconName="cog" visualStyle="primary" tabIndex={1020} size="compact" disabled />
<Ui.Button automationId="" iconName="cog" visualStyle="secondary" tabIndex={1021} size="compact" />
<Ui.Button automationId="" iconName="cog" visualStyle="secondary" tabIndex={1022} size="compact" disabled />
<Ui.Button automationId="" visualStyle="primary" text="click me" visualMode="dark" iconName="cog" tabIndex={1001} />
<Ui.Button automationId="" visualStyle="primary" text="click me" visualMode="dark" iconName="cog" disabled tabIndex={1001} />
<Ui.Button automationId="" visualStyle="secondary" text="click me" visualMode="dark" iconName="cog" tabIndex={1001} />
<Ui.Button automationId="" visualStyle="secondary" text="click me" visualMode="dark" iconName="cog" disabled tabIndex={1001} />
<Ui.Button automationId="" visualStyle="standard" text="click me" visualMode="dark" iconName="cog" tabIndex={1001} />
<Ui.Button automationId="" visualStyle="standard" text="click me" visualMode="dark" iconName="cog" disabled tabIndex={1001} />
<Ui.Button automationId="" visualStyle="negative" text="click me" visualMode="dark" iconName="cog" tabIndex={1001} />
<Ui.Button automationId="" visualStyle="negative" text="click me" visualMode="dark" iconName="cog" disabled tabIndex={1001} />
<Ui.Button automationId="" visualStyle="primary" text="click me" visualMode="dark" size="compact" iconName="cog" tabIndex={1001} />
<Ui.Button automationId="" visualStyle="primary" text="click me" visualMode="dark" size="compact" iconName="cog" disabled tabIndex={1001} />
<Ui.Button automationId="" visualStyle="secondary" text="click me" visualMode="dark" size="compact" iconName="cog" tabIndex={1001} />
<Ui.Button automationId="" visualStyle="secondary" text="click me" visualMode="dark" size="compact" iconName="cog" disabled tabIndex={1001} />
<Ui.Button automationId="" visualStyle="standard" text="click me" visualMode="dark" size="compact" iconName="cog" tabIndex={1001} />
<Ui.Button automationId="" visualStyle="standard" text="click me" visualMode="dark" size="compact" iconName="cog" disabled tabIndex={1001} />
<Ui.Button automationId="" visualStyle="negative" text="click me" visualMode="dark" size="compact" iconName="cog" tabIndex={1001} />
<Ui.Button automationId="" visualStyle="negative" text="click me" visualMode="dark" size="compact" iconName="cog" disabled tabIndex={1001} />
`}

                        </SourceCode>
                    </Ui.SingleAccordion>
                    <hr />
                    <h2>Link</h2>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Link</b><br />
                            Horizontal Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.Button automationId="" text="Link" visualStyle="link" tabIndex={1023} /><br />Primary
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.Button automationId="" text="Link" visualStyle="link" tabIndex={1024} disabled /><br />Disabled
                        </Ui.Flex.Item>
                    </Ui.Flex><br />
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Link with icon</b><br />
                            Icon Size: 16px * 16px | Horizontal Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.Button automationId="" iconName="cog" text="Link" visualStyle="link" tabIndex={1025} /><br />Primary
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.Button automationId="" iconName="cog" text="Link" visualStyle="link" tabIndex={1026} disabled /><br />Disabled
                        </Ui.Flex.Item>
                    </Ui.Flex><br />
                    <Ui.Flex>
                        <Ui.Flex.Item xs={4}>
                            <b>Small link with icon</b><br />
                            Icon Size: 8px * 8px | Horizontal Padding: 8px
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.Button automationId="" iconName="angleLeft" text="Label" visualStyle="link" size="compact" tabIndex={1025} /><br />Primary
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <Ui.Button automationId="" iconName="angleLeft" text="Label" visualStyle="link" size="compact" tabIndex={1026} disabled /> <br />Disabled
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
<Ui.Button automationId="" text="Link" visualStyle="link" tabIndex={1023} />
<Ui.Button automationId="" text="Link" visualStyle="link" tabIndex={1024} disabled />
<Ui.Button automationId="" iconName="cog" text="Link" visualStyle="link" tabIndex={1025} />
<Ui.Button automationId="" iconName="cog" text="Link" visualStyle="link" tabIndex={1026} disabled />
<Ui.Button automationId="" iconName="angleLeft" text="Label" visualStyle="link" size="compact" tabIndex={1025} />
<Ui.Button automationId="" iconName="angleLeft" text="Label" visualStyle="link" size="compact" tabIndex={1026} disabled />                            
`}

                        </SourceCode>
                    </Ui.SingleAccordion>
                    <hr />
                    <h2>Button with async function</h2>
                    <Ui.Button automationId="" onClickAsync={this.store.testButtonClickAsync} text="click me" visualStyle="primary" />
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`<Ui.Button automationId="" onClickAsync={this.store.testButtonClickAsync} text="click me" visualStyle="primary" />

@State.action.bound
public async testButtonClickAsync() {
    await this.sleepAsync(1500);
}
`}

                        </SourceCode>
                    </Ui.SingleAccordion>
                    <hr />
                    <h2>Button with tooltip</h2>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={3}>
                            <Ui.Button text="click me" tooltipContent="Tooltip on top!" tooltipPosition="top" automationId="" />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.Button text="click me" tooltipContent="Tooltip on left!" tooltipPosition="left" automationId="" />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.Button text="click me" tooltipContent="Tooltip on right!" tooltipPosition="right" automationId="" />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.Button text="click me" tooltipContent="Tooltip on bottom!" tooltipPosition="bottom" automationId="" />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {
`
<Ui.Button text="click me" tooltipContent="Tooltip on top!" tooltipPosition="top" automationId="" />
<Ui.Button text="click me" tooltipContent="Tooltip on left!" tooltipPosition="left" automationId="" />
<Ui.Button text="click me" tooltipContent="Tooltip on right!" tooltipPosition="right" automationId="" />
<Ui.Button text="click me" tooltipContent="Tooltip on bottom!" tooltipPosition="bottom" automationId="" />
`
                            }

                        </SourceCode>
                    </Ui.SingleAccordion>
                    <hr />
                    <h2>Disabled button with tooltip</h2>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={3}>
                            <Ui.Button text="click me" tooltipContent="Tooltip on top!" tooltipPosition="top" automationId="" disabled />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.Button text="click me" tooltipContent="Tooltip on left!" tooltipPosition="left" automationId="" disabled />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.Button text="click me" tooltipContent="Tooltip on right!" tooltipPosition="right" automationId="" disabled />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <Ui.Button text="click me" tooltipContent="Tooltip on bottom!" tooltipPosition="bottom" automationId="" disabled />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {
`
<Ui.Button text="click me" tooltipContent="Tooltip on top!" tooltipPosition="top" automationId="" disabled/>
<Ui.Button text="click me" tooltipContent="Tooltip on left!" tooltipPosition="left" automationId="" disabled/>
<Ui.Button text="click me" tooltipContent="Tooltip on right!" tooltipPosition="right" automationId="" disabled/>
<Ui.Button text="click me" tooltipContent="Tooltip on bottom!" tooltipPosition="bottom" automationId=""disabled />
`
                            }

                        </SourceCode>
                    </Ui.SingleAccordion>
                </Ui.PageBox.Body>
            </Ui.PageBox >
        );
    }
}