import Di from "@Di";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import FinancingClassId from "@Primitives/FinancingClassId.g";
import AdmissionReasonId from "@Primitives/AdmissionReasonId.g";
import DischargeReasonId from "@Primitives/DischargeReasonId.g";
import LateralityId from "@Primitives/LateralityId.g";
import ISelectBoxGroup from "@CommonControls/SelectBox/ISelectBoxGroup";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

@Di.injectable()
export default class SelectBoxExampleStore {

    @State.observable.ref public value: ISampleItem = null;
    @State.observable.ref public options: Array<ISelectBoxItem<ISampleItem>> = null;
    @State.observable.ref public groupedValue: number = null;
    @State.observable.ref public groupedMultiValue: number[] = null;

    @State.observable.ref public value2: ISampleItem = null;    
    @State.observable.ref public value3: ISampleItem = null;
    @State.observable.ref public preloadedOptions: Array<ISelectBoxItem<ISampleItem>> = this.getOptions();
    @State.observable.ref public groupedOptions: ISelectBoxGroup[] = this.getGroupedOptions();

    @State.observable.ref public selectedFinancingClassId: FinancingClassId = null;
    @State.observable.ref public selectedAdmissionReasonId: AdmissionReasonId = null;
    @State.observable.ref public selectedDischargeReasonId: DischargeReasonId = null;
    @State.observable.ref public selectedLateralityId: LateralityId = null;

    @State.observable public isRequired: boolean = false;
    @State.observable public isCompact: boolean = false;

    @State.observable.ref public multiValue: ISampleItem[] = null;
    @State.observable.ref public validationResults: IClientValidationResult[] = null;

    @State.observable.ref public color: string = null;
    
    @State.computed public get size(): "compact" | "standard" {
        return this.isCompact ? "compact" : undefined;
    }

    @State.action.bound
    public loadOptionsAsync() {
        return new Promise((resolve: any) => {

            State.runInAction(() => {
                this.options = this.getOptions();
            });

            resolve();

        });
    }

    private getOptions() {
        return [
            { text: "2 - Name1", value: { SampleName: "Name1", SampleNumber: 2 } } as ISelectBoxItem<ISampleItem>,
            { text: "4 - Name2", value: { SampleName: "Name2", SampleNumber: 4 } } as ISelectBoxItem<ISampleItem>,
            { text: "6 - Name3", value: { SampleName: "Name3", SampleNumber: 6 } } as ISelectBoxItem<ISampleItem>,
            { text: "8 - Name4", value: { SampleName: "Name4", SampleNumber: 8 } } as ISelectBoxItem<ISampleItem>,
        ];
    }

    private getGroupedOptions() {
        return [
            { label: "Group 1", options: [ { text: "Item 1", value: 0 }, { text: "Item 2", value: 1 }, { text: "Item 3", value: 2 } ] },
            { label: "Group 2", options: [ { text: "Item 4", value: 3 }, { text: "Item 5", value: 4 }, { text: "Item 6", value: 5 } ] },
        ];
    }

    @State.action.bound
    public setValue(newValue: ISampleItem) {
        this.value = newValue;
    }

    @State.action.bound
    public setMultiValue(newValue: ISampleItem[]) {
        this.multiValue = newValue;
    }

    @State.action.bound
    public setValue2(newValue: ISampleItem) {
        this.value2 = newValue;
    }
    
    @State.action.bound
    public setValue3(newValue: ISampleItem) {
        this.value3 = newValue;
    }

    @State.action.bound
    public setGroupedValue(value: number) {
        this.groupedValue = value;
    }

    @State.action.bound
    public setGroupedMultiValue(values: number[]) {
        this.groupedMultiValue = values;
    }

    @State.action.bound
    public setColor(color: string) {
        this.color = color;
    }

    public getOptionValue(value: ISelectBoxItem<ISampleItem>) {
        if (!value.value) {
            return "null";
        }

        return `${value.value.SampleNumber}_${value.value.SampleName}`;
    }

    public getGroupedOptionValue(value: ISelectBoxItem<any>) {
        if (!value.value) {
            return "null";
        }

        return `${value.value.text}`;
    }

    @State.action.bound
    public addNewOption(optionText: string) {
        const newItemValue = { SampleName: optionText, SampleNumber: -1 };
        this.preloadedOptions = [
            ...this.preloadedOptions,
            { text: optionText, value: newItemValue }
        ];
        this.value3 = newItemValue;
    }

    @State.action.bound
    public setName1() {
        this.value = { SampleName: "Name1", SampleNumber: 2 };
    }

    @State.action.bound
    public setFinancingClass(fcid: FinancingClassId) {
        this.selectedFinancingClassId = fcid;
    }

    @State.action.bound
    public setDischargeReason(id: DischargeReasonId) {
        this.selectedDischargeReasonId = id;
    }

    @State.action.bound
    public setAdmissionReason(id: AdmissionReasonId) {
        this.selectedAdmissionReasonId = id;
    }

    @State.action.bound
    public setLaterality(id: LateralityId) {
        this.selectedLateralityId = id;
    }

    @State.action.bound
    public setName2() {
        this.value = { SampleName: "Name2", SampleNumber: 4 };
    }
    @State.action.bound
    public setRequired(newValue: boolean) {
        this.isRequired = newValue;
    }

    @State.action.bound
    public setCompact(newValue: boolean) {
        this.isCompact = newValue;
    }
    @State.action.bound
    public addError() {
        this.validationResults = [
            {
                entityName: "test",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "testValue",
                        severity: "error",
                        message: "This filed is required. Please fill it with proper information.",
                    }
                ]
            }
        ];
    }

    @State.action.bound
    public removeValidation() {
        this.validationResults = null;
    }

    @State.bound
    @State.action
    public addWarning() {
        this.validationResults = [
            {
                entityName: "test",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "testValue",
                        severity: "warning",
                        message: "This filed is required. Please fill it with proper information.",
                    }
                ]
            }
        ];
    }

}

interface ISampleItem {
    SampleName: string;
    SampleNumber: number;
}
