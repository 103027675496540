
import createPanelStoreProvider from "@Toolkit/CommonWeb/PanelStore/PanelStoreProvider";
import FrontendActionsExampleStore from "./FrontendActionsExampleStore";

const { 
    ContextComponent: FrontendActionsExampleStoreContext, 
    StoreProvider: FrontendActionsExampleStoreProvider, 
    useStore: useFrontendActionsExampleStore,
    provideStore: provideFrontendActionsExampleStore,
} = createPanelStoreProvider(FrontendActionsExampleStore);

export {
    FrontendActionsExampleStoreContext,
    FrontendActionsExampleStoreProvider,
    useFrontendActionsExampleStore,
    provideFrontendActionsExampleStore
};