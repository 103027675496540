import React from "react";
import * as Ui from "@CommonControls";

export default class InfoButtonExampleBox extends React.Component {
    public szoveg = "Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text Tooltip text";
    public more = "Részletek";

    public render() {
        return (
            <Ui.PageBox title="InfoButton">
                <Ui.PageBox.Body>
                    <Ui.Flex>
                        <Ui.Flex.Item>
                            Test text <Ui.InfoButton tooltipContent={this.szoveg} moreLabel={this.more} tooltipMaxWidth="400px"/>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.PageBox.Body>
            </Ui.PageBox>
                );
            }
}