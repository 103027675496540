import React from "react";
import * as Ui from "@CommonControls";
import GroupBoxExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/GroupBoxExampleStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class GroupBoxExampleBox extends React.Component {

    private store: GroupBoxExampleStore = new GroupBoxExampleStore();

    public render() {
        return (
            <Ui.PageBox title="GroupBox">
                <Ui.Flex spacing="regular">
                    <Ui.Flex.Item xs={3}>
                        <Ui.GroupBox
                            title="Controlled Primary GroupBox"
                            visualStyle="primary"
                            isOpen={this.store.isOpen}
                            onOpenStateChanged={this.store.setIsOpen}
                            isCollapsible>
                            <Ui.TextBox
                                label="Normal"
                                value="Valami"
                                placeholder="Hint"
                                automationId=""
                            />
                        </Ui.GroupBox>
                        <Ui.Button automationId="" text={this.store.isOpen && this.store.isOpen === true ? "close" : "open"} onClick={this.store.setIsOpen} />
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3}>
                        <Ui.GroupBox title="Uncontrolled Standard GroupBox" isCollapsible hasBorder isOpen={this.store.isOpen}
                            onOpenStateChanged={this.store.setIsOpen}>
                            <Ui.TextBox
                                label="Normal"
                                value="Valami"
                                placeholder="Hint"
                                automationId=""
                            />
                        </Ui.GroupBox>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3}>
                        <Ui.GroupBox title="Uncontrolled Standard GroupBox" hasBorder>
                            <Ui.TextBox
                                label="Normal"
                                value="Valami"
                                placeholder="Hint"
                                automationId=""
                            />
                        </Ui.GroupBox>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3}>
                        <Ui.GroupBox title="Uncontrolled Standard GroupBox" hasBorder>
                            <Ui.TextBox
                                label="Normal"
                                value="Valami"
                                placeholder="Hint"
                                automationId=""
                            />
                        </Ui.GroupBox>
                    </Ui.Flex.Item>
                </Ui.Flex>
                <Ui.Flex spacing="regular">
                    <Ui.Flex.Item xs={3}>
                        <Ui.GroupBox title="Uncontrolled Standard GroupBox borderless" isCollapsible isOpen={this.store.isOpen}
                            onOpenStateChanged={this.store.setIsOpen}>
                            <Ui.TextBox
                                label="Normal"
                                value="Valami"
                                placeholder="Hint"
                                automationId=""
                            />
                        </Ui.GroupBox>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={3}>
                        <Ui.GroupBox title="Uncontrolled Standard GroupBox borderless">
                            <Ui.TextBox
                                label="Normal"
                                value="Valami"
                                placeholder="Hint"
                                automationId=""
                            />
                        </Ui.GroupBox>
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.PageBox>
        );
    }
}