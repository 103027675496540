import React from "react";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import _ from "@HisPlatform/Common/Lodash";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import { FinancedServiceCodeSelectorItem } from "@HisPlatform/Components/HisPlatformControls/FinancedServiceCodeSelector/FinancedServiceCodeSelectorItem";

@State.observer
export default class FinancedServiceCodeSelectorExampleBox extends React.Component {
    @State.observable.ref public financedService: FinancedServiceCodeSelectorItem = null;

    @State.action.bound
    public setFinancedService(financedService: FinancedServiceCodeSelectorItem, validOn?: LocalDate) {
        this.financedService = financedService;
    }

    public render() {
        return (
            <Ui.PageBox title="CodeSelector">
                <Ui.PageBox.Body>
                    <p>Selector component for reference data (FinancedService). Does a server-side search for codes.</p>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6}>
                            <h3>FinancedServiceCodeSelector with an example entity</h3>
                            <HisUi.FinancedServiceCodeSelector
                                onChange={this.setFinancedService}
                                value={this.financedService}
                                automationId=""
                            />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}
