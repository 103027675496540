import React from "react";
import * as Ui from "@CommonControls";
import * as DiContainer from "@DiContainer";
import SourceCode from "./SourceCode";
import NumberBoxExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/NumberBoxExampleStore";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class NumberBoxExampleBox extends React.Component {

    @DiContainer.lazyResolve("NumberBoxExampleStore") private store: NumberBoxExampleStore;

    public render() {
        return (
            <Ui.PageBox title="Number box">
                <ValidationBoundary validationResults={this.store.validationResults} entityTypeName="test">
                    <Ui.NumberBox value={this.store.simpleValue}
                        onChange={this.store.changeSimpleValue}
                        disabled={this.store.isDisabled}
                        propertyIdentifier="testValue"
                        onlyNumbersAllowedMessage="Csak számot lehet beírni!"
                        label="My value"
                    />
                    <pre>Value: {this.store.simpleValue}</pre>
                </ValidationBoundary>
                <ValidationBoundary validationResults={this.store.validationResults} entityTypeName="test">
                    <Ui.NumberBox value={this.store.simpleValue}
                        onChange={this.store.changeSimpleValue}
                        disabled={this.store.isDisabled}
                        propertyIdentifier="testValue"
                        onlyNumbersAllowedMessage="Csak számot lehet beírni!"
                        label="My value"
                        required
                    />
                    <pre>Value: {this.store.simpleValue}</pre>
                </ValidationBoundary>

                <Ui.Button automationId="" onClick={this.store.removeValidation}>Remove validation</Ui.Button>
                <Ui.Button automationId="" onClick={this.store.addError}>Add validation error</Ui.Button>
                <Ui.Button automationId="" onClick={this.store.addWarning}>Add validation warning</Ui.Button>
                <Ui.Button automationId="" onClick={this.store.setDisable}>{this.store.isDisabled ? "Enable" : "Disable"}</Ui.Button>

                <Ui.SingleAccordion title="Source">
                    <SourceCode language="html">
                        {`
<Ui.NumberBox value={this.store.simpleValue} onChange={this.store.changeSimpleValue} />
`}
                    </SourceCode>
                    <SourceCode language="typescript">
                        {`
@State.observable public simpleValue: number = 0;

@State.action.bound
public changeSimpleValue(newValue: number) {
    this.simpleValue = newValue;
}
`}
                    </SourceCode>
                </Ui.SingleAccordion>

                <hr />

                <h3>Validatable value</h3>

                <label>My value:</label>
                {/* <Ui.NumberBox value={this.store.validatableValue} onChange={this.store.changeValidatableValue} />
                <pre>Value: {this.store.validatableValue.value}</pre>
                <Ui.Button onClick={this.store.addError}>Add validation error</Ui.Button>
                <Ui.Button onClick={this.store.addWarning}>Add validation warning</Ui.Button> */}

                <Ui.SingleAccordion title="Source">
                    <SourceCode language="html">
                        {`
<Ui.NumberBox value={this.store.validatableValue} onChange={this.store.changeValidatableValue} />
`}
                    </SourceCode>
                    <SourceCode language="typescript">
                        {`
public validatableValue = new ValidatableProperty<number>(0);

@State.action.bound
public changeValidatableValue(newValue: number) {
    this.validatableValue.value = newValue;
}
`}
                    </SourceCode>

                </Ui.SingleAccordion>
                <hr />

                <h3>Null value</h3>
                <label>My value (delete the numbers):</label>
                <Ui.NumberBox value={this.store.notNullValue} onChange={this.store.changeNotNullValue} nullValue={0} />
                <pre>Value: {this.store.notNullValue}</pre>

                <Ui.SingleAccordion title="Source">
                    <SourceCode language="html">
                        {`
<Ui.NumberBox value={this.store.notNullValue} onChange={this.store.changeNotNullValue} nullValue={0} />
`}
                    </SourceCode>

                    <SourceCode language="typescript">
                        {`
@State.observable public notNullValue: number = 648;

@State.action.bound
public changeNotNullValue(newValue: number) {
    this.notNullValue = newValue;
}
`}
                    </SourceCode>
                </Ui.SingleAccordion>
            </Ui.PageBox>
        );
    }
}
