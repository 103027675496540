import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class ModalExampleStore {
    @State.observable public isOpen = false;

    @State.action.bound
    public openModal() {
        this.isOpen = true;
    }

    @State.action.bound
    public closeModal() {
        this.isOpen = false;
    }

    @State.action.bound
    public messageClicked() {
        alert("Message");
    }

    @State.action.bound
    public uncontrolledCloseClicked(closeModal: () => void) {
        alert("Closing uncontrolled modal");
        closeModal();
    }
}
