import React from "react";
import * as Ui from "@CommonControls";

export default class InfoBoxExampleBox extends React.Component {
    public text = "InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text InfoBox text";

    public render() {
        return (
            <Ui.PageBox title="InfoBox">
                <Ui.PageBox.Body>
                    <Ui.Flex>
                        <Ui.Flex.Item>
                            <Ui.InfoBox iconName="info_with_circle" iconVisualStyle="primary">{this.text}</Ui.InfoBox>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}