import { GroupBox, MoneyLabel, PageBox } from "@CommonControls";
import MoneyBox from "@CommonControls/MoneyBox/MoneyBox";
import Money from "@Toolkit/CommonWeb/Model/Money";
import React from "react";

export default function MoneyExampleBox() {

    const [ value, setValue ] = React.useState(0);

    return (
        <PageBox title="Money">
            <GroupBox title="Money label">
                <MoneyLabel value={new Money(50000, "HUF")} />
            </GroupBox>

            <GroupBox title="MoneyBox">
                <MoneyBox automationId="yo" value={value} onChange={setValue} currency="HUF" />
            </GroupBox>
        </PageBox>
    );
}