import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class ContextMenuExampleStore {
    
    @State.observable public isOpened: boolean = false;

    @State.action.bound
    public onCloseHandler() { 
        this.isOpened = false;               
    }

    @State.action.bound
    public onToggleHandler() {
        this.isOpened = !this.isOpened;
    }

    @State.action.bound
    public onContextMenuClick(e: any) {
        this.isOpened = true;
    }
}