import Di from "@Di";
import WorkflowStepStore from "@CommonControls/WorkflowStepsIndicator/WorkflowStepStore";
import { WorkflowValidation } from "@CommonControls/WorkflowStepsIndicator/WorkflowValidation";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class WorkflowStepsIndicatorExampleStore {
    public steps: WorkflowStepStore[] = [
        new WorkflowStepStore(0, "Első lépés", false, WorkflowValidation.Correct),
        new WorkflowStepStore(1, "Második lépés", false, WorkflowValidation.InCorrect),
        new WorkflowStepStore(2, "Harmadik lépés", false, WorkflowValidation.NotValidated),
        new WorkflowStepStore(3, "Negyedik lépés", false, WorkflowValidation.NotValidated),
        new WorkflowStepStore(4, "Utolsó lépés", true, WorkflowValidation.NotValidated)
    ];

    @State.observable.ref public selectedStep: WorkflowStepStore = this.steps[2];

    @State.bound
    @State.action
    public onStepSelected(selected: WorkflowStepStore) {
        this.selectedStep = selected;
    }
}