import React from "react";
import * as Ui from "@CommonControls";
import * as DiContainer from "@DiContainer";
import SourceCode from "./SourceCode";
import TabControlExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/TabControlExampleStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ValidationBoundary from "@Toolkit/ReactClient/Components/ValidationBoundary/ValidationBoundary";

@State.observer
export default class TabControlExampleBox extends React.Component {
    @DiContainer.lazyResolve("TabControlExampleStore") private store: TabControlExampleStore;
    public render() {
        return (
            <Ui.PageBox title="Tab control">
                <Ui.PageBox.Body>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6}>
                            <p>In interface design, a tabbed document interface (TDI) or Tab is a graphical control element that allows multiple documents or panels to be contained within a single window, using tabs as a navigational widget for switching between sets of documents.</p>
                            <h3>Uncontrolled tab</h3>
                            <Ui.Tab>
                                <Ui.TabPane title="Tab1" automationId="Tab1">
                                    <h1>First tab content</h1>
                                    <label>Tab control...</label>
                                </Ui.TabPane>
                                <Ui.TabPane title="Tab2" automationId="Tab2">
                                    <h1>Second tab content</h1>
                                    <label>...looks sooo...</label>
                                </Ui.TabPane>
                                <Ui.TabPane title="Tab3" automationId="Tab3">
                                    <h1>Third tab content</h1>
                                    <label>...pretty!</label>
                                </Ui.TabPane>
                            </Ui.Tab>
                            <h3>Uncontrolled tab with default active index</h3>
                            <Ui.Tab defaultActiveIndex={1}>
                                <Ui.TabPane title="Tab1" automationId="Tab1">
                                    <h1>First tab content</h1>
                                    <label>Tab1</label>
                                </Ui.TabPane>
                                <Ui.TabPane title="Tab2" automationId="Tab2">
                                    <h1>Default active tab</h1>
                                    <label>This is the default active tab!</label>
                                </Ui.TabPane>
                                <Ui.TabPane title="Tab3" automationId="Tab3">
                                    <h1>Third tab content</h1>
                                    <label>Tab3</label>
                                </Ui.TabPane>
                            </Ui.Tab>
                            <h3>Controlled tab</h3>
                            <Ui.Button text="select tab1" onClick={this.store.selectTab1} automationId="" />
                            <Ui.Button text="select tab2" onClick={this.store.selectTab2} automationId="" />
                            <Ui.Button text="select tab3" onClick={this.store.selectTab3} automationId="" />
                            <Ui.Tab activeIndex={this.store.activeIndex} onSelectedIndexChange={this.store.setIndex}>
                                <Ui.TabPane title="Tab1" automationId="Tab1">
                                    <h1>First tab content</h1>
                                    <label>Tab1</label>
                                </Ui.TabPane>
                                <Ui.TabPane title="Tab2" automationId="Tab2">
                                    <h1>Second tab content</h1>
                                    <label>Tab2</label>
                                </Ui.TabPane>
                                <Ui.TabPane title="Tab3" automationId="Tab3">
                                    <h1>Third tab content</h1>
                                    <label>Tab3</label>
                                </Ui.TabPane>
                            </Ui.Tab>
                            <h3>Uncontrolled tab with validation</h3>
                            <ValidationBoundary validationResults={this.store.validationResults} entityTypeName="test">
                                <Ui.Button onClick={this.store.addValidationResults} automationId="">Add validation results</Ui.Button>
                                <Ui.Button onClick={this.store.removeValidationResults} automationId="">Remove validation results</Ui.Button>
                                <Ui.Tab>
                                    <Ui.TabPane
                                        title="TabA"
                                        automationId="TabA"
                                        propertyPathRegexPattern="(tabA)">
                                        <h1>First tab content</h1>
                                        <label>Tab control...</label>

                                    </Ui.TabPane>
                                    <Ui.TabPane
                                        title="TabB"
                                        automationId="TabB"
                                        propertyPathRegexPattern="(tabB)">
                                        <h1>Second tab content</h1>
                                        <label>...looks sooo...</label>
                                    </Ui.TabPane>
                                    <Ui.TabPane
                                        title="TabC"
                                        automationId="TabC"
                                        propertyPathRegexPattern="(tabC)">
                                        <h1>Third tab content</h1>
                                        <label>...pretty!</label>
                                    </Ui.TabPane>
                                </Ui.Tab>
                            </ValidationBoundary>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            <h3>Page</h3>
                            <SourceCode language="html">
                                {`<Ui.Tab>
<Ui.TabPane title="Tab1">
    <h1>First tab content</h1>
    <label>Tab control...</label>
</Ui.TabPane>
<Ui.TabPane title="Tab2">
    <h1>Second tab content</h1>
    <label>...looks sooo...</label>
</Ui.TabPane>
<Ui.TabPane title="Tab3">
    <h1>Third tab content</h1>
    <label>...pretty!</label>
</Ui.TabPane>
</Ui.Tab>

<h3>Tab with default active index</h3>
<Ui.Tab defaultActiveIndex={1}>
<Ui.TabPane title="Tab1">
    <h1>First tab content</h1>
    <label>Tab control...</label>
</Ui.TabPane>
<Ui.TabPane title="Tab2">
    <h1>Default active tab</h1>
    <label>This is the default active tab!</label>
</Ui.TabPane>
<Ui.TabPane title="Tab3">
    <h1>Third tab content</h1>
    <label>...pretty!</label>
</Ui.TabPane>
</Ui.Tab>

<h3>Controlled tab</h3>
<Ui.Button text="select tab1" onClick={this.store.selectTab1} />
<Ui.Button text="select tab2" onClick={this.store.selectTab2} />
<Ui.Button text="select tab3" onClick={this.store.selectTab3} />
<Ui.Tab activeIndex={this.store.activeIndex} onSelectedIndexChange={this.store.setIndex}>
    <Ui.TabPane title="Tab1">
        <h1>First tab content</h1>
        <label>Tab1</label>
    </Ui.TabPane>
    <Ui.TabPane title="Tab2">
        <h1>Second tab content</h1>
        <label>Tab2</label>
    </Ui.TabPane>
    <Ui.TabPane title="Tab3">
        <h1>Third tab content</h1>
        <label>Tab3</label>
    </Ui.TabPane>
</Ui.Tab>

<h3>Uncontrolled tab with validation</h3>
<ValidationBoundary validationResults={this.store.validationResults} entityTypeName="test">
    <Ui.Button onClick={this.store.addValidationResults}>Add validation results</Ui.Button>
    <Ui.Button onClick={this.store.removeValidationResults}>Remove validation results</Ui.Button>
    <Ui.Tab>
        <Ui.TabPane
            title="TabA"
            propertyPathRegexPattern="(tabA)">
            <h1>First tab content</h1>
            <label>Tab control...</label>

        </Ui.TabPane>
        <Ui.TabPane
            title="TabB"
            propertyPathRegexPattern="(tabB)">
            <h1>Second tab content</h1>
            <label>...looks sooo...</label>
        </Ui.TabPane>
        <Ui.TabPane
            title="TabC"
            propertyPathRegexPattern="(tabC)">
            <h1>Third tab content</h1>
            <label>...pretty!</label>
        </Ui.TabPane>
    </Ui.Tab>
</ValidationBoundary>
`}
                            </SourceCode>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}
