import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { ITableFrameColumn } from "@CommonControls/TreeGrid/TableFrame";
import TreeGridDefaultFooter from "@CommonControls/TreeGrid/TreeGridDefaultFooter";
import ITreeNodeStore from "@CommonControls/TreeGrid/ITreeNodeStore";

let index = 0;

class NodeStore implements ITreeNodeStore {
    @State.observable.ref public key?: string = null;
    @State.observable.ref public displayValue: React.ReactNode = null;
    @State.observable.ref public children?: ITreeNodeStore[] = null;
    @State.observable.ref public isOpen?: boolean = null;
    @State.observable.ref public isActive?: boolean = null;
    @State.observable.ref public isLoading?: boolean = null;
    @State.observable.ref public isChecked?: boolean = false;
    @State.observable.ref public filterableValue?: string = "";

    constructor(filterableValue: string, displayValue: React.ReactNode, children?: ITreeNodeStore[]) {
        this.filterableValue = filterableValue;
        this.displayValue = displayValue;
        this.children = children;
        this.key = `${index++}`;
    }
}


@State.observer
export default class TreeGridExampleBox extends React.Component {

    @State.observable.ref public filterValue: string = "";

    @State.action.bound
    public onFilterValueChange(newValue: string) {
        this.filterValue = newValue;
    }

    private data1: ITreeNodeStore[] = [
        new NodeStore("Root 1", ["Root 1", "Prop 2", "Prop 3"],
            [
                new NodeStore("Child 1", [<>Child 1 with other elements <Ui.Icon iconName="cog" /></>, "Prop 2", "Prop 3"]),
                new NodeStore("Child 2", ["Child 2", "Prop 2", "Prop 3"], [
                    new NodeStore("Child 3", [<>Child 3 <Ui.Badge shape="pill" size="small">Yeah</Ui.Badge></>, "Prop 2", "Prop 3"]),
                    new NodeStore("Child 4", ["Child 4", "Prop 2", "Prop 3"]),
                    new NodeStore("Child 5", ["Child 5", "Prop 2", "Prop 3"], [
                        new NodeStore("Child 6", ["Child 6", "Prop 2", "Prop 3"])
                    ]),
                ]),
            ]
        ),
        new NodeStore("Root 2", ["Root 2", "Prop 2", "Prop 3"]),
        new NodeStore("Root 3", ["Root 3", "Prop 2", "Prop 3"], [
            new NodeStore("Child 7", ["Child 7", "Prop 2", "Prop 3"]),
            new NodeStore("Child 8", ["Child 8", "Prop 2", "Prop 3"]),
        ]),
    ];

    private data2: ITreeNodeStore[] = [
        new NodeStore("Root 1", ["Root 1", "Prop 2", "Prop 3"],
            [
                new NodeStore("Child 1", [<>Child 1 with other elements <Ui.Icon iconName="cog" /></>, "Prop 2", "Prop 3"]),
                new NodeStore("Child 2", ["Child 2", "Prop 2", "Prop 3"], [
                    new NodeStore("Child 3", [<>Child 3 <Ui.Badge shape="pill" size="small">Yeah</Ui.Badge></>, "Prop 2", "Prop 3"]),
                    new NodeStore("Child 4", ["Child 4", "Prop 2", "Prop 3"]),
                    new NodeStore("Child 5", ["Child 5", "Prop 2", "Prop 3"], [
                        new NodeStore("Child 6", ["Child 6", "Prop 2", "Prop 3"])
                    ]),
                ]),
            ]
        ),
        new NodeStore("Root 2", ["Root 2", "Prop 2", "Prop 3"]),
        new NodeStore("Root 3", ["Root 3", "Prop 2", "Prop 3"], [
            new NodeStore("Child 7", ["Child 7", "Prop 2", "Prop 3"]),
            new NodeStore("Child 8", ["Child 8", "Prop 2", "Prop 3"]),
        ]),
    ];

    private data3: ITreeNodeStore[] = [
        new NodeStore("Root 1", [this.getHighlightableLabel("Root1"), "Prop 2", "Prop 3"],
            [
                new NodeStore("Child 1", [<> {this.getHighlightableLabel("Child 1 with other elements")} <Ui.Icon iconName="cog" /></>, "Prop 2", "Prop 3"]),
                new NodeStore("Child 2", [this.getHighlightableLabel("Child 2"), "Prop 2", "Prop 3"], [
                    new NodeStore("Child 3", [<>{this.getHighlightableLabel("Child 3")}<Ui.Badge shape="pill" size="small">Yeah</Ui.Badge></>, "Prop 2", "Prop 3"]),
                    new NodeStore("Child 4", [this.getHighlightableLabel("Child 4"), "Prop 2", "Prop 3"]),
                    new NodeStore("Child 5", [this.getHighlightableLabel("Child 5"), "Prop 2", "Prop 3"], [
                        new NodeStore("Child 6", [this.getHighlightableLabel("Child 6"), "Prop 2", "Prop 3"])
                    ]),
                ]),
            ]
        ),
        new NodeStore("Root 2", [this.getHighlightableLabel("Root2"), "Prop 2", "Prop 3"]),
        new NodeStore("Root 3", [this.getHighlightableLabel("Root3"), "Prop 2", "Prop 3"], [
            new NodeStore("Child 7", [this.getHighlightableLabel("Child 7"), "Prop 2", "Prop 3"]),
            new NodeStore("Child 8", [this.getHighlightableLabel("Child 8"), "Prop 2", "Prop 3"]),
        ]),
    ];

    private getHighlightableLabel(label: string) {
        return (
            <State.Observer key={label}>
                {() => <Ui.HighlightableLabel label={label} filterValue={this.filterValue} />}
            </State.Observer>
        );
    }

    private cols: ITableFrameColumn[] = [
        { width: 50, widthMode: "percent", title: "Név", displayValueGetter: this.getDisplay },
        { width: 25, widthMode: "percent", title: "Prop1", editValueGetter: this.getEdit },
        { width: 25, widthMode: "percent", title: "Prop2" }
    ];

    private cols2: ITableFrameColumn[] = [
        { width: 50, widthMode: "percent", title: "Név" },
        { width: 25, widthMode: "percent", title: "Prop1" },
        { width: 25, widthMode: "percent", title: "Prop2" }
    ];

    private cols3: ITableFrameColumn[] = [
        { width: 50, widthMode: "percent", title: "Név" },
        { width: 25, widthMode: "percent", title: "Prop1" },
        { width: 25, widthMode: "percent", title: "Prop2" }
    ];

    private getDisplay(entry: any, setEditedNode: () => void): React.ReactNode {
        console.log(entry);
        return (
            <Ui.Button onClick={setEditedNode} text={"asdasd"} automationId="" />
        );
    }

    private getEdit(entry: any): React.ReactNode {
        console.log(entry);
        let value = "";
        const valueFUnc = (newValue: string) => {
            value = newValue;
        };
        return (
            <Ui.TextBox value={value} onChange={valueFUnc} automationId="" />
        );
    }

    public render() {
        return (
            <Ui.PageBox title="TreeGrid">
                <Ui.PageBox.Body>
                    <Ui.GroupBox title="Simple TreeGrid" hasBorder>
                        <Ui.TreeGrid columns={this.cols} data={this.data1} footer={<TreeGridDefaultFooter />} hasBackButton backButtonText="Click me" />
                    </Ui.GroupBox>

                    <Ui.GroupBox title="TreeGrid with MultiSelect" hasBorder>
                        <Ui.TreeGrid
                            multiSelect
                            columns={this.cols2}
                            data={this.data2}
                            footer={<TreeGridDefaultFooter />}
                            hasBackButton
                            backButtonText="Click me" />
                    </Ui.GroupBox>

                    <Ui.GroupBox title="TreeGrid with MultiSelect and Filter" hasBorder>
                        <Ui.TreeGrid
                            multiSelect
                            filterable
                            onFilterValueChange={this.onFilterValueChange}
                            columns={this.cols3}
                            data={this.data3}
                            footer={<TreeGridDefaultFooter />}
                            hasBackButton
                            backButtonText="Click me" />
                    </Ui.GroupBox>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}
