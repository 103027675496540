import React from "react";
import * as Ui from "@CommonControls";
import * as DiContainer from "@DiContainer";
import SourceCode from "./SourceCode";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { CheckBoxGroupFactory } from "@CommonControls/CheckBox/CheckBoxGroup";
import CheckBoxGroupExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/CheckBoxGroupExampleStore";
import CheckBoxGroupItemStore from "@CommonControls/CheckBox/CheckBoxGroupItemStore";


const StringCheckBoxGroup = CheckBoxGroupFactory<string>();

@State.observer
export default class CheckBoxGroupExampleBox extends React.Component {

    @DiContainer.lazyResolve("CheckBoxGroupExampleStore") private store: CheckBoxGroupExampleStore;

    @State.bound
    private itemCanBeNull(item: string) {
        return true;
    }
    
    private renderValues(values: Array<CheckBoxGroupItemStore<string>>) {
        let result = "Store:";
        values.forEach(v => {
            result = result.concat("\n");
            result = result.concat(v.value);
            result = result.concat(": ");
            switch (v.selectionState) {
                case true:
                    result = result.concat("true");
                    break;
                case false:
                    result = result.concat("false");
                    break;
                case null:
                    result = result.concat("null");
                    break;
                default:
                    result = result.concat("something else: " + (v.selectionState as any).toString());
            }
        });
        return result;
    }

    public render() {
        return (
            <Ui.PageBox title="Check box group">
                <Ui.PageBox.Body>
                    <p>A check box group is a GUI widget that permits the user to set a boolean or nullable boolean selection state on items of a collection, thus</p>
                    <p>a) select zero or more items, if null item selection state is denied,</p>
                    <p>b) or set 'true/false/don't care' state on items, if null item selection state is permitted (e.g. boolean type search/filter parameters).</p>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <h3>Check box group</h3>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2} xsOffset={2}>
                            <b>Three states:</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2} >
                            <b>Two states:</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6} >
                            <b>Dark mode</b>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2} xsOffset={2}>
                            <StringCheckBoxGroup
                                store={this.store.nullableStore}
                                onChange={this.store.changeNullableValue}
                                itemCanBeNull={this.itemCanBeNull}
                                label="Three states"
                                direction="column"
                                displayType="check"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.nullableStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Two states"
                                direction="column"
                                displayType="check"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.nullableStore}
                                onChange={this.store.changeNullableValue}
                                itemCanBeNull={this.itemCanBeNull}
                                label="Dark mode"
                                direction="column"
                                displayType="check"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                                visualMode="dark"
                            />
                            <pre>{this.renderValues(this.store.nullableStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={4}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Dark mode"
                                direction="row"
                                displayType="check"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                                visualMode="dark"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex><br />
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Align items</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.nullableStore}
                                onChange={this.store.changeNullableValue}
                                itemCanBeNull={this.itemCanBeNull}
                                label="Align items: left"
                                direction="column"
                                displayType="check"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="normal"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.nullableStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.nullableStore}
                                onChange={this.store.changeNullableValue}
                                itemCanBeNull={this.itemCanBeNull}
                                disabled={true}
                                label="Align items: center, disabled"
                                direction="column"
                                displayType="check"
                                alignItems="center"
                                itemLabelPosition="right"
                                verticalAlign="normal"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.nullableStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                disabled={true}
                                label="Align items: center, disabled"
                                direction="column"
                                displayType="check"
                                alignItems="center"
                                itemLabelPosition="left"
                                verticalAlign="normal"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Align items: right"
                                direction="column"
                                displayType="check"
                                alignItems="right"
                                itemLabelPosition="left"
                                verticalAlign="normal"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex><br />
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Padding - column</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="No padding"
                                direction="column"
                                displayType="check"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                disabled={true}
                                label="Container padding"
                                direction="column"
                                displayType="check"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="normal"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Item padding"
                                direction="column"
                                displayType="check"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="normal"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Container + item padding"
                                direction="column"
                                displayType="check"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="normal"
                                itemVerticalSpacing="normal"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Align items - row</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Align items left"
                                direction="row"
                                displayType="check"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                disabled={true}
                                label="Align items center"
                                direction="row"
                                displayType="check"
                                alignItems="center"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Align items right"
                                direction="row"
                                displayType="check"
                                alignItems="right"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Justify items - row</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Space evenly"
                                direction="row"
                                displayType="check"
                                justifyItems="evenly"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                disabled={true}
                                label="Space around"
                                direction="row"
                                displayType="check"
                                justifyItems="around"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Space between"
                                direction="row"
                                displayType="check"
                                justifyItems="between"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Vertical align without label</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <Ui.TextBox
                                iconName="filter"
                                label="Row"
                                placeholder="Hint"
                                propertyIdentifier="testValue"
                                automationId=""
                                />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                direction="row"
                                displayType="check"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="inlineInput"
                                itemVerticalSpacing="noPadding"
                                />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <h3>Switch </h3>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Align items</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.nullableStore}
                                onChange={this.store.changeNullableValue}
                                itemCanBeNull={this.itemCanBeNull}
                                label="Align items: left"
                                direction="column"
                                displayType="switch"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="normal"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.nullableStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.nullableStore}
                                onChange={this.store.changeNullableValue}
                                itemCanBeNull={this.itemCanBeNull}
                                disabled={true}
                                label="Align items: center, disabled"
                                direction="column"
                                displayType="switch"
                                alignItems="center"
                                itemLabelPosition="right"
                                verticalAlign="normal"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.nullableStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                disabled={true}
                                label="Align items: center, disabled"
                                direction="column"
                                displayType="switch"
                                alignItems="center"
                                itemLabelPosition="left"
                                verticalAlign="normal"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Align items: right"
                                direction="column"
                                displayType="switch"
                                alignItems="right"
                                itemLabelPosition="left"
                                verticalAlign="normal"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex><br />
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Padding - column</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="No padding"
                                direction="column"
                                displayType="switch"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                disabled={true}
                                label="Container padding"
                                direction="column"
                                displayType="switch"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="normal"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Item padding"
                                direction="column"
                                displayType="switch"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="normal"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Container + item padding"
                                direction="column"
                                displayType="switch"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="normal"
                                itemVerticalSpacing="normal"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Align items - row</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Align items left"
                                direction="row"
                                displayType="switch"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                disabled={true}
                                label="Align items center"
                                direction="row"
                                displayType="switch"
                                alignItems="center"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Align items right"
                                direction="row"
                                displayType="switch"
                                alignItems="right"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Justify items - row</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Space evenly"
                                direction="row"
                                displayType="switch"
                                justifyItems="evenly"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                disabled={true}
                                label="Space around"
                                direction="row"
                                displayType="switch"
                                justifyItems="around"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Space between"
                                direction="row"
                                displayType="switch"
                                justifyItems="between"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Vertical align without label</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <Ui.TextBox
                                iconName="filter"
                                label="Row"
                                placeholder="Hint"
                                propertyIdentifier="testValue"
                                automationId=""
                                />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                direction="row"
                                displayType="switch"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="inlineInput"
                                itemVerticalSpacing="noPadding"
                                />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <h3>Toggle Button </h3>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Align items</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.nullableStore}
                                onChange={this.store.changeNullableValue}
                                itemCanBeNull={this.itemCanBeNull}
                                label="Align items: left"
                                direction="column"
                                displayType="toggle-button"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="normal"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.nullableStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.nullableStore}
                                onChange={this.store.changeNullableValue}
                                itemCanBeNull={this.itemCanBeNull}
                                disabled={true}
                                label="Align items: center, disabled"
                                direction="column"
                                displayType="toggle-button"
                                alignItems="center"
                                itemLabelPosition="right"
                                verticalAlign="normal"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.nullableStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                disabled={true}
                                label="Align items: center, disabled"
                                direction="column"
                                displayType="toggle-button"
                                alignItems="center"
                                itemLabelPosition="left"
                                verticalAlign="normal"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Align items: right"
                                direction="column"
                                displayType="toggle-button"
                                alignItems="right"
                                itemLabelPosition="left"
                                verticalAlign="normal"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex><br />
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Padding - column</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="No padding"
                                direction="column"
                                displayType="toggle-button"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                disabled={true}
                                label="Container padding"
                                direction="column"
                                displayType="toggle-button"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="normal"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Item padding"
                                direction="column"
                                displayType="toggle-button"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="normal"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Container + item padding"
                                direction="column"
                                displayType="toggle-button"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="normal"
                                itemVerticalSpacing="normal"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Align items - row</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Align items left"
                                direction="row"
                                displayType="toggle-button"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                disabled={true}
                                label="Align items center"
                                direction="row"
                                displayType="toggle-button"
                                alignItems="center"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Align items right"
                                direction="row"
                                displayType="toggle-button"
                                alignItems="right"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Justify items - row</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Space evenly"
                                direction="row"
                                displayType="toggle-button"
                                justifyItems="evenly"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                disabled={true}
                                label="Space around"
                                direction="row"
                                displayType="toggle-button"
                                justifyItems="around"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                label="Space between"
                                direction="row"
                                displayType="toggle-button"
                                justifyItems="between"
                                itemLabelPosition="right"
                                verticalAlign="noPadding"
                                itemVerticalSpacing="noPadding"
                            />
                            <pre>{this.renderValues(this.store.simpleStore.getAll())}</pre>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>Vertical align without label</b>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={2}>
                            <Ui.TextBox
                                iconName="filter"
                                label="Row"
                                placeholder="Hint"
                                propertyIdentifier="testValue" 
                                automationId=""
                                />
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <StringCheckBoxGroup
                                store={this.store.simpleStore}
                                onChange={this.store.changeSimpleValue}
                                direction="row"
                                displayType="toggle-button"
                                alignItems="left"
                                itemLabelPosition="right"
                                verticalAlign="inlineInput"
                                itemVerticalSpacing="noPadding"
                                />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
                            TODO
                            `}
                        </SourceCode>
                        <SourceCode language="typescript">
                            {`
                            TODO
                            `}
                        </SourceCode>
                    </Ui.SingleAccordion>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}