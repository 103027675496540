import Reflection from "@Toolkit/CommonWeb/Reflection/Reflection";
import FrontendActionId from "@Toolkit/ReactClient/ActionProcessing/FrontendActionId";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";

export default class ShowSampleScreenAction extends ShowScreenFrontendActionBase {
    public static id = new FrontendActionId("ShowSampleScreen");

    constructor(
        displayMode: ScreenDisplayMode,
        public readonly someParam: string,
    ) {
        super(ShowSampleScreenAction.id, displayMode);
    }

    public toUrl(): string[] {
        return [ScreenDisplayMode[this.displayMode], this.someParam];
    }

    public static fromUrl(urlParams: string[]): ShowSampleScreenAction {
        return new ShowSampleScreenAction(ScreenDisplayMode[urlParams[0]], urlParams[1]);
    }
}

Reflection.registerType(ShowSampleScreenAction, "ShowSampleScreen", "FrontendAction");