import React from "react";
import * as Ui from "@CommonControls";
import SourceCode from "./SourceCode";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class LoadingIndicatorExampleBox extends React.Component {

    public render() {
        return (
            <Ui.PageBox title="Loading indicator">
                <Ui.PageBox.Body>
                    <h2>Standalone</h2>
                    Large <Ui.LoadingIndicator mode="standalone" size="large" />
                    Medium <Ui.LoadingIndicator mode="standalone" size="medium" />
                    Small <Ui.LoadingIndicator mode="standalone" size="small" />
                    <h2>Parent</h2>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "400px" }}>
                        <div style={{ backgroundColor: "#efefef", width: "100px", height: "100px", position: "relative" }}>
                            Some content.
                            <Ui.LoadingIndicator mode="parent" size="large" />
                        </div>
                        <div style={{ backgroundColor: "#efefef", width: "100px", height: "100px", position: "relative" }}>
                            Some content.
                            <Ui.LoadingIndicator mode="parent" size="medium" />
                        </div>
                        <div style={{ backgroundColor: "#efefef", width: "100px", height: "100px", position: "relative" }}>
                            Some content.
                            <Ui.LoadingIndicator mode="parent" size="small" />
                        </div>
                    </div>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`Large <Ui.LoadingIndicator mode="standalone" size="large" />
Medium <Ui.LoadingIndicator mode="standalone" size="medium" />
Small <Ui.LoadingIndicator mode="standalone" size="small" />
<h2>Parent</h2>
<div style={{ display: "flex", justifyContent: "space-between", width: "400px" }}>
    <div style={{ backgroundColor: "#efefef", width: "100px", height: "100px", position: "relative" }}>
        Some content.
        <Ui.LoadingIndicator mode="parent" size="large" />
    </div>
    <div style={{ backgroundColor: "#efefef", width: "100px", height: "100px", position: "relative" }}>
        Some content.
        <Ui.LoadingIndicator mode="parent" size="medium" />
    </div>
    <div style={{ backgroundColor: "#efefef", width: "100px", height: "100px", position: "relative" }}>
        Some content.
        <Ui.LoadingIndicator mode="parent" size="small" />
    </div>
</div>
`}
                        </SourceCode>

                    </Ui.SingleAccordion>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}