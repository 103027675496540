import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class SettlementTextboxExampleStore {

    @State.observable public settlementName: string;

    @State.action.bound
    public setSettlementName(newValue: string) {
        this.settlementName = newValue;
    }
}