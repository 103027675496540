import PageBox from "@CommonControls/PageBox";
import { RegisterLocalActionProcessor } from "@Toolkit/ReactClient/ActionProcessing/ActionDispatcher";
import React from "react";
import { toastSampleActionProcessorFactory } from "./FrontendActionsExampleStore";
import { provideFrontendActionsExampleStore, useFrontendActionsExampleStore } from "./FrontendActionsExampleStoreProvider";
import { ToastSampleAction } from "./ToastSampleAction";
import ActionBoundButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundButton";
import ShowSampleScreenAction from "./Screens/ShowSampleScreenAction";
import ShowSampleMdScreenAction from "./Screens/ShowSampleMdScreenAction";
import { NotPermittedSampleAction } from "./NotPermittedSampleAction";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";

const toastSampleAction = ActionDescriptor.fromAction(new ToastSampleAction("Hello world!"));
const showFullScreenSampleAction = ActionDescriptor.fromAction(new ShowSampleScreenAction(ScreenDisplayMode.Full, "Hello sample screen!"));
const showFullMdScreenSampleAction = ActionDescriptor.fromAction(new ShowSampleMdScreenAction(ScreenDisplayMode.Full, "Hello sample M-D screen!"));
const notPermittedSampleAction = ActionDescriptor.fromAction(new NotPermittedSampleAction("No!"));

function FrontendActionsExample(props: any) {
    const store = useFrontendActionsExampleStore();

    if (store.loadAsync.isLoading.get()) {
        return <>Loading...</>;
    }

    return (
        <>
            <PageBox title="FrontendActions">
                <PageBox.Body>

                    <h2>Simple actions</h2>
                    <ActionBoundButton actionDescriptor={toastSampleAction} automationId="" text="Toast sample" />
                    <ActionBoundButton actionDescriptor={notPermittedSampleAction} automationId="" text="Not permitted" />

                    <h2>Show screen</h2>
                    <ActionBoundButton actionDescriptor={showFullScreenSampleAction} automationId="" text="Full screen" />
                    <ActionBoundButton actionDescriptor={showFullMdScreenSampleAction} automationId="" text="Full MD screen" />

                </PageBox.Body>
            </PageBox>
            <RegisterLocalActionProcessor actionId={ToastSampleAction.id} actionProcessorFactory={toastSampleActionProcessorFactory} />
        </>
    );
}

export default provideFrontendActionsExampleStore(FrontendActionsExample);