import React from "react";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import {lazyResolve} from "@DiContainer";
import UniversalCodeSelectorExampleStore, {
    FakeEntityCollectionsService,
    ICodeSelectorExampleComplexSearchEntity,
    ICodeSelectorExampleEntity
} from "@HisPlatform/Components/Pages/DeveloperPage/Store/UniversalCodeSelectorExampleStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import {IUniversalCodeSelectorProps} from "@HisPlatformControls/UniversalCodeSelector";
import {wrappedValuesAreEquals} from "@HisPlatform/Common/ValueWrapperHelpers";
import {IOrderingState, IPagingState} from "@CommonControls/DataGrid/IDataGridProps";
import IPagedItems from "@Toolkit/CommonWeb/Model/IPagedItems";
import _ from "@HisPlatform/Common/Lodash";
import IStringEntityId from "@Toolkit/CommonWeb/Model/IStringEntityId";
import ContainerContext from "@Toolkit/ReactClient/Components/DependencyInjection/ContainerContext";
import IContainerService from "@Toolkit/CommonWeb/DependencyInjection/Definition/IContainerService";
import IEntityCollectionsService from "@HisPlatform/Services/Definition/EntityCollectionService/IEntityCollectionsService";
import StaticWebAppResources from "@StaticResources";
import {IComplexSearchItemGroup} from "@HisPlatformControls/UniversalCodeSelector/ComplexSearch/ComplexSearchPanel";
import EntityCollectionOwner from "@HisPlatform/BoundedContexts/Productivity/Api/Personalization/Enum/EntityCollectionOwner.g";

@State.observer
export default class UniversalCodeSelectorExampleBox extends React.Component {

    @lazyResolve("UniversalCodeSelectorExampleStore") private store: UniversalCodeSelectorExampleStore;
    @lazyResolve("IContainerService") private containerService: IContainerService;
    private fakeContainerContext = {
        resolve: this.resolve
    } as IContainerService;

    @State.bound
    private resolve<T>(serviceIdentifier: string): T {
        if (serviceIdentifier === "IEntityCollectionsService") {
            return new FakeEntityCollectionsService() as any;
        }
        return this.containerService.resolve(serviceIdentifier);
    }

    @State.bound
    private getDisplayValueAsync(value: ICodeSelectorExampleEntity) {
        const item = this.getDisplayValue(value);
        return Promise.resolve(item);
    }

    @State.bound
    private getTextValueAsync(value: ICodeSelectorExampleEntity) {
        const item = this.getTextValue(value);
        return Promise.resolve(item);
    }

    @State.bound
    private quickSearchAsync(text: string): Promise<ICodeSelectorExampleEntity[]> {
        const items = this.store.searchItems(text);
        const searchItems = items.map(item => ({id: item.id}));
        return Promise.resolve(searchItems);
    }

    @State.bound
    private complexSearchLoadAsync(filterText: string, paging: IPagingState, ordering: IOrderingState, selectedItems: ICodeSelectorExampleComplexSearchEntity[], group: IComplexSearchItemGroup): Promise<IPagedItems<ICodeSelectorExampleComplexSearchEntity>> {
        this.store.selectedGroup = group;
        const items = filterText ? this.store.searchItems(filterText) : this.store.items;
        const orderedList = _.orderBy(items, i => i.code);
        const startIndex = paging.pageSize * paging.currentPage;
        const endIndex = startIndex + paging.pageSize >= orderedList.length ? orderedList.length : startIndex + paging.pageSize;
        const result = {
            totalCount: orderedList.length,
            items: orderedList.slice(startIndex, endIndex)
        } as IPagedItems<ICodeSelectorExampleComplexSearchEntity>;
        return Promise.resolve(result);
    }

    @State.bound
    private getComplexSearchEntitiesByIds(ids: ICodeSelectorExampleComplexSearchEntity[]) {
        return this.store.allItems.filter(item => ids.some(id => wrappedValuesAreEquals(item.id, id as any)));
    }

    @State.bound
    private onComplexSearchSingleSelect(item: ICodeSelectorExampleComplexSearchEntity) {
        const value = {id: item.id};
        this.store.onChange(value);
    }

    @State.bound
    private onComplexSearchMultiSelect(items: ICodeSelectorExampleComplexSearchEntity[]) {
        const values = items.map(item => ({id: item.id}));
        this.store.onChange(values);
    }

    @State.bound
    private getDisplayValue(item: ICodeSelectorExampleEntity) {
        const entity = this.store.allItems.find(i => wrappedValuesAreEquals(i.id, item.id));
        return <><b>{entity?.code}</b>{` ${entity?.name}`}</>;
    }

    @State.bound
    private getTextValue(item: ICodeSelectorExampleEntity) {
        const entity = this.store.allItems.find(i => wrappedValuesAreEquals(i.id, item.id));
        return `${entity?.code} ${entity?.name}`;
    }

    private getEntityById(id: IStringEntityId): ICodeSelectorExampleEntity {
        return {id: {value: id.value}};
    }

    private getEntitiesByIds(ids: IStringEntityId[]): ICodeSelectorExampleEntity[] {
        return ids.map(item => ({id: {value: item.value}}));
    }

    private get getProps(): IUniversalCodeSelectorProps<ICodeSelectorExampleEntity, ICodeSelectorExampleComplexSearchEntity> {
        return {
            value: this.store.value,
            multiSelect: this.store.isMultiSelect,
            onChange: this.store.onChange,
            getDisplayValueAsync: this.getDisplayValueAsync,
            getTextValueAsync: this.getTextValueAsync,
            onQuickSearchAsync: this.quickSearchAsync,
            hasComplexSearch: this.store.hasComplexSearch,
            complexSearchLoadAsync: this.complexSearchLoadAsync,
            complexSearchItemGroups: this.store.itemGroups,
            complexSearchModalTitle: "TestEntity",
            codeGetter: "code",
            nameGetter: "name",
            getComplexSearchEntitiesByIds: this.getComplexSearchEntitiesByIds,
            onComplexSearchSingleSelect: this.onComplexSearchSingleSelect,
            onComplexSearchMultiSelect: this.onComplexSearchMultiSelect,
            showMenuSections: this.store.showFavoritesAndGroup,
            createNewItemAsync: this.store.showAddNewItem ? this.store.addNewItem : null,
            createNewItemButtonText: "new item",
            createNewItemCodePlaceHolder: StaticWebAppResources.CodeSelector.ComplexSearch.CreateNewItemCodePlaceHolder,
            createNewItemNamePlaceHolder: StaticWebAppResources.CodeSelector.ComplexSearch.CreateNewItemNamePlaceHolder,

            entityName: "ExampleEntity",
            ownerType: EntityCollectionOwner.PointOfCare,
            ownerId: this.store.fakePointOfCareId,
            currentPointOfCareId: this.store.fakePointOfCareId,
            getEntityDisplayValue: this.getDisplayValue,
            getEntityById: this.getEntityById,
            getEntitiesById: this.getEntitiesByIds,
            automationId: ""
        };
    }

    private renderExampleCodeSelector() {
        return (
            <ContainerContext.Provider value={this.fakeContainerContext}>
                <HisUi.UniversalCodeSelector
                    {...this.getProps}
                />
            </ContainerContext.Provider>
        );
    }

    public render() {
        return (
            <Ui.PageBox title="CodeSelector">
                <Ui.PageBox.Body>
                    <p>Selector component for reference data. Does a server-side search for codes. You have to implement
                        UniversalCodeSelector (component: with encapsulation, store: with inheritation) if you want it
                        for a
                        new entity.</p>

                        <Ui.Flex>
                            <Ui.Flex.Item xs={6}>
                                <h3>UniversalCodeSelector with an example entity </h3>
                                {this.renderExampleCodeSelector()}
                                <pre> {"Value: " + (this.store.value ? JSON.stringify(this.store.value, null, "    ") : "null")}</pre>
                            </Ui.Flex.Item>
                            <Ui.Flex.Item xs={6}>
                                <></>
                            </Ui.Flex.Item>
                        </Ui.Flex>

                        <Ui.CheckBox displayMode="switch" label="hasComplexSearch" value={this.store.hasComplexSearch}
                                     onChange={this.store.setHasComplexSearch}
                                     automationId="hasComplexSearchButton" />
                        <Ui.CheckBox displayMode="switch" label="isMultiSelect" value={this.store.isMultiSelect}
                                     onChange={this.store.setIsMultiSelect}
                                     automationId="isMultiSelectCheckBox" />
                        <Ui.CheckBox displayMode="switch" label="showFavoritesAndGroup"
                                     value={this.store.showFavoritesAndGroup}
                                     onChange={this.store.setShowFavoritesAndGroup}
                                     automationId="showFavoritesAndGroupButton" />
                        <Ui.CheckBox displayMode="switch" label="showAddNewItem"
                                     value={this.store.showAddNewItem}
                                     onChange={this.store.setShowAddNewItem}
                                     automationId="showAddNewItemButton" />
                        <Ui.CheckBox displayMode="switch" label="showAdditionalGroups"
                                     value={this.store.showAdditionalGroups}
                                     onChange={this.store.setShowAdditionalGroups}
                                     automationId="showAdditionalGroupsButton" />
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}
