import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import MasterDetailScreen from "@Toolkit/ReactClient/Components/Screen/MasterDetailScreen";
import React, { useMemo, useState, useCallback } from "react";
import ShowSampleMdScreenAction from "./ShowSampleMdScreenAction";
import ShowSampleScreenAction from "./ShowSampleScreenAction";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import { useScreenStateContext } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import { Button } from "@CommonControls";
import ActionBoundButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundButton";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";

interface ISampleMdScreenProps extends IScreenPropsBase<ShowSampleMdScreenAction> {
}

export default function SampleMdScreen(props: ISampleMdScreenProps) {

    const [secondaryEvents, setSecondaryEvents] = useState<string[]>([]);

    const {
        showFullScreenSampleAction,
        showFullMdScreenSampleAction,
        showModalScreenSampleAction,
        showModalMdScreenSampleAction,
        showDetailScreenSampleAction,
        showDetailMdScreenSampleAction
    } = useMemo(() => {
        return {
            showFullScreenSampleAction: ActionDescriptor.fromAction(new ShowSampleScreenAction(ScreenDisplayMode.Full, props.action.someParam + "😊")),
            showFullMdScreenSampleAction: ActionDescriptor.fromAction(new ShowSampleMdScreenAction(ScreenDisplayMode.Full, props.action.someParam + "😊")),
            showModalScreenSampleAction: ActionDescriptor.fromAction(new ShowSampleScreenAction(ScreenDisplayMode.Modal, props.action.someParam + "😊")),
            showModalMdScreenSampleAction: ActionDescriptor.fromAction(new ShowSampleMdScreenAction(ScreenDisplayMode.Modal, props.action.someParam + "😊")),
            showDetailScreenSampleAction: ActionDescriptor.fromAction(new ShowSampleScreenAction(ScreenDisplayMode.Detail, props.action.someParam + "😊")),
            showDetailMdScreenSampleAction: ActionDescriptor.fromAction(new ShowSampleMdScreenAction(ScreenDisplayMode.Detail, props.action.someParam + "😊")),
        };

    }, [props.action.someParam]);

    const screenState = useScreenStateContext();

    const secondaryCancelled = useCallback(() => {
        setSecondaryEvents([...secondaryEvents, "Cancelled"]);
    }, [setSecondaryEvents, secondaryEvents]);

    const secondarySavedExisting = useCallback(() => {
        setSecondaryEvents([...secondaryEvents, "Saved existing"]);
    }, [setSecondaryEvents, secondaryEvents]);

    const secondarySavedNew = useCallback((id: StringEntityId) => {
        setSecondaryEvents([...secondaryEvents, "Saved new with id: " + id.value]);
    }, [setSecondaryEvents, secondaryEvents]);

    return (
        <MasterDetailScreen
            title={props.action.id.value}
            onSecondaryCancelled={secondaryCancelled}
            onSecondarySavedNew={secondarySavedNew}
            onSecondarySavedExisting={secondarySavedExisting}
        >
            Master detail screen.
            someParam: {props.action.someParam}
            <hr />

            <Button automationId="" text="Cancel" onClick={screenState.cancelled} />
            <Button automationId="" text="Save" onClick={screenState.savedExisting} />

            <hr />

            <ActionBoundButton actionDescriptor={showFullScreenSampleAction} automationId="" text="Full screen" />
            <ActionBoundButton actionDescriptor={showFullMdScreenSampleAction} automationId="" text="Full MD screen" />

            <ActionBoundButton actionDescriptor={showModalScreenSampleAction} automationId="" text="Modal screen" />
            <ActionBoundButton actionDescriptor={showModalMdScreenSampleAction} automationId="" text="Modal MD screen" />

            <ActionBoundButton actionDescriptor={showDetailScreenSampleAction} automationId="" text="Detail screen" />
            <ActionBoundButton actionDescriptor={showDetailMdScreenSampleAction} automationId="" text="Detail MD screen" />

            <hr />
            <h2>Events from secondary screen</h2>
            <ul>
                {secondaryEvents.map(e => (<li>{e}</li>))}
            </ul>
        </MasterDetailScreen>
    );
}