/* tslint:disable */
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import IActionProcessor from "@Toolkit/ReactClient/ActionProcessing/IActionProcessor";
import FrontendActionId from "@Toolkit/ReactClient/ActionProcessing/FrontendActionId";
import INotificationService from "@Toolkit/ReactClient/Services/Definition/NotificationService/INotificationService";
import { FrontendActionPermissionScope } from "@Toolkit/ReactClient/ActionProcessing/FrontendActionPermissionScope";

export class ToastSampleAction extends FrontendActionBase {
    public static id = new FrontendActionId("ToastSampleAction");

    constructor(
        public readonly message: string
    ) {
        super(ToastSampleAction.id);
    }
}

export class ToastSampleActionProcessor implements IActionProcessor<ToastSampleAction> {

    constructor(
        private readonly notificationService: INotificationService
    ) { }

    public processAsync(action: ToastSampleAction): Promise<void> {
        this.notificationService.info(action.message);
        return Promise.resolve();
    }

}