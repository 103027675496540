import React from "react";
import * as Ui from "@CommonControls";
import SourceCode from "./SourceCode";
import ITreeViewNode from "@CommonControls/TreeView/ITreeViewNode";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { ITableFrameColumn } from "@CommonControls/TreeGrid/TableFrame";
import TreeGridDefaultFooter from "@CommonControls/TreeGrid/TreeGridDefaultFooter";

let index = 0;

class NodeStore implements ITreeViewNode {
    @State.observable.ref public key?: string = null;
    @State.observable.ref public displayValue: React.ReactNode = null;
    @State.observable.ref public children?: ITreeViewNode[] = null;
    @State.observable.ref public isOpen?: boolean = null;
    @State.observable.ref public isActive?: boolean = null;
    @State.observable.ref public isLoading?: boolean = null;

    constructor(displayValue: React.ReactNode, children?: ITreeViewNode[]) {
        this.displayValue = displayValue;
        this.children = children;
        this.key = `${index++}`;
    }
}


@State.observer
export default class TreeViewExampleBox extends React.Component {

    private data: ITreeViewNode[] = [
        new NodeStore("Root 1",
            [
                new NodeStore(<>Child 1 with other elements <Ui.Icon iconName="cog" /></>),
                new NodeStore("Child 2", [
                    new NodeStore(<>Child 3 <Ui.Badge shape="pill" size="small">Yeah</Ui.Badge></>),
                    new NodeStore("Child 4"),
                    new NodeStore("Child 5", [
                        new NodeStore("Child 6")
                    ]),
                ]),
            ]
        ),
        new NodeStore("Root 2"),
        new NodeStore("Root 3", [
            new NodeStore("Child 7"),
            new NodeStore("Child 8"),
        ]),
    ];

    public render() {
        return (
            <Ui.PageBox title="TreeView">
                <Ui.PageBox.Body>
                    <p>A TreeView is a graphical control element that presents a hierarchical view of information. Each item (often called a node) can have a number of subitems. This is often visualized by indentation in a list. An item can be expanded to reveal subitems, if any exist, and collapsed to hide subitems.</p>

                    <Ui.GroupBox title="Simple TreeView" hasBorder>
                        <Ui.TreeView
                            data={this.data}
                        />
                    </Ui.GroupBox>

                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="typescript">
                            {`
// ... Data:
class NodeStore implements ITreeViewNode {
    @State.observable.ref public key?: string = "" + index++;
    @State.observable.ref public displayValue: React.ReactNode = null;
    @State.observable.ref public children?: ITreeViewNode[] = null;
    @State.observable.ref public isOpen?: boolean = null;
    @State.observable.ref public isActive?: boolean = null;
    @State.observable.ref public isLoading?: boolean = null;

    constructor(displayValue: React.ReactNode, children?: ITreeViewNode[]) {
        this.displayValue = displayValue;
        this.children = children;
    }
}

private data: ITreeViewNode[] = [
    new NodeStore("Root 1",
        [
            new NodeStore("Child 1"),
            new NodeStore("Child 2", [
                new NodeStore("Child 3"),
                new NodeStore("Child 4"),
                new NodeStore("Child 5", [
                    new NodeStore("Child 6")
                ]),
            ]),
        ]
    ),
    new NodeStore("Root 2"),
    new NodeStore("Root 3", [
        new NodeStore("Child 7"),
        new NodeStore("Child 8"),
    ]),
];

// ... View:

<Ui.TreeView
    data={this.data}
/>

`}
                        </SourceCode>
                    </Ui.SingleAccordion>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}
