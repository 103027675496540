import Di from "@Di";
import moment from "moment";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import ISelectBoxItem from "@CommonControls/SelectBox/ISelectBoxItem";
import DiagnosisId from "@Primitives/DiagnosisId.g";
import ConditionId from "@Primitives/ConditionId.g";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class TooltipExampleStore {
    @State.observable public checkBoxValue: boolean = false;
    @State.observable.ref public dateTimePickerValue: moment.Moment = null;
    @State.observable.ref public dateRangePickerValue: LocalDateRange = null;
    @State.observable public textBoxValue: string = "";
    @State.observable public maskedTextBoxValue: string = "";
    @State.observable public nameTextBoxValue: string = "";
    @State.observable public numberBoxValue: number = 0;
    @State.observable public selectBoxValue: ISampleItem = null;
    @State.observable.ref public selectBoxOptions: Array<ISelectBoxItem<ISampleItem>> = this.getOptions();
    @State.observable.ref public conditionCodeSelectorValue: ConditionId = null;

    @State.action.bound
    public changeCheckBoxValue(newValue: boolean) {
        this.checkBoxValue = newValue;
    }

    @State.action.bound
    public setDateTimePickerValue(newValue: moment.Moment) {
        this.dateTimePickerValue = newValue;
    }

    @State.action.bound
    public setDateRangePickerValue(newValue: LocalDateRange) {
        this.dateRangePickerValue = newValue;
    }

    @State.action.bound
    public setTextBoxValue(newValue: string) {
        this.textBoxValue = newValue;
    }

    @State.action.bound
    public setMaskedTextBoxValue(newValue: string) {
        this.maskedTextBoxValue = newValue;
    }

    @State.action.bound
    public setNameTextBoxValue(newValue: string) {
        this.nameTextBoxValue = newValue;
    }

    @State.action.bound
    public setNumberBoxValue(newValue: number) {
        this.numberBoxValue = newValue;
    }

    @State.action.bound
    public setSelectBoxValue(newValue: ISampleItem) {
        this.selectBoxValue = newValue;
    }

    @State.action.bound
    public setConditionCodeSelectorValue(newValue: DiagnosisId) {
        this.conditionCodeSelectorValue = newValue;
    }

    private getOptions() {
        return [
            { text: "2 - Name1", value: { SampleName: "Name1", SampleNumber: 2 } } as ISelectBoxItem<ISampleItem>,
            { text: "4 - Name2", value: { SampleName: "Name2", SampleNumber: 4 } } as ISelectBoxItem<ISampleItem>,
            { text: "6 - Name3", value: { SampleName: "Name3", SampleNumber: 6 } } as ISelectBoxItem<ISampleItem>,
            { text: "8 - Name4", value: { SampleName: "Name4", SampleNumber: 8 } } as ISelectBoxItem<ISampleItem>,
        ];
    }
}

interface ISampleItem {
    SampleName: string;
    SampleNumber: number;
}