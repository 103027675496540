import React from "react";
import * as DiContainer from "@DiContainer";
import * as Ui from "@CommonControls";
import { DocumentViewerModal } from "@HisPlatformControls";
import DocumentViewerModalExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/DocumentViewerModalExampleStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SourceCode from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/SourceCode";

@State.observer
export default class DocumentViewerModalExampleBox extends React.Component {
    @DiContainer.lazyResolve("DocumentViewerModalExampleStore") private store: DocumentViewerModalExampleStore;

    public render() {
        return (
            <Ui.PageBox title="DocumentViewerModal">
                <Ui.PageBox.Body>
                    <p>Display a document in a modal.</p>

                    <Ui.Button automationId="" onClick={this.store.openModal}>Show document viewer modal</Ui.Button>

                    <DocumentViewerModal
                        title="DocumentViewerModal"
                        isOpen={this.store.isOpen}
                        onClose={this.store.closeModal}
                        htmlContent={this.store.htmlContent}
                        renderHeaderLabel={this.renderLabels} />

                </Ui.PageBox.Body>

                <Ui.SingleAccordion title="Source">
                    <SourceCode language="html">
                        {`
                    <DocumentViewerModal
                    title="DocumentViewerModal"
                    isOpen={this.store.isOpen}
                    onClose={this.store.closeModal}
                    htmlContent={this.store.htmlContent}
                    renderHeaderLabel={this.renderLabels} />
`}
                    </SourceCode>
                </Ui.SingleAccordion>
            </Ui.PageBox>
        );
    }

    @State.bound
    private renderLabels() {
        return (
            <>
                <Ui.Modal.Label label="Label1:">Description1</Ui.Modal.Label>
                <Ui.Modal.Label label="Label2:">Description2</Ui.Modal.Label>
            </>
        );
    }
}