import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

@Di.injectable()
export default class MasterDetailExampleStore {

    @State.observable.ref public validationResults: IClientValidationResult[] = null;

    @State.action.bound
    public addValidationResults() {
        this.validationResults = [
            {
                entityName: "tab1",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "tab1TestValue1",
                        severity: "error",
                        message: "--Error message1--",
                    },
                    {
                        propertyPath: "tab1TestValue2",
                        severity: "error",
                        message: "--Error message2--",
                    }
                ]
            },
            {
                entityName: "tab2",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "tab2TestValue",
                        severity: "warning",
                        message: "--Warning message--",
                    }
                ]
            },
        ];
    }

    @State.action.bound
    public removeValidationResults() {
        this.validationResults = null;
    }
}
