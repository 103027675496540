import React from "react";
import * as Ui from "@CommonControls";
import SourceCode from "./SourceCode";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class PageTitleExample extends React.Component {

    public render() {
        return (
            <>
                <Ui.PageTitle title="Page Title with labels">
                    <Ui.PageTitle.Label label="Label1:">Description1</Ui.PageTitle.Label>
                    <Ui.PageTitle.Label label="Label2:">Description2</Ui.PageTitle.Label>
                </Ui.PageTitle>
                <Ui.PageBox title="PageTitle">
                    <Ui.PageBox.Body>

                        <SourceCode language="html">
                            {`
 <Ui.PageTitle title="PageTitle with labels">
     <Ui.PageTitle.Label label="Label1:">Description1</Ui.PageTitle.Label>
     <Ui.PageTitle.Label label="Label2:">Description2</Ui.PageTitle.Label>
 </Ui.PageTitle><br/>
`}
                        </SourceCode>

                    </Ui.PageBox.Body>
                </Ui.PageBox>
                <Ui.PageTitle title="Page Title without labels" />
                <Ui.PageBox title="PageTitle">
                    <Ui.PageBox.Body>

                        <SourceCode language="html">
                            {`
 <Ui.PageTitle title="PageTitle without labels" /><br/>
`}
                        </SourceCode>

                    </Ui.PageBox.Body>
                </Ui.PageBox>
            </>
        );
    }

}