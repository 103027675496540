import React from "react";
import * as Ui from "@CommonControls";
import * as DiContainer from "@DiContainer";
import SourceCode from "./SourceCode";
import WorkflowStepsIndicatorExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/WorkflowStepsIndicatorExampleStore";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class WorkflowStepsIndicatorExampleBox extends React.Component {

    @DiContainer.lazyResolve("WorkflowStepsIndicatorExampleStore") private store: WorkflowStepsIndicatorExampleStore;

    public render() {
        return (
            <Ui.PageBox title="WorkflowStepsControl">
                <Ui.PageBox.Body>
                    <h5>WORKFLOW</h5>
                    <p>This control can be used to display the steps of a workflow. Items can have different states.</p>
                    <p>It needs an array of predefined step configuration items.</p>
                    <p>It needs a selectedStepName property if you want to display the active step.</p>

                    <Ui.WorkflowStepsIndicator steps={this.store.steps} selectedStep={this.store.selectedStep} onStepSelected={this.store.onStepSelected} />
                    <pre>Selected step title: {this.store.selectedStep.title}</pre>

                    <p>A Workflow a Páciens Ellátási Folyamaton belül jelenik meg a Secondary Header alatt. Szélességét a Dashboard befolyásolja:<br />
                        Például ha egy nyitott Simple Dashboard 3 oszlopnyi helyet foglal el (a 12-es osztásból), akkor a Workflow 9 oszlop széles. <br />
                        Ha a Dashboardot összecsukjuk, akkor a Workflow nagyobb helyet kap.</p>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
<Ui.WorkflowStepsIndicator steps={this.store.steps} selectedStep={this.store.selectedStep} />

`}
                        </SourceCode>
                        <SourceCode language="typescript">
                            {`
       public steps: IWorkflowStep[] = [
        { title: "Első lépés", disabled: false, validation: WorkflowValidation.Correct },
        { title: "Második lépés", disabled: false, validation: WorkflowValidation.InCorrect },
        { title: "Harmadik lépés", disabled: false, validation: WorkflowValidation.NotValidated },
        { title: "Negyedik lépés", disabled: false, validation: WorkflowValidation.NotValidated },
        { title: "Utolsó lépés", disabled: true, validation: WorkflowValidation.NotValidated }
    ];
    @State.observable.ref public selectedStep: IWorkflowStep = this.steps[2];

    public onStepSelected(selected: IWorkflowStep) {
        this.selectedStep = selected;
    }
    
`}
                        </SourceCode>

                    </Ui.SingleAccordion>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}