import React from "react";
import * as Ui from "@CommonControls";
import * as DiContainer from "@DiContainer";
import DialogStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/DialogStore";
import SourceCode from "./SourceCode";

export default class DialogExampleBox extends React.Component {

    @DiContainer.lazyResolve("DialogStore") private dialogStore: DialogStore;

    public render() {
        return (
            <Ui.PageBox title="Dialog">
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <p>The graphical control element dialog is a small window that communicates information to the user and prompts them for a response.</p>
                        <Ui.Button automationId="" onClick={this.dialogStore.okDialog}>OK dialog</Ui.Button>
                        <Ui.Button automationId="" onClick={this.dialogStore.okCancelDialog}>OK/Cancel dialog</Ui.Button>
                        <Ui.Button automationId="" onClick={this.dialogStore.yesNoDialog}>Yes/No dialog</Ui.Button>
                        <Ui.Button automationId="" onClick={this.dialogStore.yesNoCancelDialog}>Yes/No/Cancel dialog</Ui.Button>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={6}>
                        <h3>Store</h3>
                        <SourceCode language="typescript">
                            {`
constructor(
    private dialogService: Interfaces.IDialogService
) { }

@State.bound
@State.action
public doSomething() {
    // ...
    this.dialogService.ok("Completed");
    // ...
}
`}
                        </SourceCode>
                    </Ui.Flex.Item>
                </Ui.Flex>
                <hr />
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <p>A dialog is awaitable and the result will be the answer from the user.</p>
                        <Ui.Button automationId="" onClick={this.dialogStore.awaitableDialogAsync}>Dialog sequence</Ui.Button>
                    </Ui.Flex.Item>
                    <Ui.Flex.Item xs={6}>
                        <h3>Store</h3>
                        <SourceCode language="typescript">
                            {`
constructor(
    private dialogService: Interfaces.IDialogService
) { }

@State.bound
@State.action
public async deleteSomethingAsync() {
    // ...
    const dialogResult = await this.dialogService.ok("Completed");
    if (dialogResult === DialogResultCode.Yes) {
        // ...
    }
    // ...
}
`}
                        </SourceCode>
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.PageBox>
        );
    }
}