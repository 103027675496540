import React from "react";
import * as Ui from "@CommonControls";
import SourceCode from "./SourceCode";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class BadgeExamplebox extends React.Component {
    public render() {
        return (
            <Ui.PageBox title="Badge">
                <Ui.PageBox.Body>
                <p>Labels are used to provide additional information about something.</p>
                    <Ui.Badge text="danger" visualStyle="danger" />
                    <Ui.Badge text="positive" visualStyle="positive" />
                     <Ui.Badge text="secondary" visualStyle="secondary" />
                    <Ui.Badge text="warning" visualStyle="warning" />
                    <Ui.Badge text="danger fluid" visualStyle="danger" fluid={true} />
                    <Ui.Badge text="positive fluid" visualStyle="positive" fluid={true} />
                    <Ui.Badge text="secondary fluid" visualStyle="secondary" fluid={true} />
                    <Ui.Badge text="warning fluid" visualStyle="warning" fluid={true} />                  

                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
<Ui.Badge text="danger" visualStyle="danger" />                            
<Ui.Badge text="positive fluid" visualStyle="positive" fluid={true} />
`}
                        </SourceCode>

                    </Ui.SingleAccordion>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }

}