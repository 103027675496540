import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class CheckBoxExampleStore {
    @State.observable public nullableValue: boolean = false;
    @State.observable public simpleValue: boolean = true;

    @State.action.bound
    public changeSimpleValue(newValue: boolean) {
        this.simpleValue = newValue;
    }

    @State.action.bound
    public changeNullableValue(newValue: boolean) {
        this.nullableValue = newValue;
    }
}
