import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import GenderId from "@Primitives/GenderId.g";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

@Di.injectable()
export default class RadioButtonExampleStore {

    @State.observable.ref public validationResults: IClientValidationResult[] = null;

    @State.observable public selectedValue1: string = "First";
    @State.observable public selectedValue2: string = "Apple";

    @State.observable public selectedGenderId: GenderId = new GenderId("Male");

    public items1 = ["First", "Second", "Third"];
    public items2 = ["Apple", "Orange", "Banana"];

    public genderIds = [new GenderId("Male"), new GenderId("Female")];

    @State.action.bound
    public setSelectedValue1(newValue: string) {
        this.selectedValue1 = newValue;
    }

    @State.action.bound
    public setSelectedValue2(newValue: string) {
        this.selectedValue2 = newValue;
    }

    @State.action.bound
    public setGenderId(newValue: GenderId) {
        this.selectedGenderId = newValue;
    }

    public getGenderDisplayValue(value: GenderId) {
        return value.value;
    }

    public getIconNameForValues(value: string) {
        if (value === "First") {
            return "search";
        } else if (value === "Second") {
            return "pen";
        }
        return "check";
    }

    public getGenderIcons(value: GenderId) {
        if (value.value === "Male") {
            return "male";
        } else {
            return "female";
        }
    }

    public getGenderIconStyle(value: GenderId): React.CSSProperties {
        if (value.value === "Male") {
            return {fill: "#00B4EC"};
        } else {
            return {fill: "#CE5374"};
        }
    }

    @State.action.bound
    public addError() {
        this.validationResults = [
            {
                entityName: "test",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "testValue",
                        severity: "error",
                        message: "This filed is required. Please fill it with proper information.",
                    }
                ]
            }
        ];
    }

    @State.action.bound
    public removeValidation() {
        this.validationResults = null;
    }

    @State.bound
    @State.action
    public addWarning() {
        this.validationResults = [
            {
                entityName: "test",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "testValue",
                        severity: "warning",
                        message: "This filed is required. Please fill it with proper information.",
                    }
                ]
            }
        ];
    }
}