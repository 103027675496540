import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";

@Di.injectable()
export default class NumberBoxExampleStore {

    @State.observable public simpleValue: number = 0;
    @State.observable public notNullValue: number = 648;
    @State.observable public isDisabled: boolean = false;

    @State.observable.ref public validationResults: IClientValidationResult[] = null;

    @State.action.bound
    public changeSimpleValue(newValue: number) {
        this.simpleValue = newValue;
    }

    @State.action.bound
    public changeNotNullValue(newValue: number) {
        this.notNullValue = newValue;
    }

    @State.action.bound
    public addError() {
        this.validationResults = [
            {
                entityName: "test",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "testValue",
                        severity: "error",
                        message: "This field is required. Please fill it with proper information.",
                    }
                ]
            }
        ];
    }

    @State.action.bound
    public removeValidation() {
        this.validationResults = null;
    }

    @State.bound
    @State.action
    public addWarning() {
        this.validationResults = [
            {
                entityName: "test",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "testValue",
                        severity: "warning",
                        message: "This field is required. Please fill it with proper information.",
                    }
                ]
            }
        ];
    }

    @State.action.bound
    public setDisable() {
        this.isDisabled = !this.isDisabled;
    }
}
