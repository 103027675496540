import React from "react";
import * as Ui from "@CommonControls";
import * as DiContainer from "@DiContainer";
import AccordionExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/AccordionExampleStore";
import SourceCode from "./SourceCode";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class AccordionExampleBox extends React.Component {

    @DiContainer.lazyResolve("AccordionExampleStore") private store: AccordionExampleStore;

    public render() {
        return (
            <Ui.PageBox title="Accordion">
                <Ui.PageBox.Body>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6}>
                            <p>The accordion is a graphical control element comprising a vertically stacked list of items, such as labels or thumbnails. Each item can be "expanded" or "stretched" to reveal the content associated with that item.</p>
                            <h3>Uncontrolled - SingleAccordion</h3>
                            <Ui.SingleAccordion title="Extended search">
                                <b>This is the extended search!</b>
                            </Ui.SingleAccordion>

                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            <h3>Page</h3>
                            <SourceCode language="html">
                                {`
<Ui.SingleAccordion title="Extended search">
<b>This is the extended search!</b>
</Ui.SingleAccordion>
`}
                            </SourceCode>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6}>
                            <br /><p>Use multiple single accordions to simultaneously open them.</p>
                            <Ui.SingleAccordion title="Data group 1">
                                <b>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</b>
                            </Ui.SingleAccordion>
                            <Ui.SingleAccordion title="Data group 2">
                                <h1>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</h1>
                            </Ui.SingleAccordion>
                            <Ui.SingleAccordion title="Data group 3">
                                <i>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</i>
                            </Ui.SingleAccordion>

                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            <h3>Page</h3>
                            <SourceCode language="html">
                                {`
<Ui.SingleAccordion title="Data group 1">
<b>Some data</b>
</Ui.SingleAccordion>
<Ui.SingleAccordion title="Data group 2">
<h1>Surprise!</h1>
</Ui.SingleAccordion>
<Ui.SingleAccordion title="Data group 3">
<i>Italiano</i>
</Ui.SingleAccordion>
`}
                            </SourceCode>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6}>
                            <h3>Controlled - SingleAccordion</h3>
                            <Ui.Button automationId="" onClick={this.store.toggleSingleAccordion}>Toggle</Ui.Button>
                            <Ui.SingleAccordion title="Extended search" isOpen={this.store.isOpen} onClick={this.store.toggleSingleAccordion}>
                                <b>This is the extended search!</b>
                            </Ui.SingleAccordion>

                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            <h3>Store</h3>
                            <SourceCode language="typescript">
                                {`
@State.observable public isOpen = false;

 @State.action.bound
 public toggleSingleAccordion() {
     this.isOpen = !this.isOpen;
 }
`}
                            </SourceCode>
                            <h3>Page</h3>
                            <SourceCode language="html">
                                {`
<Ui.Button onClick={this.store.toggleSingleAccordion}>Toggle</Ui.Button>
<Ui.SingleAccordion title="Extended search" isOpen={this.store.isOpen} onClick={this.store.toggleSingleAccordion}>
    <b>This is the extended search!</b>
</Ui.SingleAccordion>
`}
                            </SourceCode>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <hr />
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6}>
                            <h3>Uncontrolled - MultiAccordion</h3>
                            <Ui.MultiAccordion>
                                <Ui.MultiAccordion.Pane title="One">
                                    <b>This is the first!</b>
                                </Ui.MultiAccordion.Pane>
                                <Ui.MultiAccordion.Pane title="Two">
                                    <b>This is the second!</b>
                                </Ui.MultiAccordion.Pane>
                                <Ui.MultiAccordion.Pane title="Three">
                                    <b>This is the third!</b>
                                </Ui.MultiAccordion.Pane>
                            </Ui.MultiAccordion>

                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            <h3>Page</h3>
                            <SourceCode language="html">
                                {`
<Ui.MultiAccordion>
<Ui.MultiAccordion.Pane title="One">
    <b>This is the first!</b>
</Ui.MultiAccordion.Pane>
<Ui.MultiAccordion.Pane title="Two">
    <b>This is the second!</b>
</Ui.MultiAccordion.Pane>
<Ui.MultiAccordion.Pane title="Three">
    <b>This is the third!</b>
</Ui.MultiAccordion.Pane>
</Ui.MultiAccordion>
`}
                            </SourceCode>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6}>
                            <h3>Controlled - MultiAccordion</h3>
                            <Ui.Button automationId="" onClick={this.store.setAccordionNone}>Close all</Ui.Button>
                            <Ui.Button automationId="" onClick={this.store.setAccordionOne}>One</Ui.Button>
                            <Ui.Button automationId="" onClick={this.store.setAccordionTwo}>Two</Ui.Button>
                            <Ui.Button automationId="" onClick={this.store.setAccordionThree}>Three</Ui.Button>

                            <Ui.MultiAccordion selectedIndex={this.store.index} onChange={this.store.setIndex}>
                                <Ui.MultiAccordion.Pane title="One" index={0}>
                                    <b>This is the first!</b>
                                </Ui.MultiAccordion.Pane>
                                <Ui.MultiAccordion.Pane title="Two" index={1}>
                                    <b>This is the second!</b>
                                </Ui.MultiAccordion.Pane>
                                <Ui.MultiAccordion.Pane title="Three" index={2}>
                                    <b>This is the third!</b>
                                </Ui.MultiAccordion.Pane>
                            </Ui.MultiAccordion>

                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            <h3>Store</h3>
                            <SourceCode language="typescript">
                                {`
@State.observable public index: number = null;

@State.action.bound
public setAccordionOne() {
    this.index = 0;
}

@State.action.bound
    public setIndex(newIndex: number) {
        if (newIndex === this.index) {
            this.index = null;
        } else {
            this.index = newIndex;

        }
    }
`}
                            </SourceCode>
                            <h3>Page</h3>
                            <SourceCode language="html">
                                {`
<Ui.MultiAccordion selectedIndex={this.store.index} onChange={this.store.setIndex}>
<Ui.MultiAccordion.Pane title="One" index={0}>
    <b>This is the first!</b>
</Ui.MultiAccordion.Pane>
<Ui.MultiAccordion.Pane title="Two" index={1}>
    <b>This is the second!</b>
</Ui.MultiAccordion.Pane>
<Ui.MultiAccordion.Pane title="Three" index={2}>
    <b>This is the third!</b>
</Ui.MultiAccordion.Pane>
</Ui.MultiAccordion>
`}
                            </SourceCode>
                        </Ui.Flex.Item>
                    </Ui.Flex>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}