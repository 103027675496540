import RouteDefinition from "@Toolkit/ReactClient/Routing/RouteDefinition";
import RoutingController from "@Toolkit/ReactClient/Routing/RoutingController";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";

export default class DeveloperPageSideMenuItem {

    constructor(
        public readonly text: string,
        public readonly routeDefinition: RouteDefinition,
        private readonly routingController: IRoutingController,
    ) { }

    @State.bound
    public clickHandler() {
        this.routingController.push(this.routeDefinition.makeRoute());
    }
}