import React from "react";
import * as Ui from "@CommonControls";
import SingleLayout from "@CommonControls/Layout/SingleLayout";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { HisDocumentEditor } from "@HisPlatformControls";
import DocumentContentStore from "@CommonControls/DocumentEditor/DocumentContentStore";

@State.observer
export default class DocumentEditorExampleBox extends React.Component {

    private readonly modes = ["Document", "TextBlock", "Template"];
    @State.observable.ref private currentMode: "Document" | "TextBlock" | "Template" = "Document";
    @State.observable.ref private isReadOnly = false;
    private contentStore = DocumentContentStore.create();

    public render() {
        return (
            <SingleLayout>
                <Ui.PageBox2 fullHeight>
                    <Ui.PageBox2.Header title="DocumentEditor" toolbar={(
                        <div style={{ display: "flex" }}>
                            <Ui.RadioButtonGroup items={this.modes} value={this.currentMode} onChange={this.setMode} displayType="groupedButtons" />
                            <Ui.CheckBox automationId="" value={this.isReadOnly} onChange={this.setReadOnly} label="ReadOnly" displayMode="switch" />
                        </div>
                    )} />
                    <Ui.PageBox2.Body hasSidePadding>
                        <Ui.ToolbarLayout
                            topToolbar={(
                                <Ui.Button automationId="" text="getContentAsTextAsync" onClickAsync={this.showTextContentAsync} />
                            )}
                            topToolbarHeight={32}
                            body={(
                                <HisDocumentEditor
                                    mode={this.currentMode}
                                    isReadOnly={this.isReadOnly}
                                    temporaryDataId="DeveloperPageDocument"
                                    contentStore={this.contentStore.forEditor}
                                />
                            )}
                        />
                    </Ui.PageBox2.Body>
                </Ui.PageBox2>
            </SingleLayout>
        );
    }

    @State.action.bound
    private setMode(newMode: "Document" | "TextBlock" | "Template") {
        this.currentMode = newMode;
    }

    @State.action.bound
    private setReadOnly(newValue: boolean) {
        this.isReadOnly = newValue;
    }

    @State.bound
    private async showTextContentAsync() {
        const isSelected = await this.contentStore.isTextSelectedAsync();
        if (isSelected) {
            const selectedText = await this.contentStore.getSelectedContentAsTextAsync();
            alert(selectedText);
        } else {
            const text = await this.contentStore.getContentAsTextAsync();
            alert(text);
        }
    }
}