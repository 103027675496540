import React from "react";
import * as Ui from "@CommonControls";
import SourceCode from "./SourceCode";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class TableExampleBox extends React.Component {

    public render() {
        return (
            <Ui.PageBox title="Table">
                <Ui.PageBox.Body>
                    <Ui.Table automationId="example_table">
                        <thead>
                            <tr>
                                <th> Content thead </th>
                                <th> Content thead </th>
                                <th> Content thead </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> content tbody </td>
                                <td> content tbody content tbody </td>
                                <td> content tbody content tbody content tbody</td>
                            </tr>
                            <tr>
                                <td> content tbody </td>
                                <td> content tbody </td>
                                <td> content tbody </td>
                            </tr>
                            <tr>
                                <td> content tbody </td>
                                <td> content tbody </td>
                                <td> content tbody </td>
                            </tr>
                        </tbody>
                    </Ui.Table>
                    <SourceCode language="html">
                        {`
 <Ui.Table>
 <thead>
     <tr>
         <th> Content thead </th>
         <th> Content thead </th>
         <th> Content thead </th>
     </tr>
 </thead>
 <tr>
     <td> content tbody </td>
     <td> content tbody content tbody </td>
     <td> content tbody content tbody content tbody</td>
 </tr>
 <tr>
     <td> content tbody </td>
     <td> content tbody </td>
     <td> content tbody </td>
 </tr>
 <tr>
     <td> content tbody </td>
     <td> content tbody </td>
     <td> content tbody </td>
 </tr>
</Ui.Table>
`}
                    </SourceCode>

                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}