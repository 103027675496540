import * as Ui from "@CommonControls";
import React from "react";
import * as DiContainer from "@DiContainer";
import SidebarExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/SidebarExampleStore";
import SourceCode from "./SourceCode";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class SidebarExampleBox extends React.Component {
    @DiContainer.lazyResolve("SidebarExampleStore") private store: SidebarExampleStore;

    public render() {
        return (
            <Ui.PageBox title="Sidebar" style={{ minHeight: 400 }}>
                <Ui.PageBox.Body>
                    <h3>Sidebar</h3>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={2}>
                            <b>SIDEBAR RIGHT</b><br />
                            Width: 32px
                            <div style={{ width: "30px", height: "400px", position: "relative" }}>
                                <Ui.Sidebar selectedItemName={this.store.selectedItem}>
                                    <Ui.Sidebar.Tab name="patient" title="páciens" onClick={this.store.selectPatientTab} />
                                    <Ui.Sidebar.Tab name="care" title="ellátás" onClick={this.store.selectCareTab} />
                                    <Ui.Sidebar.Tab name="prescriptions" title="vények" onClick={this.store.selectPrescriptionsTab} />
                                </Ui.Sidebar>
                            </div>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={3}>
                            <b>SIDEBAR TABS</b><br />
                            Width: 32px<br />
                            Height: 80px<br />
                            Margin: 0px<br />
                            Horizontal Padding: 10px<br />
                            Vertical Padding: 16px
                            <Ui.Flex>
                                <Ui.Flex.Item xs={4}>
                                    <div style={{ width: "30px", height: "59px", position: "relative" }}>
                                        <Ui.Sidebar selectedItemName={this.store.selectedItem}>
                                            <Ui.Sidebar.Tab name="patient" title="label" onClick={this.store.selectPatientTab} />
                                        </Ui.Sidebar>
                                    </div>
                                    SELECTED
                                </Ui.Flex.Item>
                                <Ui.Flex.Item xs={4} xsOffset={4}>
                                    <div style={{ width: "30px", height: "59px", position: "relative" }}>
                                        <Ui.Sidebar selectedItemName={this.store.selectedItem}>
                                            <Ui.Sidebar.Tab name="care" title="lab el" onClick={this.store.selectCareTab} />
                                        </Ui.Sidebar>
                                    </div>
                                    UNSELECTED
                                </Ui.Flex.Item>
                            </Ui.Flex>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={7}>
                            A Right Sidebar a Páciens Ellátási Folyamaton belül jelenik meg a Secondary Header alatt, a képernyő jobb oldalán.
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="typescript">
                            {`
                            <Ui.Sidebar selectedItemName={this.store.selectedItem}>
                                <Ui.Sidebar.Tab name="patient" title="páciens" onClick={this.store.selectPatientTab} />
                                <Ui.Sidebar.Tab name="care" title="ellátás" onClick={this.store.selectCareTab} />
                                <Ui.Sidebar.Tab name="prescriptions" title="vények" onClick={this.store.selectPrescriptionsTab} />
                            </Ui.Sidebar>

                            @State.observable public selectedItem: string = "patient";

                            @State.action.bound
                            public selectPatientTab() {
                                this.selectedItem = "patient";
                            }
                        
                            @State.action.bound
                            public selectCareTab() {
                                this.selectedItem = "care";
                            }
                        
                            @State.action.bound
                            public selectPrescriptionsTab() {
                                this.selectedItem = "prescriptions";
                            }
                            `}
                        </SourceCode>
                    </Ui.SingleAccordion>
                </Ui.PageBox.Body>
            </Ui.PageBox >
        );
    }
}