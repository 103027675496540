import React from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import ITitleGroup from "@CommonControls/TitleGroup/ITitleGroup";

@State.observer
export default class TitleGroupExampleBox extends React.Component {

    @State.observable private selectedId: string = null;

    @State.action.bound
    private onClick(id: string) {
        this.selectedId = id;
    }

    private titles = [
        {
            label: "Főcím1",
            elementId: "#focim1",
            childrenTitles: [
                {
                    label: "Alcím11",
                    elementId: "#alcim11"
                } as ITitleGroup,
                {
                    label: "Alcím12",
                    elementId: "#alcim12"
                } as ITitleGroup,
                {
                    label: "Alcím13",
                    elementId: "#alcim13"
                } as ITitleGroup,
                {
                    label: "Alcím14",
                    elementId: "#alcim14"
                } as ITitleGroup
            ]
        } as ITitleGroup,
        {
            label: "Főcím2",
            elementId: "#focim2",
            childrenTitles: [
                {
                    label: "Alcím21",
                    elementId: "#alcim21"
                } as ITitleGroup,
                {
                    label: "Alcím22",
                    elementId: "#alcim22"
                } as ITitleGroup,
                {
                    label: "Alcím23",
                    elementId: "#alcim23"
                } as ITitleGroup,
                {
                    label: "Alcím24",
                    elementId: "#alcim24"
                } as ITitleGroup
            ]
        } as ITitleGroup
    ];

    public render() {
        return (
            <Ui.PageBox title="TitleGroup">
                <Ui.PageBox.Body>
                    <Ui.TitleGroup containerWidth="300px" titles={this.titles} onClick={this.onClick} currentlySelectedId={this.selectedId} />
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}
