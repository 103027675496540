import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class ButtonExampleStore {

    @State.action.bound
    public async testButtonClickAsync() {
        await this.sleepAsync(1500);
    }

    private sleepAsync(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
}