exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FlexExampleBox_testDiv_vY05D {\r\n    width: 100%;\r\n    height: 35px;\r\n    padding: 0;\r\n    text-align: center;\r\n    vertical-align: middle;\r\n}\r\n\r\n.FlexExampleBox_testDiv_vY05D p {\r\n    height: 100%;\r\n    margin-top: 0;\r\n    margin-bottom: 0;\r\n}\r\n\r\n.FlexExampleBox_red_3x1mq {\r\n    background: red;\r\n    color: white;\r\n}\r\n\r\n.FlexExampleBox_green_3jVoL {\r\n    background: green;\r\n    color: white;\r\n}\r\n\r\n.FlexExampleBox_blue_28Py7 {\r\n    background: blue;\r\n    color: white;\r\n}\r\n\r\n.FlexExampleBox_yellow_3pXqE {\r\n    background: yellow;\r\n    color: black;\r\n}", ""]);

// exports
exports.locals = {
	"testDiv": "FlexExampleBox_testDiv_vY05D",
	"red": "FlexExampleBox_red_3x1mq",
	"green": "FlexExampleBox_green_3jVoL",
	"blue": "FlexExampleBox_blue_28Py7",
	"yellow": "FlexExampleBox_yellow_3pXqE"
};