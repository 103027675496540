import React from "react";
import * as Ui from "@CommonControls";
import * as svgs from "@CommonControls/Icon/SvgImports";
import { iconVisualStyleType } from "@CommonControls/Icon";
import _ from "@HisPlatform/Common/Lodash";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class IconExampleBox extends React.Component {

    private renderIcon(iconName: string, visualStyle: iconVisualStyleType = "primary") {
        return (
            <>
                <Ui.Icon iconName={iconName as any} size="large" visualStyle={visualStyle} />
                <br />
                {iconName}
            </>
        );
    }

    public render() {
        return (
            <>
                <Ui.PageBox title="Icon" fullHeight={false}>
                    <table style={{ textAlign: "center", width: "100%" }}>
                        <tbody>
                            <tr>
                                <td>Primary:</td>
                                <td >{this.renderIcon("trash")}</td>
                                <td >{this.renderIcon("pills")}</td>
                                <td >{this.renderIcon("pen")}</td>
                                <td >{this.renderIcon("caretDown")}</td>
                                <td >{this.renderIcon("chevronDown")}</td>
                                <td >{this.renderIcon("check")}</td>
                                <td >{this.renderIcon("cog")}</td>
                                <td >{this.renderIcon("exclamation")}</td>
                            </tr>
                            <tr><td colSpan={9} style={{ height: "10px" }} /></tr>
                            <tr>
                                <td>Secondary:</td>
                                <td>{this.renderIcon("heartbeat", "secondary")}</td>
                                <td>{this.renderIcon("info", "secondary")}</td>
                                <td>{this.renderIcon("infoCircle", "secondary")}</td>
                                <td>{this.renderIcon("calendar", "secondary")}</td>
                                <td>{this.renderIcon("lock", "secondary")}</td>
                                <td>{this.renderIcon("unlock", "secondary")}</td>
                                <td>{this.renderIcon("angleLeft", "secondary")}</td>
                                <td>{this.renderIcon("angleRight", "secondary")}</td>
                            </tr>
                            <tr><td colSpan={9} style={{ height: "10px" }} /></tr>
                            <tr>
                                <td>Error:</td>
                                <td>{this.renderIcon("filter", "error")}</td>
                                <td>{this.renderIcon("caretUp", "error")}</td>
                                <td>{this.renderIcon("uncheckedBox", "error")}</td>
                                <td>{this.renderIcon("plus", "error")}</td>
                                <td>{this.renderIcon("sync", "error")}</td>
                                <td>{this.renderIcon("ban", "error")}</td>
                                <td>{this.renderIcon("money", "error")}</td>
                                <td>{this.renderIcon("checkedBox", "error")}</td>

                            </tr>
                            <tr><td colSpan={9} style={{ height: "10px" }} /></tr>
                            <tr>
                                <td>Warning:</td>
                                <td>{this.renderIcon("remove", "warning")}</td>
                                <td>{this.renderIcon("chevronUp", "warning")}</td>
                                <td>{this.renderIcon("enter", "warning")}</td>
                                <td>{this.renderIcon("female", "warning")}</td>
                                <td>{this.renderIcon("male", "warning")}</td>
                                <td>{this.renderIcon("more", "warning")}</td>
                                <td>{this.renderIcon("patient", "warning")}</td>
                                <td>{this.renderIcon("caretRight", "warning")}</td>
                            </tr>
                            <tr><td colSpan={9} style={{ height: "10px" }} /></tr>
                            <tr>
                                <td>Primary:</td>
                                <td>{this.renderIcon("minus")}</td>
                                <td>{this.renderIcon("search")}</td>
                                <td>{this.renderIcon("star")}</td>
                                <td>{this.renderIcon("desktop")}</td>
                                <td>{this.renderIcon("print")}</td>
                                <td>{this.renderIcon("file")}</td>
                                <td>{this.renderIcon("document")}</td>
                                <td>{this.renderIcon("clock")}</td>
                            </tr>
                            <tr><td colSpan={9} style={{ height: "10px" }} /></tr>
                            <tr>
                                <td>Secondary:</td>
                                <td>{this.renderIcon("addressCardSolid", "secondary")}</td>
                                <td>{this.renderIcon("briefcaseSolid", "secondary")}</td>
                                <td>{this.renderIcon("heartSolid", "secondary")}</td>
                                <td>{this.renderIcon("mapMarkerAltSolid", "secondary")}</td>
                                <td>{this.renderIcon("paperclipSolid", "secondary")}</td>
                                <td>{this.renderIcon("person", "secondary")}</td>
                                <td>{this.renderIcon("notesMedicalSolid", "secondary")}</td>
                                <td>{this.renderIcon("clock", "secondary")}</td>
                            </tr>
                        </tbody>
                    </table>
                </Ui.PageBox>
                <Ui.PageBox title="All icons" fullHeight={false}>
                    <div style={{ display: "flex", flexWrap: "wrap", margin: 24 }}>
                        {_.orderBy(Object.getOwnPropertyNames(svgs).filter(key => !key.startsWith("__"))).map(iconName => (
                            <div key={iconName} style={{ textAlign: "center", width: 150, margin: 4, padding: 4, border: "1px solid #cdcdcd" }}>
                                <Ui.Icon size="large" iconName={iconName as any} />
                                <span style={{ display: "block" }}>{iconName}</span>
                            </div>
                        ))}
                    </div>
                </Ui.PageBox>
            </>
        );
    }
}