import Di from "@Di";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@Di.injectable()
export default class DrawerExampleStore {
    
    @State.observable public isOpen = false;
    @State.observable public isOpenDocked = false;
    @State.observable public isDocked = false;

    @State.action.bound
    public setIsOpen() {
        this.isOpen = !this.isOpen;
    }

    @State.action.bound
    public setIsOpenDocked() {
        this.isOpenDocked = !this.isOpenDocked;
    }

    @State.action.bound
    public setIsDocked() {
        this.isDocked = !this.isDocked;
    }
}