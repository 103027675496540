import Reflection from "@Toolkit/CommonWeb/Reflection/Reflection";
import FrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/FrontendActionBase";
import FrontendActionId from "@Toolkit/ReactClient/ActionProcessing/FrontendActionId";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ShowScreenFrontendActionBase from "@Toolkit/ReactClient/ActionProcessing/ShowScreenFrontendActionBase";

export default class ShowSampleMdScreenAction extends ShowScreenFrontendActionBase {
    public static id = new FrontendActionId("ShowSampleMdScreen");

    constructor(
        displayMode: ScreenDisplayMode,
        public readonly someParam: string
    ) {
        super(ShowSampleMdScreenAction.id, displayMode);
    }

    public toUrl(): string[] {
        return [ScreenDisplayMode[this.displayMode], this.someParam];
    }

    public static fromUrl(urlParams: string[]): ShowSampleMdScreenAction {
        return new ShowSampleMdScreenAction(ScreenDisplayMode[urlParams[0]], urlParams[1]);
    }
}

Reflection.registerType(ShowSampleMdScreenAction, "ShowSampleMdScreen", "FrontendAction");