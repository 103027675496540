
import React from "react";
import * as Ui from "@CommonControls";
import * as DiContainer from "@DiContainer";
import SourceCode from "./SourceCode";
import ContextMenuExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/ContextMenuExampleStore";
import SpanWithIcon from "@CommonControls/Icon/SpanWithIcon";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class ContextMenuExampleBox extends React.Component {

    @DiContainer.lazyResolve("ContextMenuExampleStore") private store: ContextMenuExampleStore;
    @DiContainer.lazyResolve("ContextMenuExampleStore") private uncontrolledStore: ContextMenuExampleStore;

    @State.bound
    public render() {
        return (
            <Ui.PageBox title="Context menu">
                <Ui.PageBox.Body>
                    <p>A context menu (also called contextual, shortcut, and pop up or pop-up menu) is
                        a menu in a graphical user interface (GUI) that appears upon user interaction,
                        such as a right-click mouse operation. A context menu offers a limited set of
                        choices that are available in the current state, or context.</p>

                    <Ui.ContextMenu id="my-menu-1">
                        <Ui.ContextMenu.Item>Item 1</Ui.ContextMenu.Item>
                        <Ui.ContextMenu.Item>Item 2</Ui.ContextMenu.Item>
                        <Ui.ContextMenu.Item disabled><SpanWithIcon iconName="cog">Item 3</SpanWithIcon></Ui.ContextMenu.Item>
                    </Ui.ContextMenu>
                    <Ui.ContextMenu.Provider id="my-menu-1">
                        <Ui.Message type="info">Right click me!</Ui.Message>
                    </Ui.ContextMenu.Provider>

                    <Ui.SingleAccordion title="SourceCode">
                        <pre>
                            {`
<Ui.ContextMenu id="my-menu-1">
    <Ui.ContextMenu.Item>Item 1</Ui.ContextMenu.Item>
    <Ui.ContextMenu.Item>Item 2</Ui.ContextMenu.Item>
    <Ui.ContextMenu.Item>Item 3</Ui.ContextMenu.Item>
</Ui.ContextMenu>
<Ui.ContextMenu.Provider id="my-menu-1">
    <Ui.Message type="info">Right click me!</Ui.Message>
</Ui.ContextMenu.Provider>
                            `}
                        </pre>
                    </Ui.SingleAccordion>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}
