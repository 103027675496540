import Di from "@Di";
import moment from "moment";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import IClientValidationResult from "@Toolkit/ReactClient/Components/ValidationBoundary/IClientValidationResult";
import YearMonth from "@Toolkit/CommonWeb/YearMonth";

@Di.injectable()
export default class DateTimePickerExampleStore {

    @State.observable.ref public dateValue: LocalDate = null;
    @State.observable.ref public dateTimeValue: moment.Moment = null;
    @State.observable.ref public yearMonthValue: YearMonth = null;
    @State.observable.ref public isDisabled: boolean = false;

    @State.observable.ref public validationResults: IClientValidationResult[] = null;

    @State.action.bound
    public setDateValue(newValue: LocalDate) {
        this.dateValue = newValue;
    }

    @State.action.bound
    public setDateTimeValue(newValue: moment.Moment) {
        this.dateTimeValue = newValue;
    }

    @State.action.bound
    public setYearMonthValue(newValue: YearMonth) {
        this.yearMonthValue = newValue;
    }

    @State.action.bound
    public addError() {
        this.validationResults = [
            {
                entityName: "test",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "testValue",
                        severity: "error",
                        message: "This filed is required. Please fill it with proper information.",
                    }
                ]
            }
        ];
    }

    @State.action.bound
    public removeValidation() {
        this.validationResults = null;
    }

    @State.bound
    @State.action
    public addWarning() {
        this.validationResults = [
            {
                entityName: "test",
                entityId: null,
                checkedRules: [],
                problems: [
                    {
                        propertyPath: "testValue",
                        severity: "warning",
                        message: "This filed is required. Please fill it with proper information.",
                    }
                ]
            }
        ];
    }

    @State.action.bound
    public setDisable() {
        this.isDisabled = !this.isDisabled;
    }
}
