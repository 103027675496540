import React from "react";
import * as Ui from "@CommonControls";
import SourceCode from "./SourceCode";

export default class DocumentViewerExampleBox extends React.Component {

    public render() {
        return (
            <Ui.PageBox title="Document viewer">
                <Ui.PageBox.Body>
                    <Ui.Flex>
                        <Ui.Flex.Item xs={6}>
                            <p>A DocumentViewer component is used to display an HTML formatted document without any editing features.
                            It uses the same css as the DocumentEditor to produce the same result.</p>
                            <table style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th style={{width: "25%", textAlign: "left"}}>Property</th>
                                        <th style={{width: "25%", textAlign: "left"}}>Type</th>
                                        <th style={{width: "50%", textAlign: "left"}}>Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr><td><b>htmlContent</b></td><td>string</td><td>HTML formatted content document</td></tr>
                                    <tr><td>noContentString</td><td>string</td><td>No content message</td></tr>
                                    <tr><td>onLoad</td><td>{"() => void"}</td><td>Called when the content is loaded</td></tr>
                                </tbody>
                            </table>
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            <h3>Normal</h3>
                            <Ui.DocumentViewer htmlContent="<h1>Title</h1><p>This is <b>MY</b> formatted paragraph.</p>" />

                            <h3>No content</h3>
                            <Ui.DocumentViewer htmlContent="" noContentString="no-content" />
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.SingleAccordion title="Page source code">
                        <SourceCode language="html">
                            {`<Ui.DocumentViewer htmlContent="<h1>Title</h1><p>This is <b>MY</b> formatted paragraph.</p>" />

<Ui.DocumentViewer htmlContent="" noContentString="no-content" />
`}
                        </SourceCode>
                    </Ui.SingleAccordion>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}