exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SchedulerExampleBox_entry-pending_RUmwq {\n  color: #4F5B72 !important;\n  background-color: #FFFAD9 !important;\n  border-left: 6px solid #FFDC00 !important;\n}\n.SchedulerExampleBox_entry-done_BXp7M {\n  color: #4F5B72 !important;\n  background-color: #F8E5EA !important;\n  border-left: 6px solid #CE5374 !important;\n}\n.SchedulerExampleBox_entry-current_2iQh8 {\n  color: #4F5B72 !important;\n  background-color: #DDF1F7 !important;\n  border-left: 6px solid #00B4EC !important;\n}\n.SchedulerExampleBox_entry-current_2iQh8.SchedulerExampleBox_not-fit_xL6Zf {\n  background: repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 10px, rgba(0, 0, 0, 0.3) 10px, rgba(0, 0, 0, 0.3) 20px) !important;\n}\n.SchedulerExampleBox_block_3uIml {\n  color: #E8EBF2;\n  background-color: #E8EBF2;\n  border-left: 6px solid #8695A7;\n  padding-left: 4px;\n}\n.SchedulerExampleBox_container_1rVmI {\n  border: 1px solid #E8EBF2;\n}\n.SchedulerExampleBox_container_1rVmI .SchedulerExampleBox_sidebar_2HKEA {\n  background: #F4F7FC;\n  border-right: 1px solid #E8EBF2;\n  flex-basis: 26%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n}\n.SchedulerExampleBox_scroll-to-input_uzEWf {\n  flex: 1;\n  margin-top: 1.5px;\n}\n", ""]);

// exports
exports.locals = {
	"entry-pending": "SchedulerExampleBox_entry-pending_RUmwq",
	"entryPending": "SchedulerExampleBox_entry-pending_RUmwq",
	"entry-done": "SchedulerExampleBox_entry-done_BXp7M",
	"entryDone": "SchedulerExampleBox_entry-done_BXp7M",
	"entry-current": "SchedulerExampleBox_entry-current_2iQh8",
	"entryCurrent": "SchedulerExampleBox_entry-current_2iQh8",
	"not-fit": "SchedulerExampleBox_not-fit_xL6Zf",
	"notFit": "SchedulerExampleBox_not-fit_xL6Zf",
	"block": "SchedulerExampleBox_block_3uIml",
	"container": "SchedulerExampleBox_container_1rVmI",
	"sidebar": "SchedulerExampleBox_sidebar_2HKEA",
	"scroll-to-input": "SchedulerExampleBox_scroll-to-input_uzEWf",
	"scrollToInput": "SchedulerExampleBox_scroll-to-input_uzEWf"
};