import React from "react";
import { lazyResolve } from "@DiContainer";
import MultiSelectorExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/MultiSelectorExampleStore";
import * as Ui from "@CommonControls";
import UserMultiSelector from "@HisPlatform/BoundedContexts/UserManagement/Components/Controls/Users/UserMultiSelector/UserMultiSelector";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import UserId from "@Primitives/UserId.g";

@State.observer
export default class MultiSelectorExampleBox extends React.Component {
    @lazyResolve("MultiSelectorExampleStore") private store: MultiSelectorExampleStore;
    @State.observable.ref private selectedIds: UserId[] = [];
    
    @State.bound
    private onChange(selected: UserId[]) {
        this.selectedIds = selected;
    }
    public render() {
        return (
            <Ui.PageBox title="MultiSelector">
                <UserMultiSelector selectedIds={this.selectedIds} onChange={this.onChange} />
            </Ui.PageBox>
        );
    }
}
