import React from "react";
import * as Ui from "@CommonControls";
import * as HisUi from "@HisPlatformControls";
import * as DiContainer from "@DiContainer";
import ModalExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/ModalExampleStore";
import SourceCode from "./SourceCode";
import State from "@Toolkit/ReactClient/Common/StateManaging";

@State.observer
export default class ModalExampleBox extends React.Component {

    @DiContainer.lazyResolve("ModalExampleStore") private store: ModalExampleStore;

    @State.bound
    private renderLabels() {
        return (
            <>
                <Ui.Modal.Label label="Label1:">Description1</Ui.Modal.Label>
                <Ui.Modal.Label label="Label2:">Description2</Ui.Modal.Label>
            </>
        );
    }

    public render() {
        return (
            <Ui.PageBox title="Modal">
                <Ui.PageBox.Body>
                    <h3>Controlled</h3>
                    <p>In user interface design for computer applications, a modal window is a graphical control element subordinate to an application's main window. It creates a mode that disables the main window, but keeps it visible with the modal window as a child window in front of it. Users must interact with the modal window before they can return to the parent application.</p>
                    <Ui.Button automationId="" onClick={this.store.openModal}>Show simple modal</Ui.Button>
                    <Ui.Modal title="Simple modal" isOpen={this.store.isOpen} onClose={this.store.closeModal} renderHeaderLabel={this.renderLabels}>
                        <Ui.Modal.Body>
                            <h1>Hello</h1>
                            <p>This is my modal.</p>
                        </Ui.Modal.Body>
                        <Ui.Modal.Footer>
                            <Ui.Button automationId="" onClick={this.store.messageClicked} visualStyle="primary">Message</Ui.Button>
                            <Ui.Button automationId="" onClick={this.store.closeModal}>Close</Ui.Button>
                        </Ui.Modal.Footer>
                    </Ui.Modal>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
<Ui.Button onClick={this.store.openModal}>Show simple modal</Ui.Button>
<Ui.Modal title="Simple modal" isOpen={this.store.isOpen} onClose={this.store.closeModal}>
    <Ui.Modal.Body>
        <h1>Hello</h1>
        <p>This is my modal.</p>
    </Ui.Modal.Body>
    <Ui.Modal.Footer>
        <Ui.Button onClick={this.store.messageClicked} visualStyle="primary">Message</Ui.Button>
        <Ui.Button onClick={this.store.closeModal}>Close</Ui.Button>
    </Ui.Modal.Footer>
</Ui.Modal>
`}
                        </SourceCode>
                        <SourceCode language="typescript">
                            {`
@State.observable public isOpen = false;

@State.action.bound
public openModal() {
    this.isOpen = true;
}

@State.action.bound
public closeModal() {
    this.isOpen = false;
}
`}
                        </SourceCode>
                    </Ui.SingleAccordion>
                    <hr />
                    <h3>Uncontrolled</h3>
                    <Ui.Modal title="Uncontrolled modal" triggerElement={<Ui.Button automationId="">I am the trigger of the modal</Ui.Button>}>
                        <Ui.Modal.Body>
                            <h1>Hello</h1>
                            <p>This is my uncontrolled modal.</p>
                        </Ui.Modal.Body>
                        <Ui.Modal.Footer>
                            <Ui.Button automationId="" onClick={this.store.messageClicked} visualStyle="primary">Message</Ui.Button>
                            <Ui.Modal.Button automationId="" closeModalOnClick>Close</Ui.Modal.Button>
                        </Ui.Modal.Footer>
                    </Ui.Modal>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
<Ui.Modal title="Uncontrolled modal" trigger={<Ui.Button>I am the trigger of the modal</Ui.Button>}>
    <Ui.Modal.Body>
        <h1>Hello</h1>
        <p>This is my uncontrolled modal.</p>
    </Ui.Modal.Body>
    <Ui.Modal.Footer>
        <Ui.Button onClick={this.store.messageClicked} visualStyle="primary">Message</Ui.Button>
        <Ui.Modal.Button onClick={this.store.uncontrolledCloseClicked}>Close</Ui.Modal.Button>
    </Ui.Modal.Footer>
</Ui.Modal>
`}
                        </SourceCode>
                        <SourceCode language="typescript">
                            {`
@State.action.bound
public uncontrolledCloseClicked(closeModal: () => void) {
    alert("Closing uncontrolled modal");
    closeModal();
}
`}
                        </SourceCode>
                    </Ui.SingleAccordion>
                    <hr />
                    <h3>Size</h3>
                    <Ui.Modal title="Uncontrolled modal" triggerElement={<Ui.Button automationId="">Normal size</Ui.Button>}>
                        <Ui.Modal.Body>
                            <p>Normal</p>
                        </Ui.Modal.Body>
                    </Ui.Modal>
                    <Ui.Modal title="Uncontrolled modal" triggerElement={<Ui.Button automationId="">Compact size</Ui.Button>} size="compact">
                        <Ui.Modal.Body>
                            <p>Compact</p>
                        </Ui.Modal.Body>
                    </Ui.Modal>
                    <Ui.Modal title="Uncontrolled modal" triggerElement={<Ui.Button automationId="">Fullscreen size</Ui.Button>} size="fullscreen">
                        <Ui.Modal.Body>
                            <p>Fullscreen</p>
                        </Ui.Modal.Body>
                    </Ui.Modal>
                    <Ui.SingleAccordion title="Source">
                        <SourceCode language="html">
                            {`
 <Ui.Modal title="Uncontrolled modal" triggerElement={<Ui.Button>Normal size</Ui.Button>}>
    <Ui.Modal.Body>
        <p>Normal</p>
    </Ui.Modal.Body>
</Ui.Modal>
<Ui.Modal title="Uncontrolled modal" triggerElement={<Ui.Button>Compact size</Ui.Button>} size="compact">
    <Ui.Modal.Body>
        <p>Compact</p>
    </Ui.Modal.Body>
</Ui.Modal>
<Ui.Modal title="Uncontrolled modal" triggerElement={<Ui.Button>Fullscreen size</Ui.Button>} size="fullscreen">
    <Ui.Modal.Body>
        <p>Fullscreen</p>
    </Ui.Modal.Body>
</Ui.Modal>
`}
                        </SourceCode>
                    </Ui.SingleAccordion>
                    <HisUi.ContextAwareModal
                        title="Context Aware Modal"
                        triggerElement={<Ui.Button automationId="">Trigger</Ui.Button>}
                        renderHeaderLabel={this.renderLabels}
                        size="normal"
                    >
                        <Ui.Modal.Body>
                            <p>Teszt</p>
                        </Ui.Modal.Body>
                    </HisUi.ContextAwareModal>
                </Ui.PageBox.Body>
            </Ui.PageBox>
        );
    }
}