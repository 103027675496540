import * as Ui from "@CommonControls";
import React from "react";
import ToastExampleBox from "./ExampleBoxes/ToastExampleBox";
import DialogExampleBox from "./ExampleBoxes/DialogExampleBox";
import ModalExampleBox from "./ExampleBoxes/ModalExampleBox";
import TabControlExampleBox from "./ExampleBoxes/TabControlExampleBox";
import ContextMenuExampleBox from "./ExampleBoxes/ContextMenuExampleBox";
import SelectorExampleBox from "./ExampleBoxes/SelectorExampleBox";
import TextBoxExampleBox from "./ExampleBoxes/TextBoxExampleBox";
import SelectBoxExampleBox from "./ExampleBoxes/SelectBoxExampleBox";
import DatePickerExampleBox from "./ExampleBoxes/DatePickerExampleBox";
import AccordionExampleBox from "./ExampleBoxes/AccordionExampleBox";
import CheckBoxExampleBox from "./ExampleBoxes/CheckBoxExampleBox";
import CheckBoxGroupExampleBox from "./ExampleBoxes/CheckBoxGroupExampleBox";
import DataGridExampleBox from "./ExampleBoxes/DataGrid/DataGridExampleBox";
import FlexExampleBox from "./ExampleBoxes/FlexExampleBox";
import ButtonExampleBox from "./ExampleBoxes/ButtonExampleBox";
import IconExampleBox from "./ExampleBoxes/IconExampleBox";
import NumberBoxExampleBox from "./ExampleBoxes/NumberBoxExampleBox";
import DateRangePickerExampleBox from "./ExampleBoxes//DateRangePickerExampleBox";
import AlertCardExampleBox from "./ExampleBoxes/AlertCardExampleBox";
import WorkflowStepsIndicatorExampleBox from "./ExampleBoxes/WorkflowStepsIndicatorExampleBox";
import BadgeExamplebox from "./ExampleBoxes/BadgeExampleBox";
import SidebarExampleBox from "./ExampleBoxes/SidebarExampleBox";
import PageTitleExampleBox from "./ExampleBoxes/PageTitleExampleBox";
import PageBoxExampleBox from "./ExampleBoxes/PageBoxExampleBox";
import DocumentViewerExampleBox from "./ExampleBoxes/DocumentViewerExampleBox";
import UniversalCodeSelectorExampleBox from "./ExampleBoxes/UniversalCodeSelectorExampleBox";
import MessageExampleBox from "./ExampleBoxes/MessageExampleBox";
import TableExampleBox from "./ExampleBoxes/TableExampleBox";
import TooltipExampleBox from "./ExampleBoxes/TooltipExampleBox";
import LoadingIndicatorExampleBox from "./ExampleBoxes/LoadingIndicatorExampleBox";
import MultiSelectorExampleBox from "./ExampleBoxes/MultiSelectorExampleBox";
import IRoutingFrameContentProps from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingFrameContentProps";
import RoutingFrame from "@Toolkit/ReactClient/Routing/RoutingFrame";
import DeveloperPageRoutes from "@HisPlatform/Components/Pages/DeveloperPage/DeveloperPageRoutes";
import PdfViewerExampleBoxLoader from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/PdfViewerExampleBoxLoader";
import DrawerExampleBox from "./ExampleBoxes/DrawerExampleBox";
import RadioButtonExampleBox from "./ExampleBoxes/RadioButtonExampleBox";
import ListPanelExampleBox from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/ListPanelExampleBox";
import SettlementTextboxExampleBox from "./ExampleBoxes/SettlementTextboxExampleBox";
import SideMenuExampleBox from "./ExampleBoxes/SideMenuExampleBox";
import SideMenuItem from "@CommonControls/SideMenu/SideMenuItem";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import { IRoutingController } from "@Toolkit/ReactClient/Routing/Abstractions/IRoutingController";
import IRouteDefinition from "@Toolkit/ReactClient/Routing/Abstractions/IRouteDefinition";
import GroupBoxExampleBox from "./ExampleBoxes/GroupBoxExampleBox";
import DeveloperPageSideMenuItem from "@HisPlatform/Components/Pages/DeveloperPage/View/DeveloperPageSideMenuItem";
import DocumentViewerModalExampleBox from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/DocumentViewModalExampleBox";
import _ from "@HisPlatform/Common/Lodash";
import DateTimePicker2ExampleBox from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/DateTimePicker2ExampleBox";
import HeaderExampleBox from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/HeaderExamplebox";
import ExtensionPointExampleBox from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/ExtensionPointExampleBox";
import TreeViewExampleBox from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/TreeViewExampleBox";
import ChartExampleBox from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/ChartExampleBox";
import InfoButtonExampleBox from "./ExampleBoxes/InfoButtonExampleBox";
import PatientId from "@Primitives/PatientId.g";
import ValidationResultExampleBox from "./ExampleBoxes/ValidationResultExampleBox";
import LedExamplebox from "./ExampleBoxes/LedExampleBox";
import MasterDetailExampleBox from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/MasterDetailExampleBox";
import SignalRExampleBox from "./ExampleBoxes/SignalRExampleBox";
import PdfViewer2ExampleBox from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/PdfViewer2ExampleBox";
import DocumentEditorExampleBox from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/DocumentEditorExampleBox";
import TreeGridExampleBox from "./ExampleBoxes/TreeGridExampleBox";
import SchedulerExampleBox from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/SchedulerExampleBox";
import TitleGroupExampleBox from "./ExampleBoxes/TitleGroupExampleBox";
import InfoBoxExampleBox from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/InfoBoxExampleBox";
import MonthPickerExampleBox from "./ExampleBoxes/MonthPickerExampleBox";
import DurationBoxExampleBox from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/DurationBoxExampleBox";
import FrontendActionsExample from "./ExampleBoxes/FrontendActions/FrontendActionsExample";
import FinancedServiceCodeSelectorExampleBox from "./ExampleBoxes/FinancedServiceCodeSelectorExampleBox";
import MoneyExampleBox from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/MoneyExampleBox";

@State.observer
export default class DeveloperPage extends React.Component<IRoutingFrameContentProps> {

    @State.observable.ref private currentRouteDefinition: IRouteDefinition = null;

    private pages = _.orderBy([
        new DeveloperPageSideMenuItem("Icon", DeveloperPageRoutes.icon, this.props.routingController),
        new DeveloperPageSideMenuItem("AlertCard", DeveloperPageRoutes.alertCard, this.props.routingController),
        new DeveloperPageSideMenuItem("Button", DeveloperPageRoutes.button, this.props.routingController),
        new DeveloperPageSideMenuItem("DataGrid", DeveloperPageRoutes.dataGrid, this.props.routingController),
        new DeveloperPageSideMenuItem("Toast", DeveloperPageRoutes.toast, this.props.routingController),
        new DeveloperPageSideMenuItem("Dialog", DeveloperPageRoutes.dialog, this.props.routingController),
        new DeveloperPageSideMenuItem("Modal", DeveloperPageRoutes.modal, this.props.routingController),
        new DeveloperPageSideMenuItem("Flex", DeveloperPageRoutes.flex, this.props.routingController),
        new DeveloperPageSideMenuItem("WorkflowSteps", DeveloperPageRoutes.workflowStepsIndicator, this.props.routingController),
        new DeveloperPageSideMenuItem("TabControl", DeveloperPageRoutes.tabControl, this.props.routingController),
        new DeveloperPageSideMenuItem("ContextMenu", DeveloperPageRoutes.contextMenu, this.props.routingController),
        new DeveloperPageSideMenuItem("Selector", DeveloperPageRoutes.selector, this.props.routingController),
        new DeveloperPageSideMenuItem("TextBox", DeveloperPageRoutes.textBox, this.props.routingController),
        new DeveloperPageSideMenuItem("DateRangePicker", DeveloperPageRoutes.dateRangePicker, this.props.routingController),
        new DeveloperPageSideMenuItem("DatePicker", DeveloperPageRoutes.datePicker, this.props.routingController),
        new DeveloperPageSideMenuItem("Accordion", DeveloperPageRoutes.accordion, this.props.routingController),
        new DeveloperPageSideMenuItem("CheckBox", DeveloperPageRoutes.checkBox, this.props.routingController),
        new DeveloperPageSideMenuItem("CheckBoxGroup", DeveloperPageRoutes.checkBoxGroup, this.props.routingController),
        new DeveloperPageSideMenuItem("SelectBox", DeveloperPageRoutes.selectBox, this.props.routingController),
        new DeveloperPageSideMenuItem("NumberBox", DeveloperPageRoutes.numberBox, this.props.routingController),
        new DeveloperPageSideMenuItem("UniversalCodeSelector", DeveloperPageRoutes.universalCodeSelector, this.props.routingController),
        new DeveloperPageSideMenuItem("FinancedServiceCodeSelector", DeveloperPageRoutes.financedServiceCodeSelector, this.props.routingController),
        new DeveloperPageSideMenuItem("Badge", DeveloperPageRoutes.badge, this.props.routingController),
        new DeveloperPageSideMenuItem("Sidebar", DeveloperPageRoutes.sidebar, this.props.routingController),
        new DeveloperPageSideMenuItem("PdfViewer", DeveloperPageRoutes.pdfViewer, this.props.routingController),
        new DeveloperPageSideMenuItem("PageTitle", DeveloperPageRoutes.pageTitle, this.props.routingController),
        new DeveloperPageSideMenuItem("PageBox", DeveloperPageRoutes.pageBox, this.props.routingController),
        new DeveloperPageSideMenuItem("DocumentViewer", DeveloperPageRoutes.documentViewer, this.props.routingController),
        new DeveloperPageSideMenuItem("DocumentViewerModal", DeveloperPageRoutes.documentViewerModal, this.props.routingController),
        new DeveloperPageSideMenuItem("Message", DeveloperPageRoutes.message, this.props.routingController),
        new DeveloperPageSideMenuItem("Table", DeveloperPageRoutes.table, this.props.routingController),
        new DeveloperPageSideMenuItem("Tooltip", DeveloperPageRoutes.tooltip, this.props.routingController),
        new DeveloperPageSideMenuItem("Loading indicator", DeveloperPageRoutes.loadingIndicator, this.props.routingController),
        new DeveloperPageSideMenuItem("MultiSelector", DeveloperPageRoutes.multiSelector, this.props.routingController),
        new DeveloperPageSideMenuItem("Drawer", DeveloperPageRoutes.drawer, this.props.routingController),
        new DeveloperPageSideMenuItem("Radio button", DeveloperPageRoutes.radioButton, this.props.routingController),
        new DeveloperPageSideMenuItem("ListPanel", DeveloperPageRoutes.listPanel, this.props.routingController),
        new DeveloperPageSideMenuItem("SettlementTextbox", DeveloperPageRoutes.settlementTextbox, this.props.routingController),
        new DeveloperPageSideMenuItem("SideMenu", DeveloperPageRoutes.sideMenuTextbox, this.props.routingController),
        new DeveloperPageSideMenuItem("GroupBox", DeveloperPageRoutes.groupBox, this.props.routingController),
        new DeveloperPageSideMenuItem("DateTimePicker2", DeveloperPageRoutes.dateTimePicker2, this.props.routingController),
        new DeveloperPageSideMenuItem("Header", DeveloperPageRoutes.header, this.props.routingController),
        new DeveloperPageSideMenuItem("ExtensionPoint", DeveloperPageRoutes.extensionPoint, this.props.routingController),
        new DeveloperPageSideMenuItem("TreeGrid", DeveloperPageRoutes.treeGrid, this.props.routingController),
        new DeveloperPageSideMenuItem("TreeView", DeveloperPageRoutes.treeView, this.props.routingController),
        new DeveloperPageSideMenuItem("Chart", DeveloperPageRoutes.chart, this.props.routingController),
        new DeveloperPageSideMenuItem("InfoButton", DeveloperPageRoutes.infoButton, this.props.routingController),
        new DeveloperPageSideMenuItem("ValidationResultSummary", DeveloperPageRoutes.validationResultSummary, this.props.routingController),
        new DeveloperPageSideMenuItem("MasterDetail", DeveloperPageRoutes.masterDetail, this.props.routingController),
        new DeveloperPageSideMenuItem("Led", DeveloperPageRoutes.led, this.props.routingController),
        new DeveloperPageSideMenuItem("DocumentEditor", DeveloperPageRoutes.documentEditor, this.props.routingController),
        new DeveloperPageSideMenuItem("SignalR", DeveloperPageRoutes.signalr, this.props.routingController),
        new DeveloperPageSideMenuItem("PdfViewer2", DeveloperPageRoutes.pdfViewer2, this.props.routingController),
        new DeveloperPageSideMenuItem("Scheduler", DeveloperPageRoutes.scheduler, this.props.routingController),
        new DeveloperPageSideMenuItem("TitleGroup", DeveloperPageRoutes.titleGroup, this.props.routingController),
        new DeveloperPageSideMenuItem("InfoBox", DeveloperPageRoutes.infoBox, this.props.routingController),
        new DeveloperPageSideMenuItem("MonthPicker", DeveloperPageRoutes.monthPicker, this.props.routingController),
        new DeveloperPageSideMenuItem("DurationBox", DeveloperPageRoutes.durationBox, this.props.routingController),
        new DeveloperPageSideMenuItem("FrontendActions", DeveloperPageRoutes.frontendActions, this.props.routingController),
        new DeveloperPageSideMenuItem("Money", DeveloperPageRoutes.money, this.props.routingController),
    ], i => i.text);

    @State.action.bound
    private routeChanged(controller: IRoutingController) {
        if (controller.currentRoute) {
            this.currentRouteDefinition = controller.currentRoute.definition;
        }
    }

    private hisPatientId = new PatientId("1");

    public render() {
        return (
            <Ui.PageRoot>
                <Ui.SideMenu style={{ position: "fixed", left: 0, zIndex: 100, width: "16%", height: "calc(100vh - 40px)" }}>
                    {this.pages.map((p, index) => (
                        <SideMenuItem key={index} isActive={this.currentRouteDefinition === p.routeDefinition} text={p.text} onClick={p.clickHandler} />
                    ))}
                </Ui.SideMenu>

                <div style={{ marginLeft: "16%" }}>
                    <RoutingFrame routeDefinitions={DeveloperPageRoutes} onRoutingControllerChanged={this.routeChanged}>
                        <RoutingFrame.Case route={DeveloperPageRoutes.icon} component={IconExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.alertCard} component={AlertCardExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.button} component={ButtonExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.dataGrid} component={DataGridExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.toast} component={ToastExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.dialog} component={DialogExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.modal} component={ModalExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.documentViewerModal} component={DocumentViewerModalExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.flex} component={FlexExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.workflowStepsIndicator} component={WorkflowStepsIndicatorExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.tabControl} component={TabControlExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.contextMenu} component={ContextMenuExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.selector} component={SelectorExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.textBox} component={TextBoxExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.datePicker} component={DatePickerExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.dateRangePicker} component={DateRangePickerExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.accordion} component={AccordionExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.checkBox} component={CheckBoxExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.checkBoxGroup} component={CheckBoxGroupExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.selectBox} component={SelectBoxExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.numberBox} component={NumberBoxExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.universalCodeSelector} component={UniversalCodeSelectorExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.financedServiceCodeSelector} component={FinancedServiceCodeSelectorExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.badge} component={BadgeExamplebox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.sidebar} component={SidebarExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.pdfViewer} component={PdfViewerExampleBoxLoader as any} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.pageTitle} component={PageTitleExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.pageBox} component={PageBoxExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.documentViewer} component={DocumentViewerExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.message} component={MessageExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.table} component={TableExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.tooltip} component={TooltipExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.loadingIndicator} component={LoadingIndicatorExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.multiSelector} component={MultiSelectorExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.drawer} component={DrawerExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.radioButton} component={RadioButtonExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.listPanel} component={ListPanelExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.settlementTextbox} component={SettlementTextboxExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.sideMenuTextbox} component={SideMenuExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.groupBox} component={GroupBoxExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.dateTimePicker2} component={DateTimePicker2ExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.header} component={HeaderExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.extensionPoint} component={ExtensionPointExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.treeGrid} component={TreeGridExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.treeView} component={TreeViewExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.chart} component={ChartExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.infoButton} component={InfoButtonExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.validationResultSummary} component={ValidationResultExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.masterDetail} component={MasterDetailExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.led} component={LedExamplebox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.documentEditor} component={DocumentEditorExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.signalr} component={SignalRExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.pdfViewer2} component={PdfViewer2ExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.scheduler} component={SchedulerExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.titleGroup} component={TitleGroupExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.infoBox} component={InfoBoxExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.monthPicker} component={MonthPickerExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.durationBox} component={DurationBoxExampleBox} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.frontendActions} component={FrontendActionsExample as any} />
                        <RoutingFrame.Case route={DeveloperPageRoutes.money} component={MoneyExampleBox as any} />
                    </RoutingFrame>
                </div>
            </Ui.PageRoot>
        );
    }
}
