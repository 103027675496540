import React from "react";
import * as Ui from "@CommonControls";
import { Calendar, TimeEditor, DatePicker } from "@CommonControls/DateTimePicker/DateTimePicker";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import SourceCode from "@HisPlatform/Components/Pages/DeveloperPage/View/ExampleBoxes/SourceCode";
import moment from "moment";
import TimeOfDay from "@Toolkit/CommonWeb/TimeOfDay";
import LocalDateRange from "@Toolkit/CommonWeb/LocalDateRange";
import DateRangePicker from "@CommonControls/DateRangePicker/DateRangePicker";
import Styles from "./CalendarStyles.less";
import ICalendarDayStyle from "@CommonControls/DateTimePicker/ICalendarDayStyle";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";

@State.observer
export default class DateTimePicker2ExampleBox extends React.Component {

    @State.observable.ref private calendarValue = LocalDate.today();
    @State.observable.ref private timeValue = new TimeOfDay(12, 0);

    @State.observable.ref private dateTimeValue = DateTimeService.now();
    @State.observable.ref private dateValue = LocalDate.today();

    @State.observable.ref private dateRangeValue = new LocalDateRange(LocalDate.today(), LocalDate.today());

    @State.observable.ref private calendarStyles = [
        { day: LocalDate.today().minusDays(21).toUtcDayStartMoment(), className: Styles.low } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(20).toUtcDayStartMoment(), className: Styles.low } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(19).toUtcDayStartMoment(), className: Styles.low } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(18).toUtcDayStartMoment(), className: Styles.low } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(17).toUtcDayStartMoment(), className: Styles.low } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(16).toUtcDayStartMoment(), className: Styles.low } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(15).toUtcDayStartMoment(), className: Styles.low } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(14).toUtcDayStartMoment(), className: Styles.medium } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(13).toUtcDayStartMoment(), className: Styles.medium } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(12).toUtcDayStartMoment(), className: Styles.medium } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(11).toUtcDayStartMoment(), className: Styles.medium } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(10).toUtcDayStartMoment(), className: Styles.medium } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(9).toUtcDayStartMoment(), className: Styles.medium } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(8).toUtcDayStartMoment(), className: Styles.medium } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(7).toUtcDayStartMoment(), className: Styles.high } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(6).toUtcDayStartMoment(), className: Styles.high } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(5).toUtcDayStartMoment(), className: Styles.high } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(4).toUtcDayStartMoment(), className: Styles.high } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(3).toUtcDayStartMoment(), className: Styles.high } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(2).toUtcDayStartMoment(), className: Styles.high } as ICalendarDayStyle,
        { day: LocalDate.today().minusDays(1).toUtcDayStartMoment(), className: Styles.high } as ICalendarDayStyle,
    ];

    @State.action.bound
    private setCalendarValue(newValue: LocalDate) {
        this.calendarValue = newValue;
    }

    @State.action.bound
    private setTimePickerValue(newValue: TimeOfDay) {
        this.timeValue = newValue;
    }

    @State.action.bound
    private setDateValue(newValue: LocalDate) {
        this.dateValue = newValue;
    }

    @State.action.bound
    private setDateTimeValue(newValue: moment.Moment) {
        this.dateTimeValue = newValue;
    }

    @State.action.bound
    private setDateRangeValue(newValue: LocalDateRange) {
        this.dateRangeValue = newValue;
    }

    public render() {
        return (
            <Ui.PageBox title="DateTimePicker2">
                <Ui.PageBox.Body>
                    <Ui.Flex spacing="regular">
                        <Ui.Flex.Item xs={6}>
                            {this.renderCalendar()}
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            {this.renderTimeEditor()}
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex spacing="regular">
                        <Ui.Flex.Item xs={6}>
                            {this.renderDatePicker()}
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            {this.renderDateTimePicker()}
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex spacing="regular">
                        <Ui.Flex.Item xs={6}>
                            {this.renderDateRangePicker2()}
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            {this.renderDateRangePicker2DarkMode()}
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <Ui.Flex spacing="regular">
                        <Ui.Flex.Item xs={6}>
                            {this.renderTimePicker()}
                        </Ui.Flex.Item>
                        <Ui.Flex.Item xs={6}>
                            yo
                        </Ui.Flex.Item>
                    </Ui.Flex>
                    <br /><br /><br /><br /><br /><br /><br />
                </Ui.PageBox.Body>
            </Ui.PageBox >
        );
    }

    private renderTimePicker() {
        return (
            <Ui.GroupBox title="TimePicker" hasBorder>
                <Ui.Flex>
                    <Ui.Flex.Item xs={4}>
                        <Ui.TimePicker
                            label="Time value"
                            value={this.timeValue}
                            onChange={this.setTimePickerValue}
                            automationId=""
                        />
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.GroupBox>
        );
    }

    private renderCalendar() {
        return (
            <Ui.GroupBox title="Calendar" hasBorder>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <Calendar
                            value={this.calendarValue}
                            onChange={this.setCalendarValue}
                            calendarDayStyles={this.calendarStyles}
                        />
                    </Ui.Flex.Item>

                    <Ui.Flex.Item xs={6}>
                        <SourceCode language="json">
                            {JSON.stringify({
                                value: this.calendarValue
                            }, null, "    ")}
                        </SourceCode>
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.GroupBox>
        );
    }

    private renderTimeEditor() {
        return (
            <Ui.GroupBox title="TimeEditor" hasBorder>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <TimeEditor
                            value={this.timeValue}
                            onChange={this.setTimePickerValue}
                            variant="hour-minute"
                        />
                    </Ui.Flex.Item>

                    <Ui.Flex.Item xs={6}>
                        <SourceCode language="json">
                            {JSON.stringify({
                                value: this.timeValue
                            }, null, "    ")}
                        </SourceCode>
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.GroupBox>
        );
    }

    private renderDatePicker() {
        return (
            <Ui.GroupBox title="DatePickerInput" hasBorder>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <DatePicker
                            label="Date value"
                            value={this.dateValue}
                            onChange={this.setDateValue}
                            automationId=""
                        />
                    </Ui.Flex.Item>

                    <Ui.Flex.Item xs={6}>
                        <SourceCode language="json">
                            {JSON.stringify({
                                value: this.dateValue
                            }, null, "    ")}
                        </SourceCode>
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.GroupBox>
        );
    }

    private renderDateTimePicker() {
        return (
            <Ui.GroupBox title="DateTimePickerInput" hasBorder>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <Ui.DateTimePicker
                            label="Date value"
                            value={this.dateTimeValue}
                            onChange={this.setDateTimeValue}
                            automationId=""
                        />
                    </Ui.Flex.Item>

                    <Ui.Flex.Item xs={6}>
                        <SourceCode language="json">
                            {JSON.stringify({
                                value: this.dateTimeValue
                            }, null, "    ")}
                        </SourceCode>
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.GroupBox>
        );
    }

    private renderDateRangePicker2() {
        return (
            <Ui.GroupBox title="DateRangePicker2" hasBorder>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <DateRangePicker
                            value={this.dateRangeValue}
                            onChange={this.setDateRangeValue}
                            automationId=""
                        />
                    </Ui.Flex.Item>

                    <Ui.Flex.Item xs={6}>
                        <SourceCode language="json">
                            {JSON.stringify({
                                value: this.dateRangeValue
                            }, null, "    ")}
                        </SourceCode>
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.GroupBox>

        );
    }

    private renderDateRangePicker2DarkMode() {
        return (
            <Ui.GroupBox title="DateRangePicker2 dark mode" hasBorder>
                <Ui.Flex>
                    <Ui.Flex.Item xs={6}>
                        <DateRangePicker
                            value={this.dateRangeValue}
                            onChange={this.setDateRangeValue}
                            visualMode="dark"
                            automationId=""
                        />
                    </Ui.Flex.Item>

                    <Ui.Flex.Item xs={6}>
                        <SourceCode language="json">
                            {JSON.stringify({
                                value: this.dateRangeValue
                            }, null, "    ")}
                        </SourceCode>
                    </Ui.Flex.Item>
                </Ui.Flex>
            </Ui.GroupBox>
        );
    }

}