import React from "react";
import * as Ui from "@CommonControls";
import DrawerExampleStore from "@HisPlatform/Components/Pages/DeveloperPage/Store/DrawerExampleStore";
import * as DiContainer from "@DiContainer";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import SourceCode from "./SourceCode";

@State.observer
export default class DrawerExampleBox extends React.Component {

    @DiContainer.lazyResolve("DrawerExampleStore") private store: DrawerExampleStore;

    private get buttonText() {
        if (this.store.isOpenDocked) {
            return "close";
        } else {
            return "open";
        }
    }

    public render() {
        const contentUndocked = (
            <div>
                <Ui.Message type="primary">Undocked drawer content</Ui.Message>
                <Ui.Button automationId="" onClick={this.store.setIsOpen} text="close" />
            </div>
        );
        const contentDocked = (
            <div>
                <Ui.Message type="primary">Docked drawer content</Ui.Message>
            </div>
        );
        return (
            <>
                <Ui.Drawer
                    containerStyle={{position: "relative", height: "300px"}}
                    content={contentUndocked}
                    isOpen={this.store.isOpen}
                    position={"left"}
                    onClose={this.store.setIsOpen}>
                    <div style={{ minHeight: "300px", backgroundColor: "white", padding: "10px" }}>
                        <div>
                            <h3>Undocked drawer</h3><Ui.Button automationId="" onClick={this.store.setIsOpen} text="open" /><br />
                            <p>A pleura-sinusokban folyadék nem látszik, az ábrázolódó tüdőterületeken kóros nem mutatható ki. A máj nagysága, szerkezete, denzitása szabályos, benne körülírt gócos eltérés nem igazolható. Az epeutak lefutása, tágassága szabályos. Az epehólyag normális nagyságú és falvastagságú, benne híg folyadék denzitás mérhető. A pancreas alakja, nagysága, szerkezete szabályos, környezete reakciómentes, állományában térfoglaló folyamat vagy egyéb kóros nem látható. A Wirsung-vezeték nem tágabb. A lép normális nagyságú és szerkezetű. A mellékvesék alakja, nagysága kóros eltérést nem mutat. A vesék alakja, nagysága, helyzete, struktúrája, kontrasztanyag-halmozása és -kiválasztása szabályos, az üregrendszer szabályosan/részlegesen telődött, tágulat nem igazolható. A natív felvételeken kő, meszesedés nem mutatható ki. Ureter-tágulat nem ábrázolható. Az uterus és az adnexumok / prostata alakja, nagysága, denzitása szabályos. A hólyag nagysága, falvastagsága normális. A kismedencei zsírszövet nem infiltrált. A gastrointestinalis traktus megítélhető szakaszai kóros eltérést nem mutatnak. A hasi és kismedencei erek lefutása, átmérője szabályos. A vizsgált régióban kóros folyadékgyülem, megnagyobbodott nyirokcsomó nem mutatható ki. Az ábrázolódó csontok alakja, szerkezete, denzitása szabályos. Vélemény: negatív lelet.</p>
                        </div>
                    </div >
                </Ui.Drawer>
                <Ui.Drawer
                    containerStyle={{position: "relative", height: "300px"}}
                    content={contentDocked}
                    isOpen={this.store.isOpenDocked}
                    position={"left"}
                    isDocked>
                    <div style={{ minHeight: "300px", backgroundColor: "white", padding: "10px" }}>
                        <div>
                            <h3>Docked drawer</h3><Ui.Button automationId="" onClick={this.store.setIsOpenDocked} text={this.buttonText} /><br />
                            <p>A pleura-sinusokban folyadék nem látszik, az ábrázolódó tüdőterületeken kóros nem mutatható ki. A máj nagysága, szerkezete, denzitása szabályos, benne körülírt gócos eltérés nem igazolható. Az epeutak lefutása, tágassága szabályos. Az epehólyag normális nagyságú és falvastagságú, benne híg folyadék denzitás mérhető. A pancreas alakja, nagysága, szerkezete szabályos, környezete reakciómentes, állományában térfoglaló folyamat vagy egyéb kóros nem látható. A Wirsung-vezeték nem tágabb. A lép normális nagyságú és szerkezetű. A mellékvesék alakja, nagysága kóros eltérést nem mutat. A vesék alakja, nagysága, helyzete, struktúrája, kontrasztanyag-halmozása és -kiválasztása szabályos, az üregrendszer szabályosan/részlegesen telődött, tágulat nem igazolható. A natív felvételeken kő, meszesedés nem mutatható ki. Ureter-tágulat nem ábrázolható. Az uterus és az adnexumok / prostata alakja, nagysága, denzitása szabályos. A hólyag nagysága, falvastagsága normális. A kismedencei zsírszövet nem infiltrált. A gastrointestinalis traktus megítélhető szakaszai kóros eltérést nem mutatnak. A hasi és kismedencei erek lefutása, átmérője szabályos. A vizsgált régióban kóros folyadékgyülem, megnagyobbodott nyirokcsomó nem mutatható ki. Az ábrázolódó csontok alakja, szerkezete, denzitása szabályos. Vélemény: negatív lelet.</p>
                        </div>
                    </div >
                </Ui.Drawer>
                <Ui.SingleAccordion title="Source">
                    <SourceCode language="html">
                        {`
            <Ui.Drawer
                height={"300px"}
                content={contentUndocked}
                isOpen={this.store.isOpen}
                position={"left"}
                onClose={this.store.setIsOpen}>
                <div style={{ minHeight: "300px", backgroundColor: "white", padding: "10px" }}>
                    <div>
                        <h3>Undocked drawer</h3><Ui.Button onClick={this.store.setIsOpen} text="open" /><br />
                        <p>A pleura-sinusokban folyadék nem látszik, az ábrázolódó tüdőterületeken kóros nem mutatható ki. A máj nagysága, szerkezete, denzitása szabályos, benne körülírt gócos eltérés nem igazolható. Az epeutak lefutása, tágassága szabályos. Az epehólyag normális nagyságú és falvastagságú, benne híg folyadék denzitás mérhető. A pancreas alakja, nagysága, szerkezete szabályos, környezete reakciómentes, állományában térfoglaló folyamat vagy egyéb kóros nem látható. A Wirsung-vezeték nem tágabb. A lép normális nagyságú és szerkezetű. A mellékvesék alakja, nagysága kóros eltérést nem mutat. A vesék alakja, nagysága, helyzete, struktúrája, kontrasztanyag-halmozása és -kiválasztása szabályos, az üregrendszer szabályosan/részlegesen telődött, tágulat nem igazolható. A natív felvételeken kő, meszesedés nem mutatható ki. Ureter-tágulat nem ábrázolható. Az uterus és az adnexumok / prostata alakja, nagysága, denzitása szabályos. A hólyag nagysága, falvastagsága normális. A kismedencei zsírszövet nem infiltrált. A gastrointestinalis traktus megítélhető szakaszai kóros eltérést nem mutatnak. A hasi és kismedencei erek lefutása, átmérője szabályos. A vizsgált régióban kóros folyadékgyülem, megnagyobbodott nyirokcsomó nem mutatható ki. Az ábrázolódó csontok alakja, szerkezete, denzitása szabályos. Vélemény: negatív lelet.</p>
                    </div>
                </div >
            </Ui.Drawer>
            <Ui.Drawer
                height={"300px"}
                content={contentDocked}
                isOpen={this.store.isOpenDocked}
                position={"left"}
                isDocked>
                <div style={{ minHeight: "300px", backgroundColor: "white", padding: "10px" }}>
                    <div>
                        <h3>Docked drawer</h3><Ui.Button onClick={this.store.setIsOpenDocked} text={this.buttonText} /><br />
                        <p>A pleura-sinusokban folyadék nem látszik, az ábrázolódó tüdőterületeken kóros nem mutatható ki. A máj nagysága, szerkezete, denzitása szabályos, benne körülírt gócos eltérés nem igazolható. Az epeutak lefutása, tágassága szabályos. Az epehólyag normális nagyságú és falvastagságú, benne híg folyadék denzitás mérhető. A pancreas alakja, nagysága, szerkezete szabályos, környezete reakciómentes, állományában térfoglaló folyamat vagy egyéb kóros nem látható. A Wirsung-vezeték nem tágabb. A lép normális nagyságú és szerkezetű. A mellékvesék alakja, nagysága kóros eltérést nem mutat. A vesék alakja, nagysága, helyzete, struktúrája, kontrasztanyag-halmozása és -kiválasztása szabályos, az üregrendszer szabályosan/részlegesen telődött, tágulat nem igazolható. A natív felvételeken kő, meszesedés nem mutatható ki. Ureter-tágulat nem ábrázolható. Az uterus és az adnexumok / prostata alakja, nagysága, denzitása szabályos. A hólyag nagysága, falvastagsága normális. A kismedencei zsírszövet nem infiltrált. A gastrointestinalis traktus megítélhető szakaszai kóros eltérést nem mutatnak. A hasi és kismedencei erek lefutása, átmérője szabályos. A vizsgált régióban kóros folyadékgyülem, megnagyobbodott nyirokcsomó nem mutatható ki. Az ábrázolódó csontok alakja, szerkezete, denzitása szabályos. Vélemény: negatív lelet.</p>
                    </div>
                </div >
            </Ui.Drawer>
`}
                    </SourceCode>
                </Ui.SingleAccordion>
            </>
        );
    }
}