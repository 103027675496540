import Button from "@CommonControls/Button";
import { IScreenPropsBase } from "@HisPlatform/Services/Definition/ActionProcessing/IScreenRegistry";
import { useScreenNavigationContext } from "@HisPlatform/Components/ShowScreenAction/ScreenNavigationContext";
import Screen from "@Toolkit/ReactClient/Components/Screen/Screen";
import React, { useCallback, useMemo, useState } from "react";
import ShowSampleScreenAction from "./ShowSampleScreenAction";
import { useScreenStateContext } from "@Toolkit/ReactClient/Components/Screen/ScreenStateContext";
import ScreenDisplayMode from "@Toolkit/ReactClient/ActionProcessing/ScreenDisplayMode";
import ShowSampleMdScreenAction from "./ShowSampleMdScreenAction";
import ActionBoundButton from "@Toolkit/ReactClient/ActionProcessing/ActionBoundButton";
import StringEntityId from "@Toolkit/CommonWeb/Model/StringEntityId";
import ActionDescriptor from "@Toolkit/ReactClient/ActionProcessing/ActionDescriptor";

interface ISampleScreenProps extends IScreenPropsBase<ShowSampleScreenAction> {
}

export default function SampleScreen(props: ISampleScreenProps) {

    // const screenNav = useScreenNavigationContext();
    // const setState = useCallback(() => {
    //     screenNav.showPrimary(props.action, "cica");
    // }, [screenNav]);

    const [secondaryEvents, setSecondaryEvents] = useState<string[]>([]);

    const {
        showFullScreenSampleAction,
        showFullMdScreenSampleAction,
        showModalScreenSampleAction,
        showModalMdScreenSampleAction,
    } = useMemo(() => {
        return {
            showFullScreenSampleAction: ActionDescriptor.fromAction(new ShowSampleScreenAction(ScreenDisplayMode.Full, props.action.someParam + "😊")),
            showFullMdScreenSampleAction: ActionDescriptor.fromAction(new ShowSampleMdScreenAction(ScreenDisplayMode.Full, props.action.someParam + "😊")),
            showModalScreenSampleAction: ActionDescriptor.fromAction(new ShowSampleScreenAction(ScreenDisplayMode.Modal, props.action.someParam + "😊")),
            showModalMdScreenSampleAction: ActionDescriptor.fromAction(new ShowSampleMdScreenAction(ScreenDisplayMode.Modal, props.action.someParam + "😊")),
        };

    }, [props.action.someParam]);

    const screenState = useScreenStateContext();

    const secondaryCancelled = useCallback(() => {
        setSecondaryEvents([...secondaryEvents, "Cancelled"]);
    }, [setSecondaryEvents, secondaryEvents]);

    const secondarySavedExisting = useCallback(() => {
        setSecondaryEvents([...secondaryEvents, "Saved existing"]);
    }, [setSecondaryEvents, secondaryEvents]);

    const secondarySavedNew = useCallback((id: StringEntityId) => {
        setSecondaryEvents([...secondaryEvents, "Saved new with id: " + id.value]);
    }, [setSecondaryEvents, secondaryEvents]);

    return (
        <Screen
            title={props.action.id.value}
            onSecondaryCancelled={secondaryCancelled}
            onSecondarySavedNew={secondarySavedNew}
            onSecondarySavedExisting={secondarySavedExisting}
        >
            Screen
            someParam: {props.action.someParam}
            {/* <Button automationId="" text="Set local state" onClick={setState} /> */}

            <Button automationId="" text="Cancel" onClick={screenState.cancelled} />
            <Button automationId="" text="Save" onClick={screenState.savedExisting} />

            <hr />

            <ActionBoundButton actionDescriptor={showFullScreenSampleAction} automationId="" text="Full screen" />
            <ActionBoundButton actionDescriptor={showFullMdScreenSampleAction} automationId="" text="Full MD screen" />

            <ActionBoundButton actionDescriptor={showModalScreenSampleAction} automationId="" text="Modal screen" />
            <ActionBoundButton actionDescriptor={showModalMdScreenSampleAction} automationId="" text="Modal MD screen" />

            <hr />
            <h2>Events from secondary screen</h2>
            <ul>
                {secondaryEvents.map(e => (<li>{e}</li>))}
            </ul>

        </Screen>
    );
}