import React, { useCallback } from "react";
import * as Ui from "@CommonControls";
import State from "@Toolkit/ReactClient/Common/StateManaging";
import DataGrid from "@CommonControls/DataGrid/DataGrid";
import DataGridColumn from "@CommonControls/DataGrid/Column/DataGridColumn";
import NameStore from "@Primitives/NameStore";
import IDataGridColumnProps, { IDataGridColumnBaseProps, IDataGridColumnChildProps, IDataGridColumnFilterProps } from "@CommonControls/DataGrid/Column/IDataGridColumnProps";
import { RowReference, IRowCheckState, IPagingState, DataGridLoadType, IRowBody, IOrderingState, IRowIndicatorStyle, RowId } from "@CommonControls/DataGrid/IDataGridProps";
import DataGridNumberColumn from "@CommonControls/DataGrid/Column/DataGridNumberColumn";
import DataGridDateColumn from "@CommonControls/DataGrid/Column/DataGridDateColumn";
import LocalDate from "@Toolkit/CommonWeb/LocalDate";
import DataGridBooleanColumn from "@CommonControls/DataGrid/Column/DataGridBooleanColumn";
import InMemoryDataGridDataSource from "@CommonControls/DataGrid/DataSource/InMemoryDataGridDataSource";
import DataGridWithValidationResults from "./DataGridWithValidationResults";
import DateTimeService from "@Toolkit/ReactClient/Services/Implementation/DateTimeService/DateTimeService";


class TestRow {
    @State.observable.ref public order = 1;
    @State.observable.ref public checkState: IRowCheckState = {
        isChecked: false,
        isDisabled: false,
        isVisible: true
    };

    public readonly badge = "U";
    public readonly date: LocalDate;
    public readonly isEven: boolean;

    constructor(
        public readonly index: number,
        public readonly name: NameStore,
        order: number,
        isCounting: boolean = true
    ) {
        this.order = order;
        this.date = LocalDate.createFromMoment(DateTimeService.now().add(index, "days"));
        this.isEven = !(index % 2);

        if (isCounting) {
            setInterval(() => {
                State.runInAction(() => {
                    this.order++;
                });
            }, 1000);
        }
    }
}


@State.observer
export default class DataGridExampleBox extends React.Component {

    private id = 3;

    private renderSubTable = () => (
        <State.Observer>
            {() => (
                <div style={{ margin: 8 }}>
                    <DataGrid
                        dataSource={this.data2}
                        hasHeader={this.hasHeader}
                        rowBody="body"
                        rowId="index"
                        isSelectable={this.isSelectable}
                        selectedRow={this.selectedRow}
                        onSelectedRowChange={this.selectRow}
                        rowCheckState={this.isCheckable ? "checkState" : undefined}
                        onRowChecked={this.checkRow}
                        emptyStateSettings={this.emptyStateSettings}
                        onChangeAsync={this.loadAsync}
                        paging={this.paging}
                        rowHeight={40}
                        ordering={this.ordering}
                        onContextMenu={this.renderContextMenu}
                        getRowIndicatorStyle={this.hasRowIndicator ? this.getRowIndicatorStyle : undefined}
                        filterStore={this.filter}
                        generateInitialFilterStore
                        automationId="dataGridExampleBox1_dataGrid"
                    >
                        <DataGridNumberColumn dataGetter="index" header="#" width={40} isOrderable />
                        <NameColumn dataGetter="name" header="Name" isVisible={this.nameColumnVisible} id="name" isOrderable isFilterable />
                        <DataGridColumn dataGetter="badge" header="Badge"><Badge /></DataGridColumn>
                        <DataGridDateColumn dataGetter="date" header="Created on" isFilterable />
                        <DataGridBooleanColumn dataGetter="isEven" header="%2" isFilterable isOrderable id="isEven" />
                    </DataGrid>

                </div>
            )}</State.Observer>
    );

    @State.observable.ref private data2 = [
        {
            index: 0,
            order: 1,
            name: NameStore.create({ familyName: "Cserepes", givenName1: "Virág" }),
            badge: "A",
            checkState: {
                isChecked: false,
                isDisabled: false,
                isVisible: false
            }
        },
        new TestRow(1, NameStore.create({ familyName: "Változó", givenName1: "Idő" }), 2),
        new TestRow(2, NameStore.create({ familyName: "Változó", givenName1: "Kor" }), 5),
        new TestRow(3, NameStore.create({ familyName: "Változó", givenName1: "Dátum" }), 8)
    ];

    @State.observable.ref private data = [
        {
            index: 0,
            order: 1,
            name: NameStore.create({ familyName: "Cserepes", givenName1: "Virág" }),
            badge: "A",
            checkState: {
                isChecked: false,
                isDisabled: false,
                isVisible: false
            }
        },
        new TestRow(1, NameStore.create({ familyName: "Változó", givenName1: "Idő" }), 2),
        new TestRow(2, NameStore.create({ familyName: "Változó", givenName1: "Kor" }), 5),
        new TestRow(3, NameStore.create({ familyName: "Változó", givenName1: "Dátum" }), 8),
        {
            index: 4,
            order: 30,
            badge: "Q",
            name: NameStore.create({ familyName: "Cserepes", givenName1: "Virág" }),
            body: {
                showCells: true,
                content: (
                    <Ui.GroupBox title="Body with cells" hasBorder>
                        <p>Test content.</p>
                    </Ui.GroupBox>
                )
            } as IRowBody,
            checkState: {
                isChecked: false,
                isDisabled: false,
                isVisible: false
            }
        },
        new TestRow(5, NameStore.create({ familyName: "Téves", givenName1: "Nézőpont" }), 8),
        {
            index: 6,
            order: 444,
            badge: "Q",
            name: NameStore.create({ familyName: "Best", givenName1: "Shot" }),
            body: {
                showCells: false,
                content: (
                    <Ui.GroupBox title="Body without cells" hasBorder>
                        <p>Test content.</p>
                    </Ui.GroupBox>
                )
            } as IRowBody,
            checkState: {
                isChecked: false,
                isDisabled: false,
                isVisible: false
            }
        },
        new TestRow(7, NameStore.create({ familyName: "BornFor", givenName1: "Greatness" }), 8),
    ];

    @State.observable.ref private nameColumnVisible = true;
    @State.observable.ref private hasHeader = true;
    @State.observable.ref private isSelectable = true;
    @State.observable.ref private isCheckable = true;
    @State.observable.ref private hasRowIndicator = true;
    @State.observable.ref private text = "";
    @State.observable.ref private selectedRow: RowReference<any> = null;
    @State.observable.ref private paging: IPagingState = { currentPage: 0, pageSize: 5 };
    @State.observable.ref private ordering: IOrderingState = null;
    @State.observable.ref private filter: any = null;

    @State.observable.ref private additionalColumns: IDataGridColumnProps[] = [
        {
            ...DataGridColumn.defaultProps,
            dataGetter: "order",
            header: "Order",
            id: this.id++
        },
        {
            ...DataGridColumn.defaultProps,
            dataGetter: "order",
            header: "Order",
            id: this.id++
        },
        {
            ...DataGridColumn.defaultProps,
            dataGetter: "order",
            header: "Order",
            id: this.id++
        }
    ];

    @State.action.bound
    private addColumn() {
        this.additionalColumns = [
            ...this.additionalColumns,
            {
                ...DataGridColumn.defaultProps,
                dataGetter: "order",
                header: "Order",
                id: this.id++
            }
        ];
    }

    @State.action.bound
    private removeColumn() {
        this.additionalColumns = [...this.additionalColumns.slice(0, this.additionalColumns.length - 1)];
    }

    @State.action.bound
    private toggleObserver() {
        const newColumns = [...this.additionalColumns];
        const lastCol = newColumns[newColumns.length - 1];
        lastCol.isObserver = !lastCol.isObserver;
        this.additionalColumns = newColumns;
    }

    @State.action.bound
    private toggleVisibility() {
        const newColumns = [...this.additionalColumns];
        const lastCol = newColumns[newColumns.length - 1];
        lastCol.isVisible = !lastCol.isVisible;
        this.additionalColumns = newColumns;
    }

    @State.action.bound
    private toggleStaticVisibility() {
        this.nameColumnVisible = !this.nameColumnVisible;
    }

    @State.action.bound
    private setHasHeader(hasHeader: boolean) {
        this.hasHeader = hasHeader;
    }

    @State.action.bound
    private setSelectable(newValue: boolean) {
        this.isSelectable = newValue;
    }

    @State.action.bound
    private setCheckable(newValue: boolean) {
        this.isCheckable = newValue;
    }

    @State.action.bound
    private setRowIndicator(newValue: boolean) {
        this.hasRowIndicator = newValue;
    }

    @State.action.bound
    private setText(newValue: string) {
        this.text = newValue;
    }

    @State.action.bound
    private addRow() {
        this.data = [...this.data, new TestRow(this.data.length, NameStore.create({ familyName: "Eördögh", givenName1: "László" }), 0, false)];
    }

    @State.action.bound
    private selectRandomRow() {
        this.selectedRow = this.data[(DateTimeService.now().valueOf() % this.data.length)];
    }

    @State.action.bound
    private selectRow(selection: RowReference<any>) {
        this.selectedRow = selection;
    }

    @State.action.bound
    private checkRow(isChecked: boolean, row: TestRow) {
        row.checkState = {
            ...row.checkState,
            isChecked: isChecked
        };
    }

    @State.bound
    private loadAsync(type: DataGridLoadType, paging: IPagingState, ordering: IOrderingState, filter: any): Promise<void> {
        return new Promise((resolve: () => void) => {
            setTimeout(() => {
                State.runInAction(() => {
                    console.log("Load type: ", type);
                    console.log("Paging: ", paging);
                    console.log("Ordering: ", ordering);
                    console.log("Filter: ", filter);

                    this.paging = paging;
                    this.ordering = ordering;
                    this.filter = filter;
                });
                resolve();
            }, 1000);
        });
    }

    private readonly emptyStateSettings = { title: "Nincs elem" };

    private renderContextMenu(row: any) {
        return (
            <>
                <Ui.ContextMenu.Item>Az {row.index}</Ui.ContextMenu.Item>
                <Ui.ContextMenu.Item>Ez</Ui.ContextMenu.Item>
            </>
        );
    }

    private getRowIndicatorStyle(row: any, rowId: RowId, rowIndex: number): IRowIndicatorStyle {
        return {
            color: !(rowIndex % 2) ? "red" : "blue"
        };
    }

    private createTestItems(itemCount: number) {
        let i;
        const items = [];
        for (i = 0; i < itemCount; i++) {
            const item = { id: i, name: `Teszt ${i}` };
            items.push(item);
        }
        return items;
    }

    @State.observable.ref private inMemoryDataSource: InMemoryDataGridDataSource = new InMemoryDataGridDataSource(() => this.createTestItems(50));

    private renderDataGridWithInMemoryDataSource() {
        return (
            <>
                <Ui.Header text="In Memory Data Source" />
                <DataGrid
                    dataSource={this.inMemoryDataSource}
                    hasHeader={true}
                    isSelectable
                    changeOnMount
                    generateInitialFilterStore
                    automationId="dataGridExampleBox2_dataGrid"
                >
                    <DataGridColumn dataGetter="id" header="Id" onRenderFilter={this.renderIdFilter} isFilterable isOrderable />
                    <DataGridColumn dataGetter="name" header="Name" onRenderFilter={this.renderNameFilter} isFilterable isOrderable />
                </DataGrid>
            </>
        );
    }

    private renderNameFilter(filterProps: IDataGridColumnFilterProps<any>) {
        return (
            <Ui.TextBox automationId="" {...filterProps} />
        );
    }

    private renderIdFilter(filterProps: IDataGridColumnFilterProps<any>) {
        return (
            <Ui.NumberBox {...filterProps} />
        );
    }

    public render() {
        return (
            <>
                <Ui.PageBox title="Data grid" fullHeight={false}>
                    <Ui.PageBox.Body>
                        <Ui.CheckBox automationId="" label="hasHeader" value={this.hasHeader} onChange={this.setHasHeader} />
                        <Ui.CheckBox automationId="" label="isSelectable" value={this.isSelectable} onChange={this.setSelectable} />
                        <Ui.CheckBox automationId="" label="isCheckable" value={this.isCheckable} onChange={this.setCheckable} />
                        <Ui.CheckBox automationId="" label="hasRowIndicator" value={this.hasRowIndicator} onChange={this.setRowIndicator} />

                        <Ui.Button automationId="" text="Add Row" onClick={this.addRow} />
                        <Ui.Button automationId="" text="Select random row" onClick={this.selectRandomRow} />

                        <Ui.Button automationId="" text="Add column" onClick={this.addColumn} />
                        <Ui.Button automationId="" text="Remove column" onClick={this.removeColumn} />
                        <Ui.Button automationId="" text="Toggle column observer" onClick={this.toggleObserver} />
                        <Ui.Button automationId="" text="Toggle column visible" onClick={this.toggleVisibility} />
                        <Ui.Button automationId="" text="Toggle static column visible" onClick={this.toggleStaticVisibility} />

                        <Ui.TextBox automationId="" value={this.text} onChange={this.setText} />

                        <DataGrid
                            dataSource={this.data}
                            hasHeader={this.hasHeader}
                            rowBody="body"
                            rowId="index"
                            isSelectable={this.isSelectable}
                            selectedRow={this.selectedRow}
                            onSelectedRowChange={this.selectRow}
                            rowCheckState={this.isCheckable ? "checkState" : undefined}
                            onRowChecked={this.checkRow}
                            emptyStateSettings={this.emptyStateSettings}
                            onChangeAsync={this.loadAsync}
                            paging={this.paging}
                            rowHeight={40}
                            ordering={this.ordering}
                            onContextMenu={this.renderContextMenu}
                            getRowIndicatorStyle={this.hasRowIndicator ? this.getRowIndicatorStyle : undefined}
                            filterStore={this.filter}
                            generateInitialFilterStore
                            automationId="dataGridExampleBox3_dataGrid"
                        >
                            <DataGridNumberColumn dataGetter="index" header="#" width={40} isOrderable />
                            <NameColumn dataGetter="name" header="Name" isVisible={this.nameColumnVisible} id="name" isOrderable isFilterable />
                            <DataGridColumn dataGetter="badge" header="Badge"><Badge /></DataGridColumn>
                            <DataGridDateColumn dataGetter="date" header="Created on" isFilterable />
                            <DataGridBooleanColumn dataGetter="isEven" header="%2" isFilterable isOrderable id="isEven" />
                            {this.additionalColumns.map((c) => <DataGridColumn key={c.id} {...c} />)}
                    </DataGrid>
                        <pre>{JSON.stringify({ filterStore: this.filter }, null, "    ")}</pre>
                        <Ui.Separator />
                        {this.renderDataGridWithInMemoryDataSource()}
                    </Ui.PageBox.Body>
                </Ui.PageBox>
                <DataGridWithValidationResults />
            </>
        );
    }
}


const NameColumn: React.FC<IDataGridColumnBaseProps> = props => {

    const valueRenderer = useCallback((value: NameStore) => `${value.familyName} ${value.givenName1}`, []);
    const { children, ...columnProps } = props;

    return (
        <DataGridColumn
            {...columnProps}
            onRenderCellValue={valueRenderer}
        />
    );
};


const Badge: React.FC<IDataGridColumnChildProps> = props => {
    return (
        <Ui.Badge shape="pill" visualStyle="positive" text={props.value} />
    );
};

NameColumn.defaultProps = DataGridColumn.defaultProps;